import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible } from "./assets";
import { toast } from "react-toastify";
type TTypeSport = "eSports" | "Sports" | "common"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  checkedRememberMe: boolean;
  imgPasswordVisible: any;
  isErrorEmail: boolean;
  enablePasswordField: boolean;
  imgPasswordInVisible: any;
  isErrorPass: {
    show: boolean;
    message: string;
    isInputField?: boolean;
  };
  typeSport: TTypeSport;
  showOTP: boolean;
  otpErrorMsg: string;
  token: string;
  userId: number;
  showModalTermPrivacy: boolean;

  loadingSplash: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginBlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  createOTPCallId: string = '';
  verifyOTPCallId: string = '';
  emailReg: RegExp;
  passReg: RegExp;
  labelTitle: string = "";
  steamSSOApiCallId: string = "";
  epicSSOApiCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      typeSport: "common",
      email: "",
      password: "",
      isErrorEmail: false,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      checkedRememberMe: false,
      enablePasswordField: true,
      imgPasswordInVisible: imgPasswordInVisible,
      isErrorPass: { show: false, message: "" },
      showOTP: false,
      otpErrorMsg: '',
      token: '',
      userId: 0,
      showModalTermPrivacy: false,
      loadingSplash: true,
    };

    this.passReg =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    this.emailReg = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.callGetValidationApi();
    this.handleGetTypeSport();
    // Customizable Area Start
    const timer = setTimeout(() => {
      this.setState({ loadingSplash: false });
      clearTimeout(timer);
    }, 2000);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      message.getData(getName(MessageEnum.LoginUserName));

      message.getData(getName(MessageEnum.LoginPassword));

      message.getData(getName(MessageEnum.LoginCountryCode));
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiEmailLoginCallId) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          window.localStorage.setItem(
            configJSON.keyLocalStorageSport,
            responseJson.meta.account_type
          );
          window.localStorage.setItem('userId', String(responseJson.meta.id))

          if (responseJson.meta.two_factor_authentication === "enabled") {
            this.setState({ showOTP: true, token: responseJson.meta.token, userId: responseJson.meta.id })
            this.createOTP(responseJson.meta.token, responseJson.meta.id);
          } else {
            window.localStorage.setItem(configJSON.keyLocalStorageToken, responseJson.meta.token)
            setStorageData(configJSON.keyLocalStorageToken, responseJson.meta.token)
            this.props.navigation.navigate("NavigationMenu");
          }
        } else {
          //Check Error Response
          // this.parseApiErrorResponse(responseJson);
        }
        if (responseJson?.errors) {
          toast.error(responseJson.errors)
          this.setState({
            isErrorPass: { show: true, message: responseJson.errors, isInputField: true },
          });
        }
      }

      if (apiRequestCallId === this.verifyOTPCallId) {
        if (responseJson && !responseJson.error && !responseJson.errors) {
          window.localStorage.setItem(configJSON.keyLocalStorageToken, this.state.token)
          setStorageData(configJSON.keyLocalStorageToken, this.state.token)
          this.props.navigation.navigate("NavigationMenu")
        } else {
          window.localStorage.removeItem(configJSON.keyLocalStorageToken)
          this.setState({ otpErrorMsg: "Invalid OTP secret" })
        }
      }
     this.callAPI(apiRequestCallId,responseJson)
    }

    // Customizable Area End
  }

  // Web Event Handling
  handleCheckLogin() {
    return Boolean(window.localStorage.getItem(configJSON.keyLocalStorageToken))
  }
  handleGetTypeSport() {
    const typeSport = window.localStorage.getItem('typeSport')
    if (typeSport)
      this.setState({ typeSport: typeSport as TTypeSport })
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.state.email.match(this.emailReg)
    ) {
      this.setState({ isErrorEmail: true });
    } else {
      this.setState({
        email: text,
        isErrorEmail: false,
      });
    }
  };

  setPassword = (text: string) => {
    if (
      this.state.password === null ||
      this.state.password.length === 0 ||
      !this.state.password.match(this.passReg)
    ) {
      this.setState({
        isErrorPass: { show: true, message: "Enter correct Password" },
      });
    }
    this.setState({
      password: text,
      isErrorPass: { show: false, message: "" },
    });
  };

  handleClickBackBtn = () => {
    this.setState({ showOTP: false, otpErrorMsg: '' })
  }

  createOTP = (token: string, userId: number) => {
    const header = {
      "Content-Type": "application/json",
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.createOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/settings/create_otp_secret"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    const body = {
      user_id: String(userId)
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onClickResendOTP = () => {
    this.createOTP(this.state.token, this.state.userId)
  }

  showTermAndPrivacy() {
    this.setState({ showModalTermPrivacy: true });
  }

  hideTermAndPrivacy() {
    this.setState({ showModalTermPrivacy: false });
  }

  onSubmitOTP = (otp: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.verifyOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/settings/verify_otp"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    const body = {
      otp_secret_from_email: otp
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationForgotPasswordPageInfo),
      "forgotpasswordblock"
    );
    this.send(msg);
  }
  goToSignUp() {
    this.props.navigation.navigate("Home");
  }

  doEmailLogIn() {
    if (!this.state.email) {
      this.setState({
        isErrorEmail:true
      });
    }
    if(!this.state.password)
    {
      this.setState({
        isErrorPass: { show: true, message: "Please enter a password" },
      }) 
    }
    if (this.state.email && this.state.password) {
      this.setState({
        isErrorPass: { show: false, message: "" },
        isErrorEmail:false
      });
      const header = {
        "Content-Type": configJSON.loginApiContentType,
      };

      const attrs = {
        user_name: this.state.email,
        password: this.state.password,
      };

      const data = {
        type: "email_account",
        attributes: attrs,
      };

      const httpBody = {
        data: data,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiEmailLoginCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.userLoginApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  steamLogin = () => {
    const dataBody = {
      data: {
        type: "steam_account",
      },
    };
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getSteamSSOid = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.steamSSOApiCallId = getSteamSSOid.messageId;

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSteamSSO
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataBody)
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginSSOMethod
    );
    runEngine.sendMessage(getSteamSSOid.id, getSteamSSOid);
  };

  epicLogin = () => {
    const dataBody = {
      data: {
        type: "epic_games",
      },
    };
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getEpicSSOId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.epicSSOApiCallId = getEpicSSOId.messageId;

    getEpicSSOId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSteamSSO
    );

    getEpicSSOId.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getEpicSSOId.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataBody)
    );

    getEpicSSOId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginSSOMethod
    );
    runEngine.sendMessage(getEpicSSOId.id, getEpicSSOId);
  };

  callAPI=(apiRequestCallId:string,responseJson:any)=>{
    if(apiRequestCallId===this.steamSSOApiCallId){
        if(responseJson.login_url){
          window.location.href=responseJson.login_url
        }
    }
    if(apiRequestCallId===this.epicSSOApiCallId){
      if(responseJson.login_url){
        window.location.href=responseJson.login_url
      }
    }
  }
}
