import React from "react";
import { Box, Button, styled } from "@material-ui/core";
import { moveIcon, trashBinIcon } from "../assets";


const Overlay = styled(Box)(({ theme }) => ({
    background: "#1D1D1F",
    borderRadius: "10px",
    backgroundColor:"rgba(37, 37, 39, 1)",
    padding: "4px 24px",
    position: "fixed",
    width:509,
    display:"flex",
    justifyContent:"center",
    bottom:126,
    right:33,
    gap:28,
    alignItems:"center",
    zIndex:999

   
  }))

  type SelectedOverlayProps={
    selectedItems:{
      id: number,
      gameName: string,
    }[],
  }
  
export default function SelectedFilesOverlay(props:SelectedOverlayProps) {
  return (
    <Overlay>
        <Box>
          <p style={{
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "28px",
          textAlign: "left",
          color:"rgba(207, 209, 212, 1)"
          }}>{props.selectedItems.length} Videos</p>
          <p style={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "32px",
          textAlign: "left",
          letterSpacing: "-0.005em",
          color:"rgba(207, 209, 212, 1)"}}>Total: <span style={{color:"rgba(255, 136, 26, 1)"}}>13.6GB</span></p>
        </Box>
        <Box>
        <Button variant="outlined" style={{ color: '#FF1919', borderColor: '#FF1919', borderRadius: '8px', width: '130px', height: '44px', padding: '10px 16px', gap: '10px' }} endIcon={<img src={trashBinIcon} 
        alt="Your Image" style={{ width: '24px', height: '24px' }} />}>Delete</Button>
        </Box>
        <Box>
        <Button variant="outlined" style={{ color: 'rgba(26, 172, 255, 1)', borderColor: 'rgba(26, 172, 255, 1)', borderRadius: '8px', width: '130px', height: '44px', padding: '10px 16px', gap: '10px' }} endIcon={<img src={moveIcon} 
        alt="Your Image" style={{ width: '24px', height: '24px' }} />}>Move</Button>
        </Box>
    </Overlay>
  )
}
