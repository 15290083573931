//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area Start
import { 
  Grid,
  Card,
  CardContent,
  Dialog,
  Divider,
  DialogContent,
  Box, Typography,Avatar, Button, styled, InputBase, InputAdornment, CardHeader, CardActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Breadcrumbs, IconButton, TextField, Modal
} from "@material-ui/core";
import {
  defaultAvatar,
  layoutLongView, 
  editIcon, 
  selectFolder, 
  folderIcon2, 
  deleteIcon, 
  filter, 
  folder, 
  inGameRecorder, 
  layout, 
  search, 
  uploadGame, 
  uploadIcon,untick,tickmark, folderCreationDisabled, arrowIcon, createFolderIcon, fantasticweDefaultTeamImg, filterOnHover, arrowDownOnHover, createGameFolderIcon, selectedFolderIcon } from "./assets";
import { Close } from "@material-ui/icons";
import InputField from "../../../components/src/InputField.web";
import BulkUploading from "../../bulkuploading/src/BulkUploading.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Videos from "../../videos/src/Videos.web";
import MediaConverter1 from "../../../components/src/MediaConverter1";
import DropDown from "../../../../packages/components/src/DropDown.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiCard: {
      root: {
        '&.cardItem': {
          backgroundColor: "#252527",
          display: "flex", flexDirection: "column", alignItems: "center",
          padding: 32,
          borderRadius: 10,
          gap: 24,
          width: 414,
          justifyContent: "space-between"
        }

      }
    },
    MuiButton: {
      contained: {
      },
      root: {
        textTransform: "none"
      }
    },
    MuiTypography: {
      root: {
        "&.modalTitle": {
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: "700",
          lineHeight: "28px",
          textAlign: "center",
          color: "#CFD1D4",
          marginBottom: 32,
        },
        "&.cardDescription": {

          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "26px",
          textAlign: "center",

        },
        "&.inputLabel": {
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          color: "#CFD1D4",
        }
      }
    },
    MuiCardHeader: {
      title: {
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "26px",
        textAlign: "center",
        color: "#CFD1D4",
      }
    },
    MuiDialog: {

    },
    MuiCardContent: {
      root: {

        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "26px",
        textAlign: "center",
        color: "#676767",

      }
    },
    MuiIconButton: {
      root: {
        '&.closeBtn': {
          position: "absolute",
          top: 6,
          right: 6,
          margin: 0,
          padding: 4,
        }
      }
    },



  }
});

const StyledFolderModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",

  "& .MuiPaper-root": {
    maxWidth: "800px",
    // width: "700px",
  },

  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
    marginBottom: 32,
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#676767",
    // margin: "24px auto",
    // width: "700px"
  },

}))

const StyledTable = styled(Table)({
  margin: '0 72px 0 72px',
  width: '90%',
  fontFamily: 'Poppins',
  borderSpacing: '0px 8px',
  borderCollapse: 'separate',
  minWidth: '1100px',
  overflowX:"hidden",

  '& .MuiTableRow-root': {
    height: '48px',
    marginBottom: '8px',
    // boxShadow: "-5px 4px 12px 0px rgba(0, 0, 0, 0.25) inset",
  },

  '& .MuiTableRow-head': {
    height: '18px',
  },


  '& .MuiBox-root': {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px'
  },

  '& th, & td': {
    fontSize: '20px',
    color: '#CFD1D4',
    textAlign: 'center',
    fontStyle: "normal",
    borderBottom: 'none',
    fontFamily: 'Poppins',
    margin: '0',
    padding: '0',
  },
  '& th': {
    color: '#676767',
    fontWeight: '400',
    lineHeight: '18px',
  },
  '& td': {
    lineHeight: 'normal',
    color: '#CFD1D4',
    fontWeight: '500',
    marginBottom: '8px',
  },
  '& tr': {
    height: '48px',
  },
  '& tbody tr:nth-of-type(even) td': {
    backgroundColor: '#252527',
  },
  '& td:first-child': {
    textAlign: 'left',
    padding: '0px'
  },

  '& tr td:first-child': {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px'
  },

  '& tr td:last-child': {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px'
  },
});

const StyledBreadCrumbs = styled(Breadcrumbs)({
  marginLeft: "72px",
  fontFamily: "Poppins",
  fontSize: '24px',
  fontWeight: '400',
  lineHeight: '32px',
  letterSpacing: '-0.12px',
  color: '#CFD1D4',
  margin: '16px',
  '& ol li:last-child .MuiTypography-body1': {
    color: '#FF881A !important'
  }
})

const TextFieldStyle = styled(Box)({
  "& .MuiFilledInput-underline": {
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
  },
  "& .MuiFilledInput-underline::before": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-input": {
    height: '24px !important',
    padding: '14px 12px !important',
    color: '#CFD1D4',
    fontSize: '16px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .MuiFormControl-root": {
    width: '100%'
  },
  '& .validationMsg': {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fonstSize: '1rem',
    margin: '6px 0'
  },
  '& .lableStyle': {
    color: "#676767",
    fontWeight: 400,
    fontSize: '20px',
    paddingBottom: '4px'
  }
})

const PermissionButton = styled(Button)({
  color: "#FF881A",
  borderColor: "#FF881A",
  borderRadius: 8,
  fontSize: 16,
  padding: "10px 16px 10px 16px",
  gap: 8,
  fontFamily: "Montserrat",
  fontWeight: 400,
  lineHeight: "90%",
  letterSpacing: "0.45px",
  width: "200px",
  height: '55px',
  position: "absolute",
  top: "133px",
  right: "40px",
  textTransform: 'none',

  "& .MuiButton-label": {
    display: "flex",
    flexDirection: "column",
  }

})

// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomBackdrop = () => {
    return this.state.assignViewPermissionOpen || this.state.viewPermissionOpen ? <CustomBackdrop /> : null
  }
  returnFilter = () => {
    const { staffList, inGameRole, positionList } = this.state;
    const renderFilterObj = (title, data) => {
      return (
        <><Typography component="span" className="filter-title-text mt-24">
          {title}
        </Typography>
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {data.map(item => (
              <Box className="item-all-options" data-test-id="staff-container">
                <Box
                  data-test-id="staff-item"
                  key={item.label}
                  className={`item-option ${item.selected ? "selected" : ""}`}
                  onClick={() => this.onFilterChange(item)}
                >
                  {item.avatar ? (
                    <img src={item.avatar} width={50} height={50} alt="" />
                  ) : (
                    <div className="avatar">{this.formatNameAsAvatar(item.label)}</div>
                  )}
                  <div className={`item-option-name ${item.selected ? "selected" : ""}`}>{item.label}</div>
                </Box></Box>
            ))}
          </Box>
        </>)




    }


    return (
      <>
        {staffList?.length > 0 && renderFilterObj('Staff', staffList)}
        {inGameRole?.length > 0 && renderFilterObj('In_Game_Role', inGameRole)}
        {positionList?.length > 0 && renderFilterObj('Position', positionList)}
      </>
    );
  };

  renderAssigneeViewPermissionModal = () => {
   
    return <AssignViewPermissionModal
            open={this.state.assignViewPermissionOpen}
            onClose={this.closeAssignViewPermissionModal}
            BackdropComponent={Box}
          >
            <>
              <Typography component="span" className="title-text">Assign Viewing Perssmion!</Typography>

              <Box className="assignees">
                <Typography component="span" className="filter-title-text">Entire Team</Typography>

                <Box className="item-option">
                  <img src={fantasticweDefaultTeamImg} width={50} height={50} alt="" />

                  <Box className="item-option-name">Fantastic Athletes</Box>
                </Box>
                {this.returnFilter()}
                

                <Typography component="span" className="filter-title-text mt-24">Players</Typography>

                <Box className="item-all-options">
                  {this.state.allPlayers.map(item => {
                    const name = item.attributes.first_name + " " + item.attributes.last_name;
                    const profileImg = item.attributes.photo || "";
                    return (
                      <Box data-test-id="player-item" key={String(item.id)} className="item-option" onClick={() => this.handleSelectAssignee(item.id)}>
                        {profileImg ?
            <Avatar className="avatar" src={profileImg}/> :<Avatar className="avatar" src={defaultAvatar}/>
          }

                        <Box className={`item-option-name ${item.selected ? "selected" : ""}`}>{name}</Box>
                      </Box>
                    )
                  })}
                </Box>
              </Box>

              <Box className="actions-row">
                <Box data-test-id="assignee-view-permission-back-btn" className="back-btn" onClick={this.closeAssignViewPermissionModal}>Back</Box>

                <Box className="save-btn" data-test-id="assignee-view-permission-save-btn" onClick={this.assignPermissionFolder}>Save</Box>
              </Box>
            </>
          </AssignViewPermissionModal>
  }

  renderViewPermissionModal = () => {
    return <ViewPermissionModal
              open={this.state.viewPermissionOpen}
              onClose={this.closeViewPermissionModal}
              style={this.state.assignViewPermissionOpen ? {display:'none'} : {}}
              BackdropComponent={Box}
            >
              <>
                <IconButton
                  className="close-btn"
                  data-test-id="close-view-permission-modal-btn"
                  onClick={this.closeViewPermissionModal}
                  aria-label="close">
                  <Close style={{ color: "#676767" }} />
                </IconButton>

                <Typography component='span' className="title-text">Edit Access!</Typography>

                <Typography component='span' className="sub-text">Edit who can have the Edit Access or Viewing Access of the folder</Typography>

                <Box className="viewer-list">
                  <Box className="list-header">
                    <Typography component='span' className="list-header-text">Name</Typography>

                    <Typography component="span" className="list-header-text">Access</Typography>
                  </Box>

                  <Box className="view-list-scrollable">
                    {this.state.assigneePermissions.map(item => {
                      const profileImg = item.avatar;
                      return (
                        <Box className="viewer-row" key={String(item.id)}>
                          <Box className="viewer-info">
                            {profileImg ?
                              <Avatar className="avatar" src={profileImg} /> : <Avatar className="avatar" src={defaultAvatar} />
                            }
                            <Typography component="span" className="viewer-name-text">{item.name}</Typography>
                          </Box>
                          <DropDown value={item.permission} label={item.permission === 'edit' ? "Can Edit" : item.permission === "view" ? "Can View" : ""} option={[{ value: 'edit', label: "Can Edit" }, { value: 'view', label: "Can View" }, { value: '', label: 'Remove' }]} name="assignee-permission" handleChangeFunction={selectedOption => this.handleChangeAssigneePermission(selectedOption, { option: { id: item.id } })} className="permission-dropdown" showLabelAsValue={true} hideCustomLabel={true} />
                        </Box>
                      )
                    }
                    )}
                  </Box>
                </Box>

                <Box className="actions-row">
                  <Box data-test-id="assign-user-btn" className="assign-btn" onClick={this.handleClickAssignUser}>Assign Users</Box>

                  <Box className="save-btn" onClick={this.saveEditAccess} data-test-id="save-access-btn">Save</Box>
                </Box>
              </>
            </ViewPermissionModal>
  }
  renderEmptyFolder = () => {
    const {attachmentVideos,dataRender} = this.state;

    return(
      <>{attachmentVideos.length === 0 && dataRender.length === 0 && (
        <div style={{backgroundColor:"transparent",minHeight:"400px",display:"flex",justifyContent:"center",alignItems:"center"}}><p style={{color:"#fff"}}>No Folder Found</p></div>
      )}</>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
          <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="video" />

          <Loader loading={this.state.initialLoading} />

          <StyledGridItem>
            <Grid container className="container">
              <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className="grid">
                <Box className="gridItem1">
                  <Typography style={{ width: '200px' }}>Upload Game/Folder:</Typography>
                  <Box style={{ display: "flex" }}>
                    <Button data-test-id="create-upload"
                      disabled={this.checkPermissiondBtn()}
                      onClick={() => this.onCreateUpload()}>
                      {this.checkPermissiondBtn() ?
                        <img src={folderCreationDisabled} className="icons" /> : <img src={folder} className="icons" />}
                    </Button>
                    {this.state.activeComponent === "Videos" && window?.api &&
                      <MediaConverter1 />
                    }
                  </Box>
                </Box>
                <Button className="gridItem2" data-test-id="layout-button" onClick={() => this.handleChangeLayout()} style={{ marginRight: 20, width: '132px', visibility: this.state.gameFolders.length > 0 || this.state.attachmentVideos.length > 0 ? "visible" : 'hidden' }}>Layout <img src={this.state.isLayoutGrid ? layout : layoutLongView} className="img icons" /></Button>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={7} xs={7} className="grid2">
                <Box className="inputbase">
                  <InputBase
                    className="gridItem2"
                    placeholder="Search Any Game/File Name..."
                    inputProps={{ 'aria-label': 'Search Any Game/File Name...' }}

                  />
                  <Box>
                    <IconButton type="button" style={{ padding: '10px' }} aria-label="search">
                      <img src={search} className="icons" />
                    </IconButton>
                  </Box>
                </Box>

                <Box>
                  <Button style={{ border: '1px solid #FF881A' }} className="gridItem2 button-hover">
                    Filter
                    <img src={filter} height={32} width={32} className="img icons default" />
                    <img src={filterOnHover} height={32} width={32} className="img icons on-hover" />
                  </Button>
                </Box>

                <Box style={{ width: '250px' }}>
                  <Button style={{ border: '1px solid #FF881A', width: '260px' }} className="gridItem2 button-hover">
                    Advanced Search
                    <img src={arrowIcon} style={{ width: 36, height: 36, marginLeft: 6 }} className="icons default" />
                    <img src={arrowDownOnHover} style={{ width: 36, height: 36, marginLeft: 6 }} className="icons on-hover" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </StyledGridItem>
          <SelectUploadModal
            type={localStorage.getItem("typeSport")}

            onCreate={this.showCreateFolderModal}

            isOpen={this.state.isSelectUploadModalOpen}
            onCloseDialog={this.onCloseSelectUploadModal}
            onSelectUploadGame={this.onSelectUploadGameStep}
            onClickCreateGameFolder={this.handleOpenCreateGameFolder}
            createFolderDsiabled={!this.state.permissions.includes("Create Custom Folder")}
            uploadGameDisabled={!this.state.permissions.includes("Upload")}
          />

          {(localStorage.getItem("typeSport") === "eSports") &&
            (<SelectUploadGameTypeModal
              isOpen={this.state.isSelectUploadGameTypeModalOpen}
              onCloseDialog={this.onCloseSelectUploadGameTypeModal}
              onBulkUploadGame={this.onBulkGameUpload}
              onInGameRecorder={this.onInGameRecorder}
            />)}
          <SelectFolderModal isOpen={this.state.isSelectFolderModalOpen}
            onCloseDialog={this.onCloseSelectFolderModal}
          />

          {this.renderViewPermissionModal()}

          {this.renderCustomBackdrop()}

          {this.renderAssigneeViewPermissionModal()}

        </ThemeProvider>
        <BulkUploading
          isOpen={this.state.isBulkUploaderOpen || this.state.createGameFolderOpen}
          onCloseDialog={() => {this.onCloseBulkUploadModal(); this.hideSelectFolderModal() }}
          onSelectClick={this.showSelectFolderModal}
          pathObj={{levelBreads:this.state.levelBreads,selectLevels:this.state.selectLevels}}
          selectedPath={this.state.createSelectedPath}
          firstLevelPathId={this.getSelectedLevelPath().first}
          secondLevelPathId={this.getSelectedLevelPath().second}
          thirdLevelPathId={this.getSelectedLevelPath().third}
          uploadType={this.state.isBulkUploaderOpen ? "video" : "gameFolder"}
          callBackCreate ={this.callBackCreateSuccess}
          handleBackBtn={this.handleBackBtn}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <BreadCrumbsComp Levels={this.state.levelBreads} onBreadCrumbsClick={this.handleBreadCrumbs} />
          {this.state.activeComponent === 'Videos' && <Box data-test-id="select-checkbox-box" style={{ display: "flex", alignItems: "center", marginRight: "72px" }}><span style={{ color: "#CFD1D4", fontSize: 20, fontWeight: 400, fontFamily: "sans-serif", }}>
            {"Select Files"}
          </span>
            <img
              src={this.state.selectMode ? tickmark : untick}
              alt={this.state.selectMode ? "Tick Mark" : "Untick"}
              onClick={this.handleSelectMode}
              style={{ width: "30px", marginLeft: "10px", cursor: "pointer" }}
            />
          </Box>
          }
        </div>

        {this.state.attachmentVideos.length < 1 && this.state.dataRender.length > 0 && (
          <CustomFolders
            folders={this.state.dataRender}
            onRowClick={this.onClickItem}
            onDelete={this.setDeleteFolder}
            editFolderDisabled={!this.state.permissions.includes("Edit Custom Folder")}
            deleteFolderDisabled={!this.state.permissions.includes("Delete Custom Folder")}
            onEditAccessClick={this.onEditAccessClick}
          />
        )}

        {this.state.attachmentVideos.length > 0  ? (
          <Videos
            videos={this.state.attachmentVideos}
            isLayoutGrid={this.state.isLayoutGrid}
            selectMode={this.state.selectMode}
            currentLevel={this.state.Levels[this.state.Levels.length - 1]}
            navigation={this.props.navigation}
            onCloseLayoutModal={this.handleChangeLayout}
            callVideo={this.getVideosApi}
            contentManagementBread={this.state.levelBreads}
            callBackCreateSuccess={this.callBackCreateSuccess}
          />
        ) : null}

        {this.renderEmptyFolder()}
        <DeleteFolderModal isActive={this.state.deleteFolderModal} onCloseClick={this.hideDeleteFolderModal} onDeleteButton={this.deleteFolder} folderName={this.state.deleteFolder.name} size={this.state.deleteFolder.size} />

        <Dialog
          open={this.state.deletingFolder}
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "unset"
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledFolderModal style={{ width: '780px' }}>
            <IconButton
              data-test-id="on-close-delete-modal"
              style={{ cursor: "pointer" }}
              onClick={this.closeDeleteFolder}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>

            <Typography variant="h6">Delete Folder!</Typography>

            <Typography variant="body1" style={{ marginTop: '24px' }}>
              Are you sure, you want to deleting {this.state.deleteFolder.name}?
            </Typography>

            <Typography variant="body1" style={{ marginTop: '24px' }}>
              This can not be undone.
            </Typography>

            <Typography variant="body1" style={{ marginTop: '24px' }}>
              The following action will remove <span style={{ fontWeight: "500", color: "#FF881A" }}>{this.state.deleteFolder.size}</span> from your cloud storage.
            </Typography>

            <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A' }} />

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                data-test-id="delete-folder-with-reports"
                variant="outlined"
                style={{
                  borderRadius: 8,
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  fontFamily: "Montserrat",
                  fontSize: 18,
                  width: "200px",
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontWeight: 600,
                  textTransform: 'none',
                  lineHeight: "22px",
                  height: '55px',
                  cursor: "pointer",
                }}
                onClick={this.closeDeleteFolder}
              >
                Cancel
              </Button>
              <Button
                data-test-id="delete-folder-without-reports"
                variant="contained"
                style={{
                  backgroundColor: "#FF1919",
                  color: "#1D1D1F",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px',
                  marginLeft: '24px',
                  cursor: "pointer",
                  textTransform: 'none'
                }}
                onClick={this.deleteFolder}
              >
                Delete
              </Button>
            </div>
          </StyledFolderModal>
        </Dialog>

        <CreateFolderModal
          isActive={this.state.createFolderModal}
          onViewPermissionClick={this.viewPermission}
          onCloseClick={this.hideCreateFolderModal}
          idStore={this.state.idStore}
          onAllow={this.getSpecific}
          onSelectClick={this.showSelectFolderModal}
          onCreate={this.createFolder}
          folderName={this.state.createFolderName}
          folderPath={[this.state.createSelectedPath]}
          onChangeFolderName={this.handleCreateFolderNameChange}
          style={this.state.viewPermissionOpen ? { display: 'none' } : {}}
          assigneePermissions={this.state.assigneePermissions}
        />

        <FolderSelectModal
          isActive={this.state.selectFolderModal}
          onCloseClick={this.hideSelectFolderModal}
          selectedCustom={this.state.selectedModalItem}
          levels={this.state.selectLevels}
          handleBreadCrumbs={this.handleSelectBreadCrumbs}
          customFolders={this.state.modalDataRender}
          onSelectClick={this.onClickSelectBtn}
          onRowClick={this.onRowModalClick}
          onDoubleClick={this.onDoubleClickItem}
          handleBackBtn={this.handleBackBtn}
        />
      </div>

      // Customizable Area End

    );
  }
}
// Customizable Area Start



export const CustomFolders = (props) => {
  const getBackgroundColor = (id) => {
    return {
      backgGrouldCell: props.selected === id && "#3C3C3C",
      borderCell: props.selected === id && "1px solid #FF881A",
    };
  };
  const {folders} = props
  const showActionBtn = folders.every(item => item.type !== "auto_generate_folder" && item.type !== "auto_inner_generate_folder") && (!props.editFolderDisabled || !props.deleteFolderDisabled);
  return (
    <TableContainer style={{overflowX:"hidden",fontFamily:"Poppins"}}>
      <StyledTable>
        <TableHead>

          <TableRow>
            <TableCell></TableCell>
            <TableCell>Folder Name:</TableCell>
            {folders[0].type === "auto_generate_folder" ? (<>
            <TableCell># Of Competitive Videos</TableCell>
            <TableCell># Of Practice Videos</TableCell></>) :(<><TableCell># Of Videos</TableCell><TableCell>File Size</TableCell></>)}
            
            <TableCell>Created By</TableCell>
            <TableCell>Created On</TableCell>
            {showActionBtn ? (
              <TableCell>Actions</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.folders.map((folder, index) => (
            <TableRow
              key={folder.id}
              data-test-id="custom-row"
              style={{ cursor: "pointer",userSelect:"none" }}
              onClick={() => props.onRowClick(folder)}
            >
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id),
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                }}
              >
                <Box
                  height={48}
                  width={48}
                  sx={{ bgcolor: "rgba(0, 0, 0, 0.60)" }}
                >
                  <img
                    src={folderIcon2}
                    alt="Logo"
                    style={{ width: "32px", height: "32px", margin: "8px" }}
                  />
                </Box>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id)
                    .backgGrouldCell,
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                  userSelect:"none"
                }}
              >
                {folder.name}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id)
                    .backgGrouldCell,
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                  userSelect:"none"
                }}
              >
                {folder.type === "auto_generate_folder" ? folder.of_competitive_videos || "0" : folder.videos ||"0"}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id)
                    .backgGrouldCell,
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                }}
              >
                {folder.type === "auto_generate_folder" ? folder.of_practice_videos || "0" : folder.fileSize || "0"}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id)
                    .backgGrouldCell,
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                  userSelect:"none"
                }}
              >
                {folder.createdBy}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(folder.id)
                    .backgGrouldCell,
                  borderBottom: getBackgroundColor(folder.id).borderCell,
                  userSelect:"none"
                }}
              >
                {folder.createdOn}
              </TableCell>

              {(showActionBtn && folder.folder_type==="new_folder") ? (
                <TableCell
                  style={{
                    backgroundColor: getBackgroundColor(folder.id)
                      .backgGrouldCell,
                    borderBottom: getBackgroundColor(folder.id).borderCell,
                    userSelect:"none"
                  }}
                >
                  {!props.editFolderDisabled && (folder.currentUser|| folder.editAccess) && (
                    <Button
                      data-test-id="custom-row-edit-access"
                      variant="outlined"
                      style={{
                        color: "#FF881A",
                        borderColor: "#FF881A",
                        borderRadius: "10px",
                        height: "40px",
                        marginRight: "8px",
                        textTransform:"none"
                      }}
                      endIcon={
                        <img
                          src={editIcon}
                          alt="Your Image"
                          style={{ width: "24px", height: "24px" }}
                        />
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onEditAccessClick(folder.id);
                      }}
                    >
                      Edit Access
                    </Button>
                  )}
                  {!props.deleteFolderDisabled && folder.currentUser &&(
                    <Button
                      data-test-id="custom-row-delete"
                      onClick={(event) => {
                        event.stopPropagation();
                        props.onDelete(folder.id, folder.name, folder.fileSize);
                      }}
                      variant="outlined"
                      style={{
                        color: "#FF1919",
                        borderColor: "#FF1919",
                        borderRadius: "10px",
                        width: "120px",
                        height: "40px",
                        padding: "10px 16px",
                        gap: "8px",
                        textTransform:"none"
                      }}
                      endIcon={
                        <img
                          src={deleteIcon}
                          alt="Your Image"
                          style={{ width: "24px", height: "24px" }}
                        />
                      }
                      disabled={props.deleteFolderDisabled}
                    >
                      Delete
                    </Button>
                  )}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};
export const BreadCrumbsComp = (props) => {
  return (
    <StyledBreadCrumbs separator={">"} aria-label="breadcrumb">
      {props.Levels?.map((level, index) => (
        <Typography key={index} data-test-id="bread-crumb" style={{ fontFamily: "Poppins", fontSize: '24px', fontWeight: '400', lineHeight: '32px', letterSpacing: '-0.12px', cursor: "pointer", color: "#CFD1D4" }} onClick={() => props.onBreadCrumbsClick(level)}>{level.breadCrumbName}</Typography>
      ))}
    </StyledBreadCrumbs>
  )
}

export const SelectCustomFolders = (props) => {
  const selectedId= props.selected?.id || ""
  const {folders,onDoubleClick,onRowClick} = props;
  return (
    <TableContainer style={{overflowX:"hidden"}}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Folder Name:</TableCell>
            {folders[0]?.type === "auto_generate_folder" ? (<>
            <TableCell># Of Competitive Videos</TableCell>
            <TableCell># Of Practice Videos</TableCell></>) :(<><TableCell># Of Videos</TableCell><TableCell>File Size</TableCell></>)}
            <TableCell>Created By</TableCell>
            <TableCell>Created On</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {folders.map((folder, index) => {
            const styleCell= { backgroundColor: selectedId === folder.id && "#3C3C3C", borderBottom: selectedId === folder.id && "1px solid #FF881A" }
            return (
              <TableRow key={folder.id} data-test-id="select-custom-row" style={{ cursor: "pointer",userSelect:"none" }}
              onDoubleClick={() => {
                onDoubleClick(folder)
              }} onClick={() => onRowClick(folder)}>
              <TableCell style={styleCell}>
                <Box height={48} width={48} sx={{ bgcolor: 'rgba(0, 0, 0, 0.60)' }}>
                  <img src={selectedId === folder.id && props.highlightSelected ? selectedFolderIcon : folderIcon2} alt="Logo" style={{ width: '32px', height: '32px', margin: '8px' }} />
                </Box>
              </TableCell>
              <TableCell style={{cursor: "pointer",userSelect:"none", backgroundColor: selectedId === folder.id && "#3C3C3C", borderBottom: selectedId === folder.id && "1px solid #FF881A" }}>{folder.name}</TableCell>
              <TableCell style={styleCell}> {folder.type === "auto_generate_folder" ? folder.of_competitive_videos  : folder.videos}</TableCell>
              <TableCell style={styleCell}>{folder.type === "auto_generate_folder" ? folder.of_practice_videos: folder.fileSize}</TableCell>
              <TableCell style={styleCell}>{folder.createdBy}</TableCell>
              <TableCell style={styleCell}>{folder.createdOn}</TableCell>
            </TableRow>
            )})}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export const DeleteFolderModal = (props) => {
  return (
    <Dialog
      data-test-id="delete-folder-modal"
      maxWidth="unset"
      open={props.isActive}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "auto"

        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledFolderModal>
        <IconButton
          data-test-id="on-close-delete-modal"
          style={{ cursor: "pointer" }}
          onClick={props.onCloseClick}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>
        <Typography variant="h6">Delete Folder/Video!</Typography>
        <Typography variant="body1">
          <span>Deleting {props.folderName} will delete all of the following options from <br /> selected video/s: <br /></span>
          <span style={{ fontWeight: "500", color: "#CFD1D4" }}> Mark, Clips, Report, Highlights </span>
        </Typography>
        <Typography variant="body1">
          The following action will remove <span style={{ fontWeight: "500", color: "#FF881A" }}>{props.size}</span> from your cloud storage.
        </Typography>
        <Divider style={{ width: '496px', backgroundColor: '#FF881A', margin: "24px auto" }} />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            data-test-id="delete-folder-with-reports"
            variant="outlined"
            style={{
              // backgroundColor: "#FF881A",
              color: "#FF1919",
              borderColor: "#FF1919",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              cursor: "pointer",
              textTransform: 'none'
            }}
            onClick={props.onDeleteButton}
          >
            Delete with Reports
          </Button>
          <Button
            data-test-id="delete-folder-without-reports"
            variant="contained"
            style={{
              backgroundColor: "#FF881A",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "22px",
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none'
            }}
            onClick={() => { }}
          >
            Delete without Reports
          </Button>
        </div>
      </StyledFolderModal>
    </Dialog>
  )
}

export const SelectFolderButton = (props) => {
  return (
    <InputAdornment data-test-id="open-folder-select-modal" style={{ cursor: "pointer" }} onClick={props.onSelectClick} position="end">
      <IconButton
        edge="end"
      >
        <img src={selectFolder} width={32} style={{ margin: "8px" }} />
      </IconButton>
    </InputAdornment>
  )
}

export const CreateFolderModal = (props) => {
  return (
    <Dialog
      data-test-id="create-folder-modal"
      maxWidth="unset"
      open={props.isActive}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
      style={props.style}
    >
      <StyledFolderModal >
        <PermissionButton
        data-test-id="view-permission-btn"
          onClick={props.onViewPermissionClick}
          variant="outlined"
        >
        <div style={{ marginBottom: "5px" }}>Viewing Permisson</div>
        {props.assigneePermissions && props.assigneePermissions.length && props.assigneePermissions.every(item => item.permission) ? 
          <div style={{color:'green'}}>Assigned</div>
          :
          <div style={{ color: "red" }}>(Unassigned)</div>
        }
        </PermissionButton>
        <Typography variant="h6">Create Folder!</Typography>
        <img src={createFolderIcon} width={112} style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "24px"
        }} />
        <TextFieldStyle>
          <Box style={{ marginBottom: "16px" }}>
            <Typography style={{ margin: "0 0 16px 0", textAlign: "left", color: "#CFD1D4" }} >Enter Folder Name: <span style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "21px",
              color: '#676767'
            }}>
              (This Name will be displayed accross all users)</span>
              <span style={{ color: "red" }}>*</span></Typography>
            <TextField
              name={"folderName"}
              type={"text"}
              placeholder={"Folder Name"}
              value={props.folderName}
              variant="filled"
              style={{ width: 750, height: 53 }}
              onChange={props.onChangeFolderName}
              data-test-id="input-folder-name"
            />
          </Box>
          <Box style={{ marginBottom: "48px" }}>
            <Typography style={{ margin: "0 0 16px 0", textAlign: "left", color: "#CFD1D4" }} >Select Folder Path: <span style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "21px",
              color: '#676767'
            }}>
              (Select where you would want this folder to be opened)</span>
              <span style={{ color: "red" }}>*</span></Typography>
            <TextField
              data-test-id="input-folder-select-modal"
              name={"folderPath"}
              type={"text"}
              placeholder={"Folder Path"}
              value={props.folderPath}
              variant="filled"
              style={{ width: 750, height: 53 }}
              InputProps={{
                endAdornment: (
                  <SelectFolderButton onSelectClick={props.onSelectClick} />
                ),
              }}
            />
          </Box>
        </TextFieldStyle>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            data-test-id="create-folder-cancel"
            onClick={props.onCloseClick}
            variant="outlined"
            style={{
              color: "#FF881A",
              borderColor: "#FF881A",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "normal",
              width: "200px",
              height: '55px',
              cursor: "pointer",
              textTransform: "none"
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            data-test-id="create-button"
            onClick={props.onCreate}
            disabled={!props.folderPath || !props.folderName}
            style={{
              color: "#1D1D1F",
              backgroundColor: "#FF881A",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "normal",
              width: "200px",
              height: '55px',
              marginLeft: '40px',
              cursor: "pointer",
              textTransform: "none"
            }}
          >
            Create Folder
          </Button>
        </div>
      </StyledFolderModal>
    </Dialog>
  )
}

export const FolderSelectModal = (props) => {
  const {levels} = props;
  let disableBackBtn = levels.length > 1 ? false : true;
  return (
    <Dialog
      data-test-id="folder-select-modal"
      open={props.isActive}
      maxWidth="unset"
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "auto"

        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledFolderModal>
        <IconButton
          data-test-id="folder-select-close"
          style={{ cursor: "pointer" }}
          onClick={props.onCloseClick}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>
        <Typography variant="h6">Select Folder Path!</Typography>
        <BreadCrumbsComp data-test-id="folder-select-bread-crumbs" Levels={props.levels} onBreadCrumbsClick={props.handleBreadCrumbs} />

        <SelectCustomFolders data-test-id="folder-select-custom" handleBackBtn={props.handleBackBtn} selected={props.selectedCustom} folders={props.customFolders} onRowClick={props.onRowClick} onDoubleClick={props.onDoubleClick} highlightSelected={true} />

        <div style={{ display: "flex", justifyContent: "space-evenly", margin: "auto", width: "55%", alignItems: "center" }}>
          <Button
            onClick={props.handleBackBtn}
            data-test-id="folder-back-button"
            variant="contained"
            style={{
              color: "#1D1D1F",
              backgroundColor: "#FF881A",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "normal",
              width: "200px",
              height: '55px',
              marginTop: '48px',
              cursor: "pointer",
              textTransform: "none",
              opacity: disableBackBtn ? 0.3 : 1
            }}
            disabled={disableBackBtn}
          >
            Back
          </Button>
          <Button
            onClick={props.onSelectClick}
            data-test-id="folder-select-button"
            variant="contained"
            style={{
              color: "#1D1D1F",
              backgroundColor: "#FF881A",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px 16px 10px 16px",
              gap: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
              lineHeight: "normal",
              width: "200px",
              height: '55px',
              marginTop: '48px',
              cursor: "pointer",
              textTransform: "none"
            }}
          >
            Select
          </Button>
        </div>
      </StyledFolderModal>
    </Dialog>
  )
}

export const SelectUploadModal = (props) => {
  return (
    <Dialog
      data-test-id="select-upload-modal"
      maxWidth="unset"
      PaperProps={{
        style: {
          maxWidth: "auto",
          border: "unset",
          background: "transparent",
          margin: 'auto',

        },
      }}
      open={props.isOpen}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
        }
      }}
    >
      <ModalInfoStyled>
        <IconButton
          onClick={props.onCloseDialog}
          className="closeBtn"
          data-test-id="close-upload-modal"
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>
        <Typography
          className="modalTitle"
          variant="h6"
        >Select Upload Type!</Typography>
        <DialogContent>
          <Box
            style={{
              justifyContent: "space-around",
              gap: 32,
              borderRadius: "10px",
              width: "100%",
              display: "flex",
            }}
          >
            <Card className="cardItem">
              <CardHeader
                title="Create Folder:"
              />
              <img
                src={createFolderIcon}
                height={86}
                style={{
                  marginRight: "auto",
                  display: "block",
                  marginLeft: "auto",
                }}
                width={101}
              />

              <CardContent>
                <Typography
                  className="cardDescription"
                  variant="body2" >
                  Create a folder for file management <br /> Assign who will have access to this folder.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    padding: "10px 0px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    data-test-id="select-upload-create"
                    onClick={() => { props.onCloseDialog(); props.onCreate(); }}
                    style={{
                      backgroundColor: "#FF881A",
                      color:  "#1D1D1F",
                      borderRadius: 8,
                      padding: "10px 16px 10px 16px",
                      lineHeight: "22px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: '18px',
                      width: '166px',
                      height: '55px',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textTransform: 'none',
                      opacity:props.createFolderDsiabled ? 0.5 :1
                    }}
                    variant="contained"
                    disabled={props.createFolderDsiabled}
                  >
                    Create
                  </Button>
                </Box>
              </CardActions>
            </Card>
            {props.type === "eSports" && (<Card className="cardItem">
              <CardHeader
                title="Create Game Folder:"
              />
              <img src={createGameFolderIcon} height={86} width={101} style={{
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }} />

              <CardContent>
                <Typography variant="body2" className="cardDescription">
                  Create an empty folder which should then be shared with the players. <br /> Assign uploads and marking timelines.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#FF881A",
                      padding: "10px 16px 10px 16px",
                      color:  "#1D1D1F",
                      borderRadius: 8,
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      lineHeight: "22px",
                      fontSize: '18px',
                      width: '166px',
                      height: '55px',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textTransform: 'none',
                      opacity:props.createFolderDsiabled ? 0.5 : 1
                    }}
                    variant="contained"
                    onClick={props.onClickCreateGameFolder}
                    disabled={props.createFolderDsiabled}
                  >
                    Next Step
                  </Button>
                </Box>
              </CardActions>
            </Card>)}
            <Card className="cardItem">
              <CardHeader
                title="Upload Game:" />
              <img
                style={{
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
                src={uploadGame}
                width={101}
                height={86}
              />

              <CardContent>
                <Typography
                  className="cardDescription"
                  variant="body2"
                >
                  Uploaded a previously recorded game to an existing folder.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    data-test-id='upload-game-btn1'
                    style={{
                      backgroundColor:"#FF881A",
                      color:"#1D1D1F",
                      borderRadius: 8,
                      padding: "10px 16px 10px 16px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      lineHeight: "22px",
                      fontSize: '18px',
                      width: '166px',
                      height: '55px',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textTransform: 'none',
                      opacity:props.uploadGameDisabled ? 0.5 : 1
                    }}
                    variant="contained"
                    onClick={props.onSelectUploadGame}
                    disabled={props.uploadGameDisabled} 
                  >
                    Next Step
                  </Button>
                </Box>
              </CardActions>
            </Card>


          </Box>

        </DialogContent>
      </ModalInfoStyled>

    </Dialog>
  )
}

export const SelectUploadGameTypeModal = (props) => {
  return (
    <Dialog
      data-test-id="select-upload-game-type-modal"
      maxWidth="unset"
      open={props.isOpen}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "auto"

        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <ModalInfoStyled>

        <IconButton
          className="closeBtn"
          onClick={props.onCloseDialog}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>
        <Typography variant="h6" className="modalTitle">Select Upload Type!</Typography>
        <DialogContent>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              borderRadius: "10px",
              gap: 32,
            }}
          >
            <Card className="cardItem">
              <CardHeader
                title="In Game Recorder:"
              />
              <img src={inGameRecorder} height={86} width={101} style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }} />

              <CardContent>
                <Typography variant="body2" className="cardDescription">
                  Automatically recorders the game into the selected folder.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    data-test-id="in-game-recorder-btn"
                    onClick={props.onInGameRecorder}
                    style={{
                      backgroundColor: "#FF881A",
                      color: "#1D1D1F",
                      borderRadius: 8,
                      padding: "10px 16px 10px 16px",
                      gap: 8,
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      lineHeight: "22px"
                    }}
                  >
                    Create
                  </Button>
                </Box>
              </CardActions>
            </Card>
            <Card className="cardItem">
              <CardHeader
                title="Upload Game:"
              />
              <img src={uploadGame} height={86} width={101} style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }} />

              <CardContent>
                <Typography variant="body2" className="cardDescription">
                  Uploaded a previously recorded game to an existing folder.
                </Typography>
              </CardContent>
              <CardActions>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={props.onBulkUploadGame}
                    style={{
                      backgroundColor: "#FF881A",
                      color: "#1D1D1F",
                      borderRadius: 8,
                      padding: "10px 16px 10px 16px",
                      gap: 8,
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      lineHeight: "22px"
                    }}
                  >
                    Next Step
                  </Button>
                </Box>
              </CardActions>
            </Card>


          </Box>

        </DialogContent>
      </ModalInfoStyled>

    </Dialog>
  )
}

export const SelectFolderModal = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      maxWidth="unset"
      data-test-id="select-folder-modal"
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "auto"

        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <FolderModalStyled>

        <IconButton
          className="closeBtn"
          data-test-id="close-select-folder-modal"
          onClick={props.onCloseDialog}
          aria-label="close">
          <Close style={{ color: "#676767" }} />
        </IconButton>
        <Typography variant="h6" className="modalTitle">Select Folder For Recording!</Typography>
        <DialogContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              borderRadius: "10px",
              gap: 24,
            }}
          >

            <img src={inGameRecorder} height={112} width={112} />
            <Box sx={{ width: "750px" }}>
              <Typography variant="body1" className="inputLabel" >Select Folder Path:<span style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#676767",
              }}>
                (Select where you would want this game recording)</span>
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <InputField
                name="reTypePassword"
                type={"text"}
                value={"Fantastic Athletes Folders > 2023 >  Competitive > Daily Uploads"}
                className="MuiFilledInput-underline inputField"
                // onChange={this.changeHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                      >
                        <img src={uploadIcon} width={32} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

            </Box>
            <Box style={{
              display: "flex",
              alignItems: "center",
              gap: 40,


            }}>
              <Button
                variant="outlined"
                style={{
                  // backgroundColor: "#FF881A",
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px'
                }}
                onClick={props.onCloseDialog}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF881A",
                  color: "#1D1D1F",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px'
                }}

              >
                Create
              </Button>
            </Box>
          </Box>

        </DialogContent>
      </FolderModalStyled>

    </Dialog>
  )
}

const ModalInfoStyled = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",

}))

const FolderModalStyled = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",
  "&.input_field_conatiner": {
    width: 750,
  }
}))

const PaperStyle = styled(Box)({
  backgroundColor: "#151414fa",
  color: "#151414fa",
  height: "642px", 
  width: "969px",
  margin:'22px',
  position: "relative",
  padding: "20px",
});
const Positions = styled(Box)({
  "& .next_back":{
display:'flex',
margin:'10px',
alignItems:"center",
justifyContent:"center"
  },
  "& .scroll-container":{
    display: 'flex', 
    flexWrap:'wrap'
  },
  "& .scroll-container-head":{
      height: '550px', 
      backgroundColor: '#252527',
      overflowY:'scroll',
      overflowX:"hidden", 
    },
  "& .heading-container": {
      textAlign: "center",
      marginBottom: "20px" 
    },
    
    "& .heading": {
      color:'white',
      fontSize: "17px",
      fontWeight: "bold"
    },
  "& .avatar":{
    left:'23px' ,
    height:'50px',
    width:'50px',
    backgroundColor:'#FF881A',
    color:'#151414fa',
    fontWeight:"bold"
  },
  "& .title":{
    fontSize: "16px", 
    fontFamily: 'poppins',
    color: 'white',
    marginBottom: "1rem" ,
    position:'relative',
    left:"27px"
  },
  "& .item-container":{
    display: 'flex',
    alignItems: 'center', 
    marginRight: "1rem", 
    marginBottom: '0.5rem' 
  },
  "& .info-container":{
    backgroundColor: 'black',
    padding: '0.5rem', 
    borderRadius: '5px', 
    marginLeft: '0.5rem' 
  },
  "& .info":{
    color: 'white',
    width:"227px",
    position:'relative',
    left:'16px'
  },
  "& .img-style_button":{
      margin:'10px',
      width:'100px',
      border:"1px solid #FF881A",
      color: '#FF881A',
      height:'32px'
    },
    "& .saveButton":{
      margin:'5px',
      width:'100px',
      backgroundColor:'#FF881A', 
      color: 'black',
    height:'32px'
    },
})
const StyledGridItem = styled('div')(({ theme }) => ({
  "& .button-hover .on-hover": {
    display: 'none',
  },

  "& .button-hover:hover": {
    backgroundColor: '#FF881A',
    color: "#1D1D1F",

    "& .on-hover": {
      display: 'block',
    },

    "& .default": {
      display: 'none'
    }
  },

  '& .pathsParent': {
    width: "95vw",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "sans-serif",
    marginBottom: 10,
  },
  '& .paths': {
    color: "#CFD1D4",
    display: "flex",
    alignItems: "center",
    Lineheight: 10
  },
  '& .selectFiles': {
    color: "#CFD1D4",
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "sans-serif",
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  '& .pathItems': {
    fontSize: 24,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  '& .pathIcon': {
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('lg')]: {
      width: 18,
    },
    [theme.breakpoints.down('md')]: {
      width: 14,
    },
    [theme.breakpoints.down('sm')]: {
      width: 12,
    },
    [theme.breakpoints.down('xs')]: {
      width: 10,
    },
  },
  '& .container': {
    width: "93vw",
    margin: "auto",
    marginTop: 20,
    marginBottom: 40
  },
  '& .grid': {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  '& .grid2': {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  '& .gridItem1': {
    display: "flex",
    border:'1px solid #FF881A',
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 30px",
    paddingLeft: 20,
    borderRadius: 10,
    fontSize: 20,
    width: "69%",
    color: "#CFD1D4",
    backgroundColor: "252527",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      width: "59%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      width: "49%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: "39%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      width: "29%",
    },
  },
  '& .gridItem2': {
    borderRadius: 10,
    fontSize: 18,
    padding: 18,
    width:'100%',
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: 8,
    },
  },
  '& .inputbase': {
    width: "59%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    fontSize: 20,
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      // width:"50%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      // width:"40%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      // width:"30%",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      // width:"20%",
    },
  },
  '& .img': {
    marginLeft: 10,
  },
  '& .icons': {
    [theme.breakpoints.down('lg')]: {
      width: 40,
    },
    [theme.breakpoints.down('md')]: {
      width: 30,
    },
    [theme.breakpoints.down('sm')]: {
      width: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: 10,
    },
  }
}));

const CustomBackdrop = styled(Box)(({theme}) => ({
  width: '100vw',
  height:'100vh',
  zIndex:'1299',
  backdropFilter:'blur(2px)',
  position:'absolute',
  top:'0',
  left:'0',
  backgroundColor:'rgba(0,0,0,0.6)'
}))

const AssignViewPermissionModal = styled(Modal)(({theme}) => ({
  width: '969px',
  margin: 'auto',
  marginTop: '50px',
  color: 'white',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: '#1E1E1F',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  "& *": {
    boxSizing: 'border-box'
  },

  "& .title-text": {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '20px',
    color: "#CFD1D4",
  },

  "& .assignees": {
    width: '100%',
    marginTop: '32px',
    height: '550px',
    borderRadius: '10px',
    padding: '16px',
    overflow: 'auto',
    backgroundColor: '#252527',
    display: 'flex',
    flexDirection: 'column'
  },

  "& .filter-title-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '16px',
    color: "#CFD1D4"
  },

  "& .item-option": {
    width: '275px',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '8px',
    cursor: 'pointer',

    "&:hover .item-option-name": {
      border: '1px solid #FF881A'
    },

    "& img": {
      borderRadius: '50%',
      position: 'relative',
      zIndex: '1302'
    }
  },
  "&.selected": {
    border: "1px solid #FF881A",
    color: '#FF881A'
  },
  "& .item-option-name": {
    width: '250px',
    height: '40px',
    borderRadius: '8px',
    marginLeft: '-25px',
    paddingLeft: '33px',
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#1D1D1F',
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '16px',
    color: "#CFD1D4",
    position: 'relative',
    zIndex: '1301',
    border: '1px solid rgba(0,0,0,0)',

    "&.selected": {
      border: "1px solid #FF881A",
      color: '#FF881A'
    }
  },


  "& .mt-24": {
    marginTop: '24px',
  },

  "& .item-all-options": {
    marginRight:15,
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '16px',
    maxWidth: '100%'
  },

  "& .avatar": {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
    zIndex: '1302',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#FF881A",
    fontFamily: "Poppins",
    fontWeight: '600',
    fontSize: "18px",
    color: '#1D1D1F'
  },

  "& .actions-row": {
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px',
    justifyContent: 'center',
    gap: '40px',
  },

  "& .save-btn": {
    padding: '10px 16px',
    height: '55px',
    borderRadius: '10px',
    justifyContent: 'center',
    fontSize: '18px',
    backgroundColor: "#FF881A",
    fontFamily: "Montserrat",
    display: 'inline-flex',
    fontWeight: '600',
    color: "#1D1D1F",
    width: '200px',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  },

  "& .back-btn": {
    height: '55px',
    fontWeight: '600',
    borderRadius: '8px',
    fontFamily: "Montserrat",
    border: '1px solid #FF881A',
    padding: '10px 16px',
    display: 'inline-flex',
    justifyContent: 'center',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer',
    width: '200px',
    flexDirection: 'row',
    alignItems: 'center'
  },
}))

const ViewPermissionModal = styled(Modal)(({theme}) => ({
  width: '780px',
  margin: 'auto',
  color: 'white',
  height: 'fit-content',
  borderRadius: '10px',
  padding: '40px',
  backgroundColor: "#1E1E1F",
  boxSizing: "border-box",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  "& *": {
    boxSizing: 'border-box'
  },

  "& .close-btn": {
    position: 'absolute',
    right: '0',
    top: '0',
  },

  "& .title-text": {
    fontFamily: "Poppins",
    fontWeight: '700',
    fontSize: '20px',
    color: "#CFD1D4",
  },

  "& .sub-text": {
    marginTop: '32px',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '18px',
    color: "#676767"
  },

  "& .viewer-list": {
    width: "calc(100% - 68px)",
    borderRadius: '10px',
    backgroundColor: "#252527",
    padding: '16px',
    marginTop: '24px',
    height: '414px',
    overflow: 'hidden'
  },

  "& .list-header": {
    display: "flex",
    flexDirection: "row",
    width: '100%',
    marginTop: '24px',
  },

  "& .list-header-text": {
    flex: "1",
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    color: "#676767"
  },

  "& .view-list-scrollable": {
    height: '328px',
    overflow: "scroll"
  },

  "& .viewer-row": {
    width: '100%',
    marginTop: '8px',
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    padding: '4px 16px',
    backgroundColor: '#1D1D20',

    "& img": {
      borderRadius: '50%',
    }
  },

  "& .viewer-info": {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    maxWidth: '50%',
  },

  "& .viewer-name-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '18px',
    color: "#CFD1D4",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  "& .permission-dropdown": {
    minWidth: '230px',
    height: '40px',

    "& .select-container": {
      height: '40px !important',
    },

    "& *:not(.css-26l3qy-menu)": {
      maxHeight: '100% !important'
    },

    "& .css-26l3qy-menu": {
      padding: "0 8px",
      borderRadius: '10px',
      border: "1px solid #FF881A",
      backgroundColor: "#1D1D20 !important",
      fontFamily: "Poppins",
      fontWeight: '400',
      fontSize: '18px',
      color: "#CFD1D4",

      "& .css-bqjh3z-option": {
        backgroundColor: "#1D1D20",
      },

      "& .css-1tlt3hm-option": {
        color: "#1D1D1F"
      },

      '& [class*="-option"]': {
        borderRadius: '8px',
        marginTop: '2px',
        cursor: 'pointer',

        "&:hover": {
          color: "#1D1D1F !important",
          backgroundColor: '#FF881A',
        },

        "&:last-child": {
          color: "#FF1919"
        }
      }
    }
  },

  "& .actions-row": {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    marginTop: '24px',
  },

  "& .assign-btn": {
    height: '55px',
    borderRadius: '8px',
    border: '1px solid #FF881A',
    padding: '10px 16px',
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    color: "#FF881A",
    cursor: 'pointer',
    width: '200px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },

  "& .save-btn": {
    height: '55px',
    borderRadius: '10px',
    padding: '10px 16px',
    backgroundColor: "#FF881A",
    fontFamily: "Montserrat",
    fontWeight: '600',
    fontSize: '18px',
    color: "#1D1D1F",
    width: '200px',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  }
}))
// Customizable Area End
