import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import CustomToastAddEvent from "../../../components/src/toast/CustomToastAddEvent.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { LIST_COLOR_VALUE_EVENT, TValueEvent } from "../../../components/src/CustomKeyboards/Events.web";
import { GameFolder } from "../../../blocks/dashboard/src/DashboardController.web";

export interface Video {
  id: number,
  type: string,
  account_id: number,
  uploading_for: string,
  game_score1: null,
  game_score2: null,
  team_name: string,
  oppo_team_name: string,
  game_date: string,
  game_type: string,
  game_status: null,
  auto_generate_folder: null,
  auto_inner_generate_folder: null,
  parent_folder: string,
  files: {
    filename: string,
    url: string,
  }[],
  created_by: string,
  mark_as_finished: null | true
}

interface IEventInformation {
  id: number,
  name: string
}

interface IUserForAddEvent {
  id: string;
  type: string;
  attributes: {
    fullname: string;
    photo: null | string;
  }
}

export interface Event {
  id: number,
  type: string,
  attributes: {
    default_action_id: IEventInformation,
    sub_action_id: IEventInformation,
    value_id: IEventInformation | null,
    reason_id: IEventInformation | null,
    sub_reason_id: IEventInformation | null,
    event_duration: string,
    attachment_id: number,
    photo: string,
    current_user_bookmarked: boolean;
  }
}

export interface IListValueKeyboard{
  listAction?: IEvent[];
  listSubAction?: IEvent[];
  listValue?: IEvent[];
  listReason?: IEvent[];
  listSubReason?: IEvent[];
  commentInput?: string;
}

export interface IEvent {
  id: number,
  name: string;
  default_action_id?: number,
  isActive?: boolean;
}

interface IListActionKeyBoard {
  action: IEvent[],
  sub_action: IEvent[],
  values: IEvent[],
  reason: IEvent[],
  sub_reason: IEvent[]
}

interface IActionKeyboard {
  id: number,
  name: string
}

type TResponseListActionKeyboard = IActionKeyboard & IListActionKeyBoard

export interface Comment {
  id: number,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isGridView: boolean;
  videoDetail: Video | null;
  videoUrl: string;
  events: Event[];

  showModalAddEvent: boolean;
  time: number;

  // List value of keyboard
  listAction: IEvent[];
  listSubAction: IEvent[];
  listValue: IEvent[];
  listReason: IEvent[];
  listSubReason: IEvent[];
  listActionKeyboard: IListActionKeyBoard;
  isCanSubmitEvent: boolean;

  clipDuratonBefore: number;
  clipDuratonAfter: number;

  comments: Comment[],
  selectedEventId: number | null,
  showComemntModal: boolean,
  commentInput: string,

  videoSpeedMenuAnchor: any,
  currentVideoSpeed: number;

  isLoading: boolean;
  isOpenModal: boolean;
  statusMarking: {
    status: string;
    missing_value?: number;
    type_missing_value?: string;
  };
  isMarkFinished: boolean;

  gameFolders: GameFolder[];

  listUserForAddEvent: IUserForAddEvent[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoEventController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  typeSport = "";
  videoRef: any;
  videoId: number;
  apiGetVideoDetailCallId: string = "";
  apiGetEventsCallId: string = "";
  apiGetActionKeyBoardCallId: string = "";
  apiGetDashboardCallId: string = "";
  apiGetUsersForAddEventCallId: string = "";
  apiCreateEventCallId: string = "";
  apiDeleteEventCallId: string = "";
  apiFinishMarkingCallId: string = "";
  apiHandleBookmarkActiveCallId: string = "";

  apiGetComemntsCallId: string = "";
  apiAddComemntCallId: string = "";

  speedList = [0.5, 1, 1.5, 2, 2.5]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isGridView: true,
      videoDetail: null,
      videoUrl: "",
      events: [],

      showModalAddEvent: false,
      time: 0,

      listAction: [],
      listSubAction: [],
      listValue: [],
      listReason: [],
      listSubReason: [],
      isCanSubmitEvent: false,
      listActionKeyboard: {
        action: [],
        sub_action: [],
        values: [],
        reason: [],
        sub_reason: []
      },

      clipDuratonBefore: 1,
      clipDuratonAfter: 1,

      comments: [],
      selectedEventId: null,
      showComemntModal: false,
      commentInput: "",

      videoSpeedMenuAnchor: null,
      currentVideoSpeed: 1,
      isLoading: false,
      statusMarking: {
        status: ""
      },
      isOpenModal: false,
      isMarkFinished: false,
      gameFolders: [],
      listUserForAddEvent: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoId = this.props.navigation.getParam("videoId", null);
    this.videoRef = React.createRef();

    if (!this.videoRef.current) {
      this.videoRef = {
        current: {
          getCurrentTime: () => 0,
          getCurrentMarker: () => 0,
          toggleFullScreen: () => { },
          play: () => { },
          pause: () => { },
          back: () => { },
          back5s: () => { },
          back15s: () => { },
          next: () => { },
          next5s: () => { },
          next15s: () => { },
        }
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const getResponseData = (message: Message) => message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const getResponseSuccess = (message: Message) => message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (!isResponseMessage) return;

    const handlers = {
      [this.apiGetVideoDetailCallId]: () => {
        const responseJson: { data: Video } = getResponseSuccess(message);
        this.setState({ videoDetail: responseJson?.data, isMarkFinished: Boolean(responseJson?.data?.mark_as_finished) });
        const optimizedVideo = responseJson.data?.files.find(element => element.url.includes("_master.m3u8"));
        this.setState({ videoUrl: optimizedVideo?.url + "" });
      },
      [this.apiGetEventsCallId]: () => {
        const responseJson: { data: Event[] } = getResponseSuccess(message);
        if (responseJson?.data) {
          this.setState({ events: responseJson.data, isLoading: false });
          this.videoRef.current.getCurrentMarker();
        }
      },
      [this.apiCreateEventCallId]: async() => {
        const responseJson = getResponseSuccess(message);
        if (responseJson?.status === "SUCCESS") {
          // Show toast
          toast(<CustomToastAddEvent />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
            style: {
              background: "#00000099",
              padding: "8px",
              borderRadius: "16px",
              width: "280px"
            }
          })

          await this.getEvents();
        } else {
          toast(<CustomToastAddEvent isError={true}/>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
            style: {
              background: "#00000099",
              padding: "8px",
              borderRadius: "16px",
              width: "280px"
            }
          })
        }
        this.closeModalAddEvent();
        this.setState({isLoading: false});
      },
      [this.apiDeleteEventCallId]: () => {
        this.getEvents();
      },
      [this.apiGetComemntsCallId]: () => {
        const responseJson = getResponseSuccess(message);
        this.setState({ comments: responseJson?.data });
      },
      [this.apiAddComemntCallId]: () => {
        this.getComemnts();
      },
      [this.apiGetActionKeyBoardCallId]: () => {
        const responseJson: { actions: TResponseListActionKeyboard[]} = getResponseSuccess(message);
        const actions = responseJson?.actions;
        const listActions = actions.filter(actions => ({id: actions.id, name: actions.name}))
        if(listActions.length){
          this.setState({ listActionKeyboard: this.mapResponseToListActionKeyboard(actions), listAction: listActions });
        }
      },
      [this.apiFinishMarkingCallId]: () => {
        const responseJson = getResponseSuccess(message);
        this.setState({isOpenModal: true, statusMarking: responseJson})
      },
      [this.apiGetDashboardCallId]: () => {
        const responseJson = getResponseSuccess(message);
        const { game_folders } = responseJson;
        this.setState({gameFolders: game_folders?.data || []});
      },
      [this.apiGetUsersForAddEventCallId]: () => {
        const responseJson = getResponseSuccess(message);
        const { data } = responseJson;
        this.setState({listUserForAddEvent: data || []})
      },
      [this.apiHandleBookmarkActiveCallId]: () => {
        this.getEvents();
      }
    };

    const callId = getResponseData(message);
    if (handlers[callId]) {
      handlers[callId]();
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.typeSport =  await getStorageData('typeSport') ;
    await this.getActionKeyboard();
    await this.getVideoDetail();
    await this.getEvents();
    await this.getDashboardData();
    await this.getUsersForAddEvent();
  }

  getVideoDetail = async () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetVideoDetailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVideoDetailEndPoint}/${this.videoId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getEvents = async () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetEventsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.eventsEndPoint}?attachment_id=${this.videoId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    };

  convertSecondsToHMS(seconds: number | null) {
    if(!seconds) return "00:00:00";
    // Round the seconds to the nearest whole number
    seconds = Math.round(seconds);

    // Calculate hours
    const hours = Math.floor(seconds / 3600);
    // Calculate remaining seconds after extracting hours
    seconds %= 3600;
    // Calculate minutes
    const minutes = Math.floor(seconds / 60);
    // Calculate remaining seconds after extracting minutes
    const remainingSeconds = seconds % 60;

    // Format hours, minutes, and seconds to be two digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  getDashboardData = async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDashboardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDashboardDataEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUsersForAddEvent = async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUsersForAddEventCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUsersForAddEventEndPoint}?attachment_id=${this.videoId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getActionKeyboard = async () => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetActionKeyBoardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getActionsKeyBoardEndPoint}?attachment_id=${this.videoId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setActivationBookmark = async (eventId: string, method: "Post" | "Delete") => {
    const token = await getStorageData("authToken");
    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiHandleBookmarkActiveCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bookmarkEndPoint}?add_event_comment_id=${eventId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addEvent = async (newEvent: {time: number | null, action: number | null, subAction: number | null, value: number | null, reason: number | null, subReason: number | null}) => {
    const {
      time,
      action,
      subAction,
      value,
      reason,
      subReason
    } = newEvent;

    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateEventCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.eventsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const add_event_comment: {[key: string]: string | number} = {};

    const items = [
      { key: 'action_id', value: action },
      { key: 'sub_action_id', value: subAction },
      { key: 'value_id', value: value },
      { key: 'reason_id', value: reason },
      { key: 'sub_reason_id', value: subReason },
      { key: 'event_duration', value: this.convertSecondsToHMS(time) },
      { key: 'comment', value: this.state.commentInput },
      { key: 'attachment_id', value: this.videoId}
    ];
    
    items.forEach(item => {
      if (item.value !== null && item.value !== undefined) {
        add_event_comment[item.key] = item.value;
      }
    });

    const bodyJson = {
      add_event_comment
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyJson)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteEvent = async (eventId: number) => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteEventCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEventEndPoint}/${eventId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleFinishMarking = async () => {
    const token = await getStorageData("authToken");

    const header = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFinishMarkingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.finishMarkingEndPoint}?attachment_id=${this.videoId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleCommentModal(eventId: number) {
    this.setState({ selectedEventId: eventId, showComemntModal: true, comments: [] }, this.getComemnts);
  }

  hideCommentModal() {
    this.setState({ showComemntModal: false });
  }

  getComemnts = async () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetComemntsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsEndPoint}?video_event_id=${this.state.selectedEventId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  

  addComment = async () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddComemntCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formdata = new FormData();
    formdata.append("video_event_id", this.state.selectedEventId + "");
    formdata.append("comment", this.state.commentInput);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickSendComment() {
    if(!this.state.commentInput) return;
    this.setState({ commentInput: "" });
    this.addComment();
  }

  onChangeCommentInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ commentInput: event.target.value })
  }

  showAddEventModal() {

    if (this.videoRef.current.getCurrentMarker()) {
      alert("Can not add an event inside an event.")
      return;
    }

    this.videoRef.current.pause();
    this.setState({
      showModalAddEvent: true,
      time: this.videoRef.current.getCurrentTime()
    });
  }

  hideAddEventModal() {
    this.setState({
      showModalAddEvent: false,
    });
  }

  async submitAddEvent() {
    // Loading status
    this.setState({isLoading: !this.state.isLoading, showModalAddEvent: false})

    await this.addEvent({
      action: this.state.listAction.find(action => action.isActive)?.id ?? null,
      subAction: this.state.listSubAction.find(subAction => subAction.isActive)?.id ?? null,
      value: this.state.listValue.find(value => value.isActive)?.id ?? null,
      reason: this.state.listReason.find(reason => reason.isActive)?.id ?? null,
      subReason: this.state.listSubReason.find(subReason => subReason.isActive)?.id ?? null,
      time: this.state.time
    })

  }

  closeModalAddEvent() {
    this.setState({ 
      showModalAddEvent: false,
      listReason: [],
      listSubAction: [],
      listSubReason: [],
      listValue: [],
      listAction: this.state.listActionKeyboard.action,
      isCanSubmitEvent: false
    });
  }

  convertTimeStringToNumber(timeString: string) {
    // timeString like 01:22:09
    if(!timeString) return 0
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }

  toggleFullScreen() {
    this.videoRef.current.toggleFullScreen();
  }


  onChangeLayout() {
    this.setState({ isGridView: !this.state.isGridView });
  }

  onToggleSpeedMenu(event: any) {
    this.setState({ videoSpeedMenuAnchor: event.currentTarget });
  }

  onHideSpeedMenu(speedElm: number) {
    this.setState({
      currentVideoSpeed: speedElm
    })
    const timer = setTimeout(() => {
      this.setState({ videoSpeedMenuAnchor: null });
      clearTimeout(timer);
    }, 300);
  }

  getHeaderEvent() {
   if(this.typeSport === "Sports")
    return "Physical, Mental and Sports IQ Events"
   return "Technical, Mental and Game IQ Events"
  }
  mappingValueRenderStep = (stepAction: number, actionId?: number) => {
    const actionIdBeSelected = actionId ? actionId : this.state.listAction.find(action => action.isActive)?.id;
    const mappingSubActionByActionId = this.state.listActionKeyboard.sub_action.filter(dataSubAction => dataSubAction.default_action_id === actionIdBeSelected);
    const mappingValueByActionId = this.state.listActionKeyboard.values.filter(dataValue => dataValue.default_action_id === actionIdBeSelected);
    const mappingReasonByActionId = this.state.listActionKeyboard.reason.filter(dataReason => dataReason.default_action_id === actionIdBeSelected);
    const mappingSubReasonByActionId = this.state.listActionKeyboard.sub_reason.filter(dataSubReason => dataSubReason.default_action_id === actionIdBeSelected);
    const listKeyStep = ["listSubAction", "listValue", "listReason", "listSubReason"];
    const mappedData = [mappingSubActionByActionId, mappingValueByActionId, mappingReasonByActionId, mappingSubReasonByActionId];
  
    // Get the last index mapping has value
    let indexLastItemHasValue = -1;
    for (let index = mappedData.length - 1; index >= 0; index--) {
      if (mappedData[index].length > 0) {
        indexLastItemHasValue = index;
        break;
      }
    }
  
    const indexStepAction = stepAction - 2;
    let isHandleRenderNextStep = false;
    const newState: Partial<S> = {};
    
    // Start at stepAction - 2 because the first key in the array is in step 2
    for (let index = indexStepAction; index < mappedData.length; index++) {
      if (!isHandleRenderNextStep && mappedData[index].length) {
        newState[listKeyStep[index] as keyof S] = mappedData[index];
        isHandleRenderNextStep = true;
        if (index === mappedData.length - 1) {
          newState.isCanSubmitEvent = true;
        }
      } else {
        newState[listKeyStep[index] as keyof S] = [];
        newState.commentInput = "";
        newState.isCanSubmitEvent = false;
      }
    }
  
    if ((indexStepAction - 1 === listKeyStep.length - 2) || indexStepAction > indexLastItemHasValue) {
      newState.isCanSubmitEvent = true;
    }
  
    this.setState(prevState => ({
      ...prevState,
      ...newState
    }));
  };

  handleSelectAction = (actionId: number) => {
    this.setState({
      listAction: this.state.listAction.map(action => action.id === actionId ? ({...action, isActive: true}) : ({...action, isActive: false})),
      isCanSubmitEvent: false
    })
    this.mappingValueRenderStep(2, actionId);
  }

  handleSelectSubAction = (subActionId: number) => {
    this.setState({
      listSubAction: this.state.listSubAction.map(subAction => subAction.id === subActionId ? ({...subAction, isActive: true}) : ({...subAction, isActive: false})),
    })
    this.mappingValueRenderStep(3);

  }

  handleSelectValue = (valueId: number) => {
    this.setState({
      listValue: this.state.listValue.map(value => value.id === valueId ? ({...value, isActive: true}) : ({...value, isActive: false})),
    })
    this.mappingValueRenderStep(4)
  }

  handleSelectReason = (reasonId: number) => {
    this.setState({
      listReason: this.state.listReason.map(reason => reason.id === reasonId ? ({...reason, isActive: true}) : ({...reason, isActive: false})),
    })
    this.mappingValueRenderStep(5)
  }

  handleSelectSubReason = (subReasonId: number) => {
    this.setState({
      listSubReason: this.state.listSubReason.map(subReason => subReason.id === subReasonId ? ({...subReason, isActive: true}) : ({...subReason, isActive: false}))
    })
    this.mappingValueRenderStep(6)
  }

  getShadowColorDialog = () => {
    const currentValueIsActive = this.state.listValue.find(value => value.isActive)?.name.toLowerCase();
    const boxShadowColor = LIST_COLOR_VALUE_EVENT[currentValueIsActive as TValueEvent]
    return boxShadowColor ? `0px -4px 16px 0px ${boxShadowColor}` : "none"
  }

  mapResponseToListActionKeyboard = (response: any): IListActionKeyBoard => {
    const listActionKeyboard: IListActionKeyBoard = {
      action: [],
      sub_action: [],
      values: [],
      reason: [],
      sub_reason: []
    };
  
    response.forEach((actionGroup: any) => {
      listActionKeyboard.action.push({
        id: actionGroup.id,
        name: actionGroup.name,
      });
  
      listActionKeyboard.sub_action.push(...actionGroup.sub_actions);
  
      listActionKeyboard.values.push(...actionGroup.values);
  
      listActionKeyboard.reason.push(...actionGroup.reasons);
  
      listActionKeyboard.sub_reason.push(...actionGroup.sub_reasons);
    });
  
    return listActionKeyboard;
  }

  handleCloseModal = async () => {
    this.setState({isOpenModal: false});
    await this.getVideoDetail();
  }

  handleGoBack = () => {
    this.props.navigation.navigate("ContentManagement")
  }

  handleActiveBookmarkIcon = (eventId: string, currentUserBookmark: boolean) => {
    this.setState({isLoading: true})
    // If current user bookmark is false will deactive bookmark
    if(currentUserBookmark)
      this.setActivationBookmark(eventId, "Delete")
    else 
      this.setActivationBookmark(eventId, "Post")
  }
  // Customizable Area End
}
