import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import OTPVerificationForm from "../../../components/src/OTPVerificationForm.web";
import { 
  NavigateBefore 
} from "@material-ui/icons";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import { 
  group_logo
 } from "../../landingpage/src/assets";
// Customizable Area End

import { bgImage, bgImageEsports, bgImageSports, epic, eyeOff, eyeOn, logo, riot, steam } from "./assets";
import LoginBlockController, { Props } from "./LoginBlockController.web";

const LoginStyle = styled(Box)({
  "& input:-webkit-autofill": {
    "-webkit-box-shadow": "0 0 0 30px #1D1D1F inset !important",
    "-webkit-text-fill-color": "#CFD1D4 !important",
  },
  "& .lableStyle": {
    color: "#676767",
    fontWeight: 400,
    fontSize: "20px",
    fomtFamily: "Poppins",
  },
  "& .MuiBox-root-2": {
    height: "100%",
  },
  "& .MuiFilledInput-underline": {
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
  },
  "& .inputField": {
    width: "100%",
    color: "#CFD1D4",
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    backgroundColor: "#1D1D1F",
    padding: "7px 0px",
    "& .errorMessage": {
      color: "#FF1919",
      fontSize: "14px",
      width: "65%",
      fontWeight: 400,
      fontFamily: "Poppins",
      marginRight: "4px",
    },
  },
  "& .inputField.errorField": {
    border: "1px solid #FF1919",
  },
  "& .MuiInput-underline::before": {
    borderBottom: "none",
    outline: "none",
  },
  "& focus: .inputField": {
    outline: "none",
    border: "none",
  },
  "& .MuiInput-underline::after": {
    borderBottom: "none",
  },
  "& .MuiInputBase-input": {
    color: "#CFD1D4",
    // borderRadius: "10px",
    padding: "10px 8px 10px 8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
  },
  "& .logoImage": {
    display: "flex",
    justifyContent: "center",
    margin: "47px auto",
    width: 220,
  },
  "& .boxContainner": {
    display: "flex",
    flex: "0 0 calc(100%/12*3.5)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
  },
  "& .buttonContainer": {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  "& .btn-below-line": {
    border: "1px solid #676767",
    width: "19vw",
    marginTop: 14,
  },
  "& .loginBtn": {
    backgroundColor: "#FF881A",
    width: "100%",
    maxWidth: "150px",
    color: "#1D1D1F",
    textTransform: "none",
    fontWeight: 400,
    padding: "15px 2px",
    fontSize: "18px",
    lineHeight: "20px",
    borderRadius: "6px",
    fontFamily: "Poppins",
  },
  "& .games-company-container": {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    alignSelf: "center",
    marginBottom: "30px",
  },
  "& .bottom-link-text": {
    color: "#FF881A",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    cursor: "pointer",
    textDecoration: "underline",
  },
  "& .bottom-container": {
    display: "flex",
    fontFamily: "Poppins",
    alignItems: "baseline",
    justifyContent: "center",
  },
  "& .bottom-text": {
    color: "#CFD1D4",
    paddingRight: "1%",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Poppins",
  },
  "& .forgot-password-text": {
    color: "#FF881A",
    fontSize: "16px",
    fontFamily: "Poppins",
    marginTop: "25px",
    width: "100%",
    textDecoration: "underline",
    display: "flex",
    flexDirection: "row-reverse",
  },
  "& .img-container-box": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "-11px 0px 13px 0px #00000040 inset",
    width: "100%",
    background: "#252527",
    height: "max-content",
    minHeight: "100vh",
  },
  "& .input-container": {
    width: "100%",
    padding: "0 20px",
    boxSizing: "border-box",
  },
  "& .img-style": {
    margin: "1%",
    height: 36.67,
    width: 36.67,
  },
  "& .card-text": {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Montserrat",
    color: "#CFD1D4",
  },
  "& .card-container": {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "50px",
    display: "flex",
    boxSizing: "border-box",
    padding: "0 20px",
  },
  "& .social-box": {
    marginTop: 24,
    marginBottom: 30,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .input-lable-box": {
    marginTop: "24px",
    width: "100%",
  },
  "& .image-body": {
    flex: "0 0 calc(100%/12*8.5)",
    display: "flex",
    overflowX: "hidden",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  "& .image-wrapper": {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    overflow: "auto",
    position:"relative",
    "& > img":{
      position:"absolute",
      zIndex:-1,
      inset:0,
      width:"100vw",
      height:"100%"
    }
  },
  "& .validationError": {
    color: "#FF1919",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
  },

  "& .otp-container": {
    backgroundColor: "transparent",

    "& .input-container": {
      padding: "0",
    },

    "& .MuiFilledInput-underline:first-child": {
      border: "none",
    },
  },

  "& .otp-form-container": {
    position: "relative",
    width: "100%",
  },

  "& .back-btn-container": {
    position: "absolute",
    top: "-40px",
    left: "25px",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "center",
    width: "fit-content",
    alignItems: "center",
    cursor: "pointer",

    "&:hover .back-btn-text": {
      textDecoration: "underline",
    },
  },

  "& .back-icon": {
    width: "24px",
    height: "24px",
    fill: "#FF881A",
  },

  "& .back-btn-text": {
    color: "#FF881A",
    fontFamily: "Poppins",
    fontSize: "16px",
  },
});

export const TermPrivacy = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  alignSelf: "center",

  "& .label": {
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    color: "#CFD1D4",
    marginLeft: 7,
  },

  "& .label-yellow": {
    fontSize: "16px",
    fontFamily: "Poppins",
    textTransform: "none",
    color: "#FF881A",
    marginLeft: 7,
    textDecoration: "underline",
  },
});

const backgroundImage:Record<string,string>={
  common:bgImage,
  eSports:bgImageEsports,
  Sports:bgImageSports,
}

export default class LoginBlock extends LoginBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderTermAndPrivacy() {
    return (
      <TermPrivacy>
        <Button onClick={this.showTermAndPrivacy.bind(this)}>
          <Typography className="label-yellow">
            {"Terms and Conditions"}
          </Typography>
          <Typography className="label">{"and"}</Typography>
          <Typography className="label-yellow">{"Privacy Policy"}</Typography>
        </Button>
      </TermPrivacy>
    );
  }

  renderSplash() {
    return (
      <Box
        style={{
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={group_logo} width={280} height={140} alt="bg-spinner" />
      </Box>
    )
  }

  renderOTP() {
    return (
      <Box className="otp-form-container">
        <OTPVerificationForm
          email=""
          onClickResend={this.onClickResendOTP}
          onSubmit={this.onSubmitOTP}
          errorMsg={this.state.otpErrorMsg}
          className="otp-container"
        />

        <Box
          className="back-btn-container"
          onClick={this.handleClickBackBtn}
          data-test-id="back-btn"
        >
          <NavigateBefore className="back-icon" />

          <Typography component="span" className="back-btn-text">
            Back to login
          </Typography>
        </Box>
      </Box>
    )
  }

  renderUserNameInput() {
    return (
      <Box style={{ marginTop: "2%", width: "100%" }}>
        <Typography className="lableStyle">Username</Typography>
        <TextField
          className={`inputField ${this.state.isErrorPass.isInputField &&
            this.state.isErrorPass.message ===
            "Incorrect username"
            ? "errorField"
            : ""
            }`}
          data-test-id="textEmail"
          InputProps={{
            disableUnderline: true,
            endAdornment:
              this.state.isErrorPass.isInputField &&
                this.state.isErrorPass.message ===
                "Incorrect username" ? (
                <p
                  className="errorMessage"
                  style={{ width: "270px" }}
                >
                  {this.state.isErrorPass.message}
                </p>
              ) : (
                ""
              ),
          }}
          onChange={(e) =>
            this.setState({ email: e?.target?.value })
          }
        />
        {this.state.isErrorEmail &&
            <span className="validationError">
              {"Please enter a username"}
            </span>
          }
      </Box>
    )
  }

  renderPasswordInput() {
    return (
      <Box className="input-lable-box">
        <Typography className="lableStyle">Password</Typography>
        <TextField
          className={`inputField ${this.state.isErrorPass.isInputField &&
            this.state.isErrorPass.message ===
            "Incorrect password"
            ? "errorField"
            : ""
            }`}
          data-test-id="inputFieldPass"
          onChange={(e) =>
            this.setState({ password: e?.target?.value })
          }
          type={
            this.state.enablePasswordField ? "password" : "text"
          }
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {this.state.isErrorPass.isInputField &&
                  this.state.isErrorPass.message ===
                  "Incorrect password" ? (
                  <p className="errorMessage">
                    {this.state.isErrorPass.message}
                  </p>
                ) : (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                    style={{
                      color: "#676767",
                      marginRight: "0px",
                    }}
                  >
                    {this.state.enablePasswordField ? (
                      <img
                        alt="visibilityOff"
                        width={32}
                        src={eyeOff}
                      />
                    ) : (
                      <img
                        alt="visibilityOn"
                        width={32}
                        src={eyeOn}
                      />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        {this.state.isErrorPass.show &&
          !this.state.isErrorPass.isInputField && (
            <span className="validationError">
              {this.state.isErrorPass.message}
            </span>
          )}
      </Box>
    )
  }

  renderLoginInput() {
    return (
      <>
        <Box className="input-container">
            {this.renderUserNameInput()}
            {this.renderPasswordInput()}
          
          <Box
            data-test-id="goToForgetPass"
            className="forgot-password-text"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.goToForgotPassword()}
            >
              Forgot Password?
            </span>
          </Box>
        </Box>
        {this.renderTermAndPrivacy()}
        <Box className="buttonContainer">
          <Button
            variant="contained"
            data-test-id="btnAddExample"
            className="loginBtn"
            onClick={() => this.doEmailLogIn()}
          >
            Log-In
          </Button>
        </Box>
        <Box
          style={{
            display:
              this.state.typeSport === "eSports" ? "flex" : "none",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Box className="btn-below-line" />
        </Box>
        <Box className="social-box">
          <Box
            className="games-company-container"
            style={{
              display:
                this.state.typeSport === "eSports"
                  ? "flex"
                  : "none",
            }}
          >
            <Button onClick={this.steamLogin} data-test-id="steamBtn">
              <img
                src={steam}
                alt="SSO Steam"
                className="img-style"
              />
            </Button>
            <Button variant="text" onClick={this.epicLogin}  data-test-id="epicBtn">
              <img
                src={epic}
                alt="Epic Games"
                className="img-style"
              />
            </Button>
            <img
              src={riot}
              alt="Riot Games"
              className="img-style"
            />
          </Box>

          <Box className="bottom-container">
            <Typography className="bottom-text">
              Don’t have an account?
            </Typography>
            <Box
              className="bottom-link-text"
              data-test-id="btnForgetPass"
              onClick={() => this.goToSignUp()}
            >
              Sign up here!
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  renderLoginContent() {
    return (
      <Box className="image-wrapper">
        <img src={backgroundImage[this.state.typeSport]} alt="image"/>
        <Box className="boxContainner">
          <Box className="img-container-box">
            <Box>
              <img className="logoImage" src={logo} />
            </Box>
            {this.state.showOTP ? this.renderOTP() : this.renderLoginInput()}
            <Box className="card-container">
              <Box
                style={{
                  display: "flex",
                  padding: "16px",
                  backgroundColor: "#1D1D1F",
                  width: "100%%",
                  borderRadius: "10px",
                }}
              >
                <Box>
                  <Typography className="card-text">
                    Every access to this website is logged. Only authorized users may enter.
                    ©2017-2020 Peak Performance Result LTD & 2020 Fantastic Athletes Corporation
                    This system is patent protected - US Patent Pending # US2020133976(A1)
                  </Typography>
                </Box>
                <Box
                  style={{ border: "1px solid #FF881A", margin: "0 4px" }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <img style={{ width: "100px" }} src={logo} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="image-body" />
      </Box>
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <LoginStyle>
        {this.state.loadingSplash ? this.renderSplash() : this.renderLoginContent()}
        <TermsConditions
          navigation="" id=""
          showModal={this.state.showModalTermPrivacy}
          showAcceptButton={false}
          onClosePress={this.hideTermAndPrivacy.bind(this)}
          onAcknowledgePress={this.hideTermAndPrivacy.bind(this)}
        />
      </LoginStyle>
      // Customizable Area End
    );
  }
}
