Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.patchApiMethod = "PATCH";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.inviteUserEndPoint="bx_block_settings/team/invitations"
exports.userTeamEndPoint="bx_block_settings/account/teams/statistic"
exports.groupsApiEndPoint = "/bx_block_settings/team/accounts";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.deleteAccountsApiEndPoint = "account_block/accounts/delete_account";


exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "* All fields are mandatory.";

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getEsportTitlesApiEndPoint = "/account_block/esport_title_list"
exports.getEsportRolesApiEndPoint = "/account_block/in_game_roles"
exports.getSportTitlesApiEndPoint = "/account_block/sport_title_list"
exports.getSportPositionsApiEndPoint = "/account_block/get_positions"
exports.createUserInTeamEndPoint = "account_block/accounts"
exports.textClose = "Close";
exports.textSave = "Save";
exports.textUpdate = "Update";
exports.textAddGroup = "Add Group";
exports.textShowGroup = "Show Group";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textAccounts = "Accounts";
exports.textAddAcc = "Add Acc.";
exports.textDeleteAcc = "Delete Acc.";
exports.textId = "Id";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textSelectAccounts = "Select accounts";
// Customizable Area End
