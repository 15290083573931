import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { UserProfileData } from "./UserProfileController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  checkboxValue: Array<boolean>
  toastOpen: boolean
  token: string;
  twoFAEnabled: boolean;
  settingId: number;
  intialLoaded: boolean;
  otpModalOpen: boolean;
  email: string;
  userId: number;
  otpErrorMsg: string;
  userAvatar: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  enableTwoFACallId: string = '';
  getSettingCallId: string = '';
  saveNotificationCallId: string = '';
  createOTPCallId: string = '';
  verifyOTPCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      checkboxValue: [false, false, false],
      toastOpen: false,
      token: '',
      twoFAEnabled: false,
      settingId: 0,
      intialLoaded: false,
      otpModalOpen: false,
      email: '',
      userId: 0,
      otpErrorMsg: '',
      userAvatar:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

      const apiRequestCallIds = {
        [this.enableTwoFACallId]: this.handleEnableTwoFAResponse,
        [this.getSettingCallId]: this.handleGetSettingResponse,
        [this.saveNotificationCallId]: this.handleSaveNotificationResponse,
        [this.verifyOTPCallId]: this.handleVerifyOTPResponse
      }

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse)
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleVerifyOTPResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        const header = {
          "Content-Type": "application/json",
          token: this.state.token
        }

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        )

        this.enableTwoFACallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "/bx_block_settings/settings/activate_2fa"
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        )

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PATCH"
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {
        this.setState({ otpErrorMsg: responseJson.error })
      }
    } else {
      this.setState({ otpErrorMsg: "Invalid OTP secret" })
    }
  }

  onGetUserProfileResponse = (data: UserProfileData) => {
    this.setState({ email: data.data.attributes.email, userId: data.data.attributes.account_id })
  }

  handleClickResend = () => {
    this.createOTP();
  }

  handleCloseOTPModal = () => {
    this.setState({ otpModalOpen: false })
  }

  handleSubmitOtp = (otp: string) => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/settings/verify_otp"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    const body = {
      otp_secret_from_email: otp
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    this.verifyOTPCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleClickCustomKeyboard = () => {
    this.props.navigation.navigate("Cfcustomkeyboards")
  }

  handleChangeNotificationSetting = () => {
    const preferences = ['A player finished marking', 'Team Report is Ready to view', 'A new video has been uploaded'];

    const header = {
      token: this.state.token,
      "Content-Type": "application/json"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.saveNotificationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_settings/settings/update_setting_notification_preferences?id=${this.state.settingId}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    )

    const body: { preferences: Array<string> } = {
      preferences: []
    }

    this.state.checkboxValue.forEach((item, index) => {
      if (item) {
        body.preferences.push(preferences[index])
      }
    })

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSaveNotificationResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.errors) {
        this.setState({ toastOpen: true })
      }
    }
  }

  handleEnableTwoFAResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.errors) {
        this.setState(prevState => ({ toastOpen: true, twoFAEnabled: !prevState.twoFAEnabled, otpModalOpen: false }))
      }
    }
  }

  handleClickCheckbox = (position: number) => {
    const checkboxValue = this.state.checkboxValue
    checkboxValue[position] = !this.state.checkboxValue[position]

    this.setState((prevState: S) => {
      return {
        ...prevState,
        checkboxValue
      }
    })
  }

  handleGetSettingResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        const { id, attributes } = responseJson.setting.data
        const { two_factor_authentication, setting_notification_preferences } = attributes
        const { checkboxValue } = this.state
        this.setState({userAvatar:attributes.photo})

        if (setting_notification_preferences && setting_notification_preferences.length) {
          const { setting_notification_type } = setting_notification_preferences[0]

          Array(setting_notification_type).forEach((item: string) => {
            if (item.includes("A player finished marking")) {
              checkboxValue[0] = true
            }

            if (item.includes("Team Report is Ready to view")) {
              checkboxValue[1] = true
            }

            if (item.includes("A new video has been uploaded")) {
              checkboxValue[2] = true
            }
          })
        }

        if (two_factor_authentication === 'disabled') {
          this.setState({ twoFAEnabled: false, settingId: id, checkboxValue, intialLoaded: true })
        } else {
          this.setState({ twoFAEnabled: true, settingId: id, checkboxValue, intialLoaded: true })
        }
      }
    }
  }

  getSetting = (token: string) => {
    const header = {
      token: token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getSettingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/settings/get_setting"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createOTP = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.createOTPCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_settings/settings/create_otp_secret"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    const body = {
      user_id: String(this.state.userId)
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSubmit = () => {
    this.setState({ otpModalOpen: true })

    this.createOTP();
  }

  hideToast = () => {
    this.setState({ toastOpen: false })
  }

  async componentDidMount() {
    const token = localStorage.getItem("authToken") || ""

    this.setState({ token })

    this.getSetting(token);
  }

  handleUserProfileNaviagtion=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }
  handleSubscriptionNaviagtion=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
