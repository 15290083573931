import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  styled,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Modal,
  Input,
  Menu,
  MenuItem,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { Close, CloseOutlined } from "@material-ui/icons";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { 
  markVideoIcon, 
  layoutVideoIcon,
  filterIcon,
  back,
  back15s,
  back5s,
  next,
  next15s,
  next5s,
  blankAvatar,
  groupUserIcon,
  commentIcon,
  editIcon,
  deleteButton,
  starIconButton,
  reportIcon,
} from "./assets";
import CustomVideoPlayer from "../../../components/src/CustomVideoPlayer";
import Events, { getColorForValue } from "../../../components/src/CustomKeyboards/Events.web";
import Loader from "../../../components/src/Loader.web";
import ImageComponent from "../../../components/src/ImageComponent.web";
import { backIcon } from "../../../../packages/blocks/customisableusersubscriptions/src/assets";
import BrowseRecentGame from "../../../components/src/BrowseRecentGame.web";
import VideoEventController, { Props } from "./VideoEventController";
import ModalStatusMarking from "../../../components/src/Modal/ModalStatusMarking.web";
// Customizable Area End


export default class VideoEvent extends VideoEventController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  columns = [
    { id: 'photo', label: '', flex: 1 },
    { id: 'id', label: '#', flex: 1 },
    { id: 'default_action_id', label: 'ACTION', flex: 2 },
    { id: 'sub_action_id', label: 'SUB ACTION', flex: 2 },
    { id: 'value_id', label: 'VALUE', flex: 2 },
    { id: 'reason_id', label: 'REASON', flex: 2 },
    { id: 'sub_reason_id', label: 'SUB REASON', flex: 2 },
    { id: 'event_duration', label: 'TIME', flex: 2 },
    { id: 'options', label: 'OPTIONS', flex: 4 },
  ];

  renderTopMenu() {
    return(
      <TopMenuStyled>
        <Box data-test-id="top-menu-back-btn" className="top-menu-back-btn" onClick={this.handleGoBack}>
          <img src={backIcon} width={10} />
        </Box>

        <Button className="top-menu-item second-item">
          <Typography className="top-menu-label" style={{ color: "#FF881A" }}>Game: </Typography>
          <Typography style={{marginLeft: 8}} className="top-menu-label">{this.state.videoDetail?.team_name} Vs {this.state.videoDetail?.oppo_team_name}</Typography>
        </Button>
        <Box style={{ flex: 1 }} />
        {!this.state.isMarkFinished ? (<Button className="top-menu-item" data-test-id="btn_finish_marking" onClick={() => this.handleFinishMarking()}>
          <Typography className="top-menu-label">Finish Marking</Typography>
          <img src={markVideoIcon} className="menu-icon" />
        </Button>) : <Button className="top-menu-item" style={{border: "1px solid #FF881A"}}>
          <Typography className="top-menu-label">Go to Report</Typography>
          <img src={reportIcon} className="menu-icon" />
        </Button>}
        <Box style={{ width: 24 }}/>
        <Button data-test-id="layout-btn" className="top-menu-item" onClick={() => this.onChangeLayout()}>
          <Typography className="top-menu-label">Layout</Typography>
          <img src={layoutVideoIcon} className="menu-icon" /> 
        </Button>
      </TopMenuStyled>
    )
  }

  renderPlayerPOV() {
    return (
      <>
        {this.state.listUserForAddEvent.map((item, index) => (
          <Box style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            marginLeft: 10,
          }}
            key={index}
          >
            <Typography>{item?.attributes?.fullname}</Typography>
            <ImageComponent alt="i" fallbackSrc={blankAvatar} widthImage="80px" heightImage="80px" style={{borderRadius: 10, overflow: "hidden"}} src={item.attributes?.photo || blankAvatar}/>
          </Box>
        ))}
      </>
    )
  }

  renderTableHeader() {
    return (
      <TableHead>
        <TableRow>
          {this.columns.map((column) => (
            <TableCell
              className="tableCell"
              key={column.id}
              align={"center"}
              style={{ flex: column.flex, fontSize: "10px", fontWeight: 600, color: "#676767", textAlign: "center", padding: 0 }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  renderEventTable() {

    return (
      <EventTableStyled>
        <Box className="header"
          data-test-id="event-item-container"
        >
          <Box>
            <Typography style={{ color: "#CFD1D4", fontSize: 20 }}>Events</Typography>
            <Typography style={{ color: "#676767" }}>{this.getHeaderEvent()}</Typography>
          </Box>
          <Box style={{display: "flex", columnGap: "8px"}}>
            <Button className="btnHeader" >
              <Typography style={{ marginRight: 10, color: "#CFD1D4" }}>Player</Typography>
              <img style={{ width: 20, height: 20 }} src={groupUserIcon} />
            </Button>
            
            <Button className="btnHeader" >
              <Typography style={{ marginRight: 10, color: "#CFD1D4" }}>Filter</Typography>
              <img style={{ width: 20, height: 20 }} src={filterIcon} />
            </Button>
          </Box>
        </Box>
        <TableContainer className="tableContent">
          <Table>
            {this.renderTableHeader()}
            <TableBody>
              {this.state.events.map((rowItem: any) => {
                interface Column {
                  id: string,
                  flex: number
                }
                return (
                  <TableRow className="tableRow" hover role="checkbox" tabIndex={-1} key={rowItem.id}>
                    {this.columns.map((column: Column) => {
                      const value = rowItem.attributes[column.id] || rowItem.attributes[column.id]?.name || "-";
                      const eventId = rowItem?.id;
                      const isHasComment = Boolean(rowItem?.attributes?.comment);
                      const isBookmark = Boolean(rowItem?.attributes?.current_user_bookmarked);
                      const valueKeyboard = rowItem?.attributes?.value_id?.name;
                      return (
                        <TableCell className="tableCell" key={column.id} style={{ flex: column.flex, fontSize: "12px", fontWeight: 500, padding: 0, color: "#CFD1D4", textAlign: "center" }}>
                          {column.id === "photo" ? (
                            <ImageComponent
                              style={{ borderLeft: `4px solid ${getColorForValue(valueKeyboard)}`, borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}
                              fallbackSrc={blankAvatar}
                              alt="i"
                              widthImage="30px"
                              heightImage="30px"
                              src={(value === "-" || !value) ? blankAvatar : value} />
                          ) : undefined}
                          {column.id === "options" ? (
                            <Box style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                              <Box data-test-id="show-comment-btn" onClick={() => {}/* this.toggleCommentModal(rowItem.id) */ } style={{ cursor: "pointer" }}>
                                <ImageComponent widthImage="18px" heightImage="18px" alt="i" colorSvgLine={isHasComment ? "#FF881A" : ""} fallbackSrc={commentIcon} src={commentIcon} style={{ width: 16, height: 16 }}/>
                              </Box>
                              <Box>
                                <img src={editIcon} style={{ width: 18, height: 18 }}/>
                              </Box>
                              <Box data-test-id="delete-event-btn" onClick={() => { this.deleteEvent(rowItem.id) }} style={{ cursor: "pointer" }}>
                                <img src={deleteButton} style={{ width: 18, height: 18 }}/>
                              </Box>
                              <Box style={{cursor: "pointer"}} data-test-id={isBookmark ? "isActiveBookmark" : "isDeActivateBookmark"} onClick={() => this.handleActiveBookmarkIcon(eventId, isBookmark)}>
                                <ImageComponent widthImage="18px" heightImage="18px" alt="i" colorSvgLine={isBookmark ? "#FF881A" : ""} fallbackSrc={starIconButton} src={starIconButton}/>
                              </Box>
                            </Box>
                          ) : undefined}
                          {column.id !== "photo" && column.id !== "options" && (value?.name || value)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>          
        </TableContainer>
      </EventTableStyled>
    )
  }

  renderVideoArea = () => {
    return (
      <VideoAreaStyled>
        <div style={{ flex: "0 0 60%", maxWidth: "60%" }} data-test-id="video-player">
          {!!this.state.videoUrl ? (<CustomVideoPlayer
            ref={this.videoRef}
            clipDuratonBefore={this.state.clipDuratonBefore}
            clipDuratonAfter={this.state.clipDuratonAfter}
            videoUrl={this.state.videoUrl}
            markers={this.state.events.map(element => ({
              id: element.id,
              time: this.convertTimeStringToNumber(element.attributes.event_duration) || 0,
              action: element.attributes.default_action_id?.name,
              subAction: element.attributes.sub_action_id?.name,
              value: element.attributes.value_id?.name,
              reason: element.attributes.reason_id?.name,
              subReason: element.attributes.sub_reason_id?.name,
              photo: element.attributes?.photo
            }))}
            speed={this.state.currentVideoSpeed}
          />) : (
            <div style={{ flex: 1, height: window.innerWidth * 0.31, backgroundColor: "black" }} />
          )}
        </div>
        {this.state.isGridView && this.renderRightEventTable()}
        {!this.state.isGridView && this.renderSidePOV()}
      </VideoAreaStyled>
    )
  }

  renderSidePOV() {
    return (
      <SidePOVStyled>
        {!!this.state.listUserForAddEvent.length && <Typography>Team Videos:</Typography>}
        {this.renderPlayerPOV()}
      </SidePOVStyled>
    )
  }

  renderBottomPOV() {
    return (
      <BottomPOVStyled>
        {
          !!this.state.listUserForAddEvent?.length &&
          <Typography style={{ marginRight: 10, marginTop: 25 }}>Team Videos:</Typography>
        }
        
        {this.renderPlayerPOV()}
      </BottomPOVStyled>
    )
  }

  renderBottomEventTable() {
    return (
      <BottomEventTableStyled>
        {this.renderEventTable()}
      </BottomEventTableStyled>
    )
  }

  renderRightEventTable() {
    return (
      <RightEventTableStyled data-test-id="right-event-table">
        {this.renderEventTable()}
      </RightEventTableStyled>
    )
  }

  renderVideoController() {
    return (
      <VideoControllerStyled>
        <Box className="controller-wrapper">
          <Button data-test-id="back15s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back15s()}>
            <img src={back15s} className="video-controll-icon"/>
          </Button>
          <Button data-test-id="back5s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back5s()}>
            <img src={back5s} className="video-controll-icon"/>
          </Button>
          <Button data-test-id="back-btn" className="video-controll-btn" onClick={() => this.videoRef.current.back()}>
            <img src={back} className="video-controll-icon"/>
          </Button>
          <Button 
            data-test-id="change-speed-btn" 
            className="video-controll-btn" 
            onClick={this.onToggleSpeedMenu.bind(this)}
          >
            <Typography>{this.state.currentVideoSpeed}X</Typography>
            <Menu
              open={Boolean(this.state.videoSpeedMenuAnchor)}
              anchorEl={this.state.videoSpeedMenuAnchor}
              onClose={() => this.setState({ videoSpeedMenuAnchor: null })}
            >
              {this.speedList.map(speedElm => (
                <MenuItem
                  key={speedElm}
                  data-test-id="change-speed-btn-item" 
                  onClick={() => this.onHideSpeedMenu(speedElm)}
                  style={{
                    backgroundColor: speedElm === this.state.currentVideoSpeed ? "rgba(0,0,0,0.1)" : undefined
                  }}>{speedElm}X</MenuItem>
              ))}
            </Menu>
          </Button>
          <Button data-test-id="next-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next()}>
            <img src={next} className="video-controll-icon"/>
          </Button>
          <Button data-test-id="next5s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next5s()}>
            <img src={next5s} className="video-controll-icon"/>
          </Button>
          <Button data-test-id="next15s-btn" className="video-controll-btn" onClick={() => this.videoRef.current.next15s()}>
            <img src={next15s} className="video-controll-icon"/>
          </Button>
        </Box>
        {!this.state.isMarkFinished && (<Button className="add-event-btn"
          data-test-id="add-event-btn"
          onClick={() => {
            this.showAddEventModal();
          }}
        >
          <Typography>{"Add Event   +"}</Typography>
        </Button>)}
      </VideoControllerStyled>
    )
  }

  renderAddEVentModal() {
    const {
      showModalAddEvent,
    } = this.state;
    return (
      <DialogStyled 
        data-test-id="dialogAddEvent"
        open={showModalAddEvent}
        fullWidth={true}
        disableScrollLock
        maxWidth={false}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }
        }}
        PaperProps={{
          style: {
            boxShadow: this.getShadowColorDialog(),
            boxSizing: "border-box",
            maxHeight: "420px",
          }
        }}
      >
        <IconButton
          id="closeBtn"
          aria-label="close"
          onClick={() => this.closeModalAddEvent()}
        >
          <CloseOutlined />
        </IconButton>
        <Box className="add_event">
          <Events 
            data-test-id="list_event"
            listValueKeyboard={{listAction: this.state.listAction,  listSubAction: this.state.listSubAction, listValue: this.state.listValue, listReason: this.state.listReason, listSubReason: this.state.listSubReason, commentInput: this.state.commentInput }} 
            handleSelectAction={this.handleSelectAction}
            handleSelectSubAction={this.handleSelectSubAction}
            handleSelectValue={this.handleSelectValue}
            handleSelectReason={this.handleSelectReason}
            handleSelectSubReason={this.handleSelectSubReason}
            handleChangeComment={this.onChangeCommentInput}
            isSubmit={this.state.isCanSubmitEvent}
          />
          <Button disabled={!this.state.isCanSubmitEvent} data-test-id="submit-event-modal-btn" className="confirm-btn" onClick={this.submitAddEvent.bind(this)}>Submit</Button>
        </Box>
      </DialogStyled>
    )
  }

  renderCommentModal() {
    const {
      showComemntModal
    } = this.state;
    return (
      <Modal
        open={showComemntModal}
      >
        <CommentModal>
          <Box className="container">
            <Button data-test-id="hide-comment-btn" className="close-btn" onClick={this.hideCommentModal.bind(this)}>
              <Close style={{ color: 'white' }}/>
            </Button>
            <Typography className="modal-label">COMEMNTS</Typography>
            {(this.state.comments || []).map(item => (
              <Box data-test-id="comemnt-container">
                <Box className="name-row">
                  <img className="avatar" />
                  <Typography className="name">Jack</Typography>
                  <Typography className="time">1 minute ago</Typography>
                </Box>
                <Typography className="content">This is a comment</Typography>
              </Box>
            ))}
            {!this.state.comments && <Box className="no-comment-text">No comment</Box>}
            <Box className="input-row">
              <Input data-test-id="comemnt-input" className="input" placeholder="Enter your comment" value={this.state.commentInput} onChange={this.onChangeCommentInput.bind(this)}/>
              <Button data-test-id="submit-comment-btn" className="send-btn" onClick={this.onClickSendComment.bind(this)}>Send</Button>
            </Box>
          </Box>
        </CommentModal>
      </Modal>
    )
  }

  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <VideoEventStyled>
        <NavigationMenu navigation={this.props.navigation} id="NavigationMenu" currentTabProp="video" />
        <Box data-test-id="container" style={{ marginLeft: 40, marginRight: 40, marginBottom: 100 }}>
          {this.renderAddEVentModal()}
          {this.renderCommentModal()}
          {this.renderTopMenu()}
          {this.renderVideoArea()}
          <Box className="bottomContent">
            <Box className="bottomContent-action">
              {this.renderVideoController()}
              {this.state.isGridView && this.renderBottomPOV()}
              {!this.state.isGridView && this.renderBottomEventTable()}
            </Box>
            <Box className="recentGame">
              <Typography style={{fontFamily: "Poppins", fontSize: "24px", color: "#CFD1D4"}}>
                Browse Recent Games
              </Typography>
              <BrowseRecentGame data={this.state.gameFolders}/>
            </Box>
          </Box>
        </Box>
        <Loader loading={this.state.isLoading} />
        <ModalStatusMarking statusMarking={this.state.statusMarking} isOpen={this.state.isOpenModal} handleCloseModal={this.handleCloseModal}/>
      </VideoEventStyled>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogStyled = styled(Dialog)(({ theme }) => ({
  position: "relative",
  "& #closeBtn": {
    position: "absolute",
    right: "4px",
    color: "#474A4D"
  },
  "& .MuiDialog-scrollPaper": {
    alignItems: "flex-end"
  },
  "& .MuiDialog-paper": {
    margin: 0,
    width: '100%',
    maxWidth: 'none',
    borderRadius: "10px 10px 0 0 ",
    borderTop: "1px solid #0A0A0A",
  },
  "& .add_event": {
    paddingRight: 24,
    display: "flex",
    alignItems: "center",
    background: "#252527",
    "& #events_component": {
      border: 0,
      minHeight: "350px"
    },
    "& .confirm-btn": {
      width: "150px",
      height: "50px",
      background: "#FF881A",
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: 600,
      color: "#1D1D1F",
      borderRadius: "8px"
    },
    "& .confirm-btn.Mui-disabled": {
      opacity: 0.6
    }
  }
}));

const VideoEventStyled = styled(Box)(({ theme }) => ({
  "& .bottomContent": {
    display: "flex",
    marginTop: "20px",
    "& .bottomContent-action": {
      flex: "0 0 60%",
      maxWidth: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .recentGame": {
      flex: "0 0 40%",
      maxWidth: "40%",
      marginLeft: 10,
      "& .browseRecentGame_box":{
        background: "transparent",
        padding: 0,
        "& .listRecentGame": {
          padding: 0,
          "& .recentGameItem": {
            borderRadius: "10px",
            "& .backgroundBlurGameItem": {
              borderRadius: "10px"
            }
          }
        }
      }
    }
  },
}));

const EventTableStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  "& .header": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    "& .btnHeader": {
      background: "#00000099",
      border: "1px solid #FF881A",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: 120,
      height: 40,
      borderRadius: 8,
      padding: "4px 16px",
      textTransform: "none"
    }
  },
  "& .tableContent": {
    overflowY: "auto",
    maxHeight: "calc(500px - 80px)",
    "& .tableRow": {
      height: "40px"
    },
    "& .tableRow:nth-child(even)":{
      background: "#252527",
      height: "30px"
    },
    "& .tableCell": {
      border: 0
    }
  }
}));

const CommentModal = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  color: "white",

  "& .container": {
    backgroundColor: "#252527",
    width: 500,
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 60,
    paddingBottom: 30,
    position: "relative"
  },

  "& .close-btn": {
    width: 50,
    height: 50,
    position: "absolute",
    right: 20,
    top: 20
  },

  "& .name-row": {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    marginTop: 15
  },

  "& .avatar": {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "gray"
  },

  "& .name": {
    marginLeft: 5,
    fontSize: 16,
  },

  "& .time": {
    marginLeft: 5,
    color: "gray",
    fontSize: 16,
  },

  "& .content": {
    marginLeft: 35,
    fontSize: 16,
  },

  "& .input-row": {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    height: 50,
    width: "100%",
    marginTop: 30,
    backgroundColor: "white",
    borderRadius: 5,
    overflow: "hidden",
  },

  "& .input": {
    height: "100%",
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },

  "& .send-btn": {
    height: "100%",
    width: 100,
    backgroundColor: "gray"
  },

  "& .no-comment-text": {
    marginTop: 50,
    marginBottom: 70,
  }
  
}))

const TopMenuStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: 60,
  marginTop: 15,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  "& *": {
    boxSizing: 'border-box'
  },

  "& .top-menu-back-btn": {
    height: '100%',
    width: '60px',
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#252527",
  },
  
  "& .top-menu-item": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#252527",
    height: "100%",
    borderRadius: 10,
    textTransform: 'none',

    "&.second-item": {
      marginLeft: '10px'
    }
  },

  "& .top-menu-label": {
    color: "#CFD1D4",
  },

  "& .menu-icon": {
    width: 30,
    height: 30,
    marginLeft: 10
  }
}))

const SidePOVStyled = styled(Box)(({ theme }) => ({
  width: 150,
  overflowY: "scroll",
  height: window.innerWidth * 0.49,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "#CFD1D4",
}))

const BottomPOVStyled = styled(Box)(({ theme }) => ({
  height: 150,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "#CFD1D4",
}))

const BottomEventTableStyled = styled(Box)(({ theme }) => ({
  width: "70%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "center",
}))

const RightEventTableStyled = styled(Box)(({ theme }) => ({
  flex: "0 0 40%",
  maxWidth: "40%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "scroll",
  marginLeft: 10,
}))

const VideoAreaStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: 30,
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "space-between",
  maxHeight: "500px",
}))

const VideoControllerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 50,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,

  "& .controller-wrapper": {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flex: 1,
    borderBottomWidth: 3,
    borderStyle: "solid",
    borderBottomColor: "#FF881A",
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    border: 10,
    marginRight: 25,
    borderRadius: 10,
    overflow: "hidden",
  },

  "& .add-event-btn": {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FF881A",
    overflow: "hidden",
    color: "#FF881A",
    borderRadius: 10,
    textTransform: "none",
    backgroundColor: "black"
  },

  "& .video-controll-btn": {
    color: "#FF881A",
  },

  "& .video-controll-icon": {
    width: 50,
    height: 40
  }
}))
// Customizable Area End
