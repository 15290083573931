import { Box, Modal, Typography, styled } from '@material-ui/core';
import { allStaffImg } from '../../../../packages/blocks/cfcustomkeyboards/src/assets';
import React, { useEffect, useState } from 'react';

export interface Staff {
    id: string;
    type: string;
    attributes: {
        fullname: string;
        role: string;
        photo: null | string;
    }
    selected: boolean;
}

export interface Player extends Staff { }

export interface Props {
    show: boolean;
    onClose: () => void;
    staffList: Array<Staff>;
    playerList: Array<Player>;
    onSave: (staffList: Array<Staff>, playerList: Array<Player>) => void;
}

const Wrapper = styled(Modal)(({ theme }) => ({
    textTransform: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& *": {
        boxSizing: "border-box"
    },

    "& .modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "937px",
        height: '793px',
        backgroundColor: "#252527",
        borderRadius: 10,
        overflow: "auto",
        padding: "40px",
        paddingRight: '32px',
        gap: '32px',
    },

    "& .title-text": {
        fontFamily: "Poppins",
        fontWeight: '700',
        fontSize: '20px',
        color: "#CFD1D4"
    },

    "& .staffs": {
        width: '100%',
        height: '550px',
        borderRadius: '10px',
        overflow: 'auto',
        backgroundColor: '#252527',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '8px',
    },

    "& .filter-title-text": {
        fontFamily: "Poppins",
        fontWeight: '400',
        fontSize: '16px',
        color: "#CFD1D4"
    },

    "& .item-all-options": {
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '16px',
        maxWidth: '100%',
        marginTop: '8px',
    },

    "& .item-option": {
        width: '265px',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',

        "&:hover .item-option-name": {
            border: '1px solid #FF881A'
        },

        "& img": {
            borderRadius: '50%',
            position: 'relative',
            zIndex: 1302
        },

        "&.mt--8": {
            marginTop: '-8px',
        },

        "&.mt-8": {
            marginTop: '8px'
        }
    },

    "& .item-option-name": {
        width: '250px',
        height: '40px',
        borderRadius: '8px',
        marginLeft: '-25px',
        paddingLeft: '33px',
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: '#1D1D1F',
        fontFamily: "Poppins",
        fontWeight: '400',
        fontSize: '16px',
        color: "#CFD1D4",
        position: 'relative',
        zIndex: 1301,
        border: '1px solid rgba(0,0,0,0)',

        "&.selected": {
            border: "1px solid #FF881A",
            color: '#FF881A'
        }
    },

    "& .avatar": {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        position: 'relative',
        zIndex: '1302',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#FF881A",
        fontFamily: "Poppins",
        fontWeight: '600',
        fontSize: "18px",
        color: '#1D1D1F'
    },

    "& .actions-row": {
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '24px',
        justifyContent: 'center',
        gap: '40px',
    },

    "& .save-btn": {
        padding: '10px 16px',
        height: '55px',
        borderRadius: '10px',
        justifyContent: 'center',
        fontSize: '18px',
        backgroundColor: "#FF881A",
        fontFamily: "Montserrat",
        display: 'inline-flex',
        fontWeight: '600',
        color: "#1D1D1F",
        width: '200px',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },

    "& .back-btn": {
        height: '55px',
        fontWeight: '600',
        borderRadius: '8px',
        fontFamily: "Montserrat",
        border: '1px solid #FF881A',
        padding: '10px 16px',
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: '18px',
        color: "#FF881A",
        cursor: 'pointer',
        width: '200px',
        flexDirection: 'row',
        alignItems: 'center'
    },
}))

const AssignPlayerModal = (props: Props) => {
    const [tempStaffList, setTempStaffList] = useState<Array<Staff>>([]);
    const [tempPlayerList, setTempPlayerList] = useState<Array<Player>>([]);

    const handleSelectAllPlayer = () => {
        setTempPlayerList((prev: Array<Staff>) => {
            if (prev.every(item => item.selected)) {
                return prev.map(item => ({ ...item, selected: false }))
            } else {
                return prev.map(item => ({ ...item, selected: true }))
            }
        })
    }

    const handleSelectPlayer = (id: string) => {
        setTempPlayerList(prev => prev.map(item => {
            if (item.id === id) {
                return { ...item, selected: !item.selected }
            }

            return item;
        }))
    }

    const handleSelectAllStaff = () => {
        setTempStaffList((prev: Array<Staff>) => {
            if (prev.every(item => item.selected)) {
                return prev.map(item => ({ ...item, selected: false }))
            } else {
                return prev.map(item => ({ ...item, selected: true }))
            }
        })
    }

    const handleSelectStaff = (id: string) => {
        setTempStaffList(prev => prev.map(item => {
            if (item.id === id) {
                return { ...item, selected: !item.selected }
            }

            return item;
        }))
    }

    useEffect(() => {
        if (props.show) {
            setTempStaffList(props.staffList.map(item => item));
            setTempPlayerList(props.playerList.map(item => item));
        }
    }, [props.show])

    const formatNameAsAvatar = (name: string) => {
        const char = name.split(" ");

        if (char[1]) {
            return char[0].split("")[0] + char[1].split("")[0];
        }

        return char[0].split("")[0];
    };

    return (
        <Wrapper open={props.show} onClose={props.onClose}>
            <Box className='modal-body'>
                <Typography component="span" className='title-text'>
                    Edit Individual Staffs / Players!
                </Typography>

                <Box className='staffs'>
                    <Typography component="span" className="filter-title-text">Staffs</Typography>

                    <Box className="item-all-options" data-test-id="staff-player-staff-container">
                        <Box data-test-id="staff-player-staff-item-all" className='item-option' onClick={handleSelectAllStaff}>
                            <Box className="avatar">
                                <img src={allStaffImg} width={50} />
                            </Box>

                            <Box className={`item-option-name ${tempStaffList.every(item => item.selected) ? "selected" : ""}`}>All Staffs</Box>
                        </Box>

                        {tempStaffList.map((item, index) => {
                            return (
                                <Box data-test-id="staff-player-staff-item" key={String(item.id)} className={`item-option${index > 1 ? " mt--8" : ""}`} onClick={() => handleSelectStaff(item.id)}>
                                    <Box className="avatar">
                                        {item.attributes.photo ? <img src={item.attributes.photo} width={50} /> : formatNameAsAvatar(item.attributes.fullname)}
                                    </Box>

                                    <Box className={`item-option-name ${item.selected ? "selected" : ""}`}>{item.attributes.fullname}</Box>
                                </Box>
                            )
                        })}
                    </Box>

                    <Typography component="span" className="filter-title-text">Players</Typography>

                    <Box className="item-all-options" data-test-id="staff-player-player-container">
                        <Box data-test-id="staff-player-player-item-all" className='item-option' onClick={handleSelectAllPlayer}>
                            <Box className="avatar">
                                <img src={allStaffImg} width={50} />
                            </Box>

                            <Box className={`item-option-name ${tempPlayerList.every(item => item.selected) ? "selected" : ""}`}>All players</Box>
                        </Box>

                        {tempPlayerList.map((item, index) => {
                            return (
                                <Box data-test-id="staff-player-player-item" key={String(item.id)} className={`item-option${index > 1 ? " mt--8" : ""}`} onClick={() => handleSelectPlayer(item.id)}>
                                    <Box className="avatar">
                                        {item.attributes.photo ? <img src={item.attributes.photo} width={50} /> : formatNameAsAvatar(item.attributes.fullname)}
                                    </Box>

                                    <Box className={`item-option-name ${item.selected ? "selected" : ""}`}>{item.attributes.fullname}</Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>

                <Box className="actions-row">
                    <Box data-test-id="staff-player-back-btn" className="back-btn" onClick={props.onClose}>Back</Box>

                    <Box className="save-btn" data-test-id="staff-player-save-btn" onClick={() => props.onSave(tempStaffList, tempPlayerList)}>Save</Box>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default AssignPlayerModal;