import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apex, ember, enterprise, nexus, spark } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  billType: string;
  selectedUsers:string;
  subscriptionsData:any;
  contactUsModal: boolean;
  dataLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getListCallId: any;
  getSubscriptionDataCallId: string = "";
  buyHereCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      billType:"month",
      selectedUsers:"1",
      dataLoading:false,
      subscriptionsData:{
        headers:[
          {id:1,"name":"Spark", "image":spark, "price":8.5, "total":"85"},
          {id:2,"name":"Ember", "image":ember, "price":12, "total":"100"},
          {id:3,"name":"Nexus", "image":nexus, "price":"15", "total":"200"},
          {id:4,"name":"Apex", "image":apex, "price":"19", "total":"400"},
          {id:5,"name":"Enterprise", "image":enterprise, "price":null, "total":null},
      ],
      rows:[
        { description: "Video Uploads (Per Month)", Spark: "40 Videos", Ember: "50 Videos", Nexus: "60 Videos", Apex: "70 Videos", Enterprise: "Custom" },
        { description: "Cloud Storage", Spark: "40 Gb", Ember: "50 Gb", Nexus: "60 Gb", Apex: "70 Gb", Enterprise: "Custom" },
        { description: "Storage Retention Period", Spark: "2 Weeks", Ember: "3 Weeks", Nexus: "4 Weeks", Apex: "8 Weeks", Enterprise: "Custom" },
        { description: "Number Of Custom Keyboards", Spark: "5", Ember: "10", Nexus: "15", Apex: "20", Enterprise: "Custom" },
        { description: "Onboarding & Training", Spark: "-", Ember: "-", Nexus: "-", Apex: false, Enterprise: true },
        { description: "Dedicated Success Manager", Spark: "-", Ember: "-", Nexus: "-", Apex: "-", Enterprise: true },
        { description: "FWE Leading Webinars", Spark: "-", Ember: "-", Nexus: "-", Apex: "-", Enterprise: true }
      ],
      stripe_plan_id:[],

      },
      contactUsModal: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const apiRequestCallIds = {
        [this.getSubscriptionDataCallId]: this.handleGetSubscriptionData,
        [this.buyHereCallId]: this.handleBuyHereApi,
      };

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getSubscriptionData()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SubscriptionDetails");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg)
    // Customizable Area End
  }

  // Customizable Area Start


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.billType!==this.state.billType||prevState.selectedUsers!==this.state.selectedUsers){
      this.getSubscriptionData();
    }
  }

  handleGetSubscriptionData = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      this.setState({dataLoading:false})
      if (!responseJson.error) {
        
        const headers=responseJson.headers.sort((a:any, b:any) => {
          if (a.name === "Enterprise") return 1;
          if (b.name === "Enterprise") return -1;
          return parseFloat(a.price) - parseFloat(b.price);
      });
        this.setState({subscriptionsData:{...responseJson,headers}})
      }
    }
  };
  handleBuyHereApi = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {  
        window.location.href=responseJson.session_id.url
      }if(responseJson.errors.message){
        toast.error("Something Went Wrong")

      }
    }
  };
  
  onBillingChange= (event: React.ChangeEvent<{}>, tab: "Mothly" | "Annually") =>{
    this.setState({
      billType: tab
    })
  }

  handleChangeUsers = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      selectedUsers:selectedOption.value
    }));
  };
  handleContactUs=()=>{
    this.setState({
      contactUsModal: true,
    })
  }

  closeContactUs=()=>{
    this.setState({
      contactUsModal:false,
    })
  }

  goToUserProfile=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  navigateAddContactUs() {
    const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
    navigateTo.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddContactus'
    );
    navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateTo);
  }
  

   getSubscriptionData= async ()=>{
    const token = await getStorageData("authToken");
    this.setState({dataLoading:true})
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getSubscriptionDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubscriptionDataEndPoint}?bill_type=${this.state.billType}&users=${Number(this.state.selectedUsers)}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    )

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }

  onBuyHere=async(planId:number)=>{
    const token = await getStorageData("authToken");
    const price = this.state.subscriptionsData.stripe_plan_ids.find((plan:any)=>plan.id===planId)
    
    const formData = new FormData();
    formData.append("quantity", this.state.selectedUsers)
    formData.append("price", price.stripe_plan_id)
    formData.append("subscription_id", price.id.toString())
    formData.append("interval", this.state.billType)
    

    const header = {
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.buyHereCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_stripe_integration/payment_methods"
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  // Customizable Area End
}
