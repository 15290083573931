import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker, {
  DocumentPickerResponse,
} from "react-native-document-picker";
import { Platform } from "react-native";
import React,{ ChangeEvent ,RefObject} from 'react';
export const baseURL = require("../../../framework/src/config.js").baseURL;
import { toast } from "react-toastify";
import { IFile } from "../../../components/src/CustomFeatureVideoProcess/OptimizationStatus.web";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ProductItem } from "../../../../packages/blocks/share/src/ShareController.web";

export interface Keyboard {
  id: number;
  name: string | null;
  type?: string;
}

interface IUploadedFile {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      account_id: number;
      files:
      | {
        id: number;
        file_name: string;
        file_url: string;
      }[]
      | null;
      status: string;
    };
  };
  meta: {
    message: string;
  };
}


interface IDownloadResponse {
  jobId: number;
  statusCode: number;
  bytesWritten: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  onCloseDialog: () => void;
  onSelectClick: () => void;
  pathObj?:{selectLevels:any;levelBreads:any};
  selectedPath: string;
  firstLevelPathId: number;
  secondLevelPathId: number;
  thirdLevelPathId: number;
  uploadType?: "gameFolder" | "video";
  callBackCreate?:any;
  handleBackBtn?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  isMultiple: boolean;
  gameScore1: string;
  gameScore2: string;
  teamName: string;
  opponentTeamName: string;
  uploadDate: string;
  gameType: string,
  gamePlace: string,
  gameStatus: string,
  token: string;
  filesWeb: File[];
  files: any[];
  uploadedFiles: IUploadedFile[];
  filesStatus: (undefined | "uploading" | "success" | "failed")[];
  importedFile: any[];
  importedData: Array<Record<string, string>>;
  editingCell: any;
  filesSelect: IFile[]
  thumbnailImg:any;
  showAssignModal:boolean;
  createGameFolderForm: {
    customName: string;
    myTeamName: string;
    opponentsTeamName: string;
    gameDueDate: string;
    gameType: string;
  }
  formValue: {
    customName: string;
    gameScore1: string;
    gameScore2: string;
    myTeamName: string;
    opponentsTeamName: string;
    uploadDate: string;
    gameType: string;
  }
  errorMessageOn: {
    gameScore:string;
    myTeamName:string;
    opponentsTeamName:string;
    dateOfBirth?: string; // optional
    gameType: string;
    pathMsg:string;
    hasFileMsg?:string;
    
  }
  fieldChanging: string;
  gameTypeOptions:any;
  userType: string;
  groups: Array<ProductItem & {expanded: boolean}>;
  allKeyboard: Array<Keyboard>
  defaultTeamName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  importDataCallId: string = "";
  assignKeyboardGroup:string="";
  getGameTypeId: string = ""
  getAllKeyboardCallId: string = '';
  createGameFolderCallId: string = '';
  maxFileSize =  5 * 1024 * 1024 * 1024;;
  fileInputRef: RefObject<HTMLInputElement> = React.createRef();

  steps =
    localStorage.getItem("typeSport") === "eSports" ? [
      'General Info',
      'Share Video',
      'Assign Keyboards',
    ] : ['General Info',
      'Share Video',
      'Assign Keyboards', "In & Out"]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      isMultiple: false,
      gameScore1: "",
      gameScore2: "",
      teamName: "",
      opponentTeamName: "",
      uploadDate: "",
      gameType: "",
      gamePlace: "Home",
      gameStatus: "Win",
      token: "",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      importedFile: [],
      importedData: [],
      editingCell: "",
      filesSelect: [],
      thumbnailImg:"",
      showAssignModal:false,
      createGameFolderForm: {
        customName: '',
        myTeamName: '',
        opponentsTeamName: '',
        gameDueDate: '',
        gameType: '',
      },
      formValue: {
        customName: "",
        gameScore1: "",
        gameScore2: "",
        myTeamName: "",
        opponentsTeamName: "",
        uploadDate: "",
        gameType: '',
      },
      errorMessageOn: {
        gameScore:'',
        myTeamName:'',
        opponentsTeamName:'',
        dateOfBirth: '',
        gameType:'',
        pathMsg:'',
        hasFileMsg:''
      },
      fieldChanging: '',
      groups: [],
      allKeyboard: [],
      gameTypeOptions:[],
      userType: '',
      defaultTeamName: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    this.handleGetBulkUploadCall(message);
    this.handleGetGameType(message)
    this.handleCreateBulkUploadingCall(message);
    this.handleCreateGameFolderResponse(message);
    this.handleImportDataCall(message);
    this.handleGetAllKeyboardResponse(message);
    this.handleUpdateKeyboard(message)
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    const userType = await getStorageData('typeSport') || ""
    const token = window.localStorage.getItem("authToken") || "";
    const profile = sessionStorage.getItem('profile')
    let defaultTeamName = '';
    if (profile) {
      const parsedProfile = JSON.parse(profile)

      defaultTeamName = parsedProfile.team_name || ""
    }
    this.setState(prev => 
      ({ ...prev, token, userType, defaultTeamName, formValue: { ...prev.formValue, myTeamName: defaultTeamName }, createGameFolderForm: { ...prev.createGameFolderForm, myTeamName: defaultTeamName }}),
      () => {
        this.getAllKeyboard(token)
        this.getGameType()
      }
    );
  };

  componentDidUpdate(prevProps: Props, prevState: S) {
    const { formValue } = this.state;
    if (prevState.formValue !== formValue) {
      this.setState((prevState: S) => ({
        errorMessageOn: {
          ...prevState.errorMessageOn,
          [this.state.fieldChanging]: "",
          gameScore: this.validateGameScore(formValue.gameScore1, formValue.gameScore2)
        },
      }));
    }
  }

  handleResponse = (responseJson:any, errorResponse:any, successCallback:any) => {
    if (responseJson) {
      successCallback(responseJson);
    } else {
      this.parseApiErrorResponse(errorResponse);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleCreateBulkUploadingCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.createBulkUploadCallId !== null && this.createBulkUploadCallId === dataMessage) {
      let filesStatus = this.state.filesStatus;
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:any) => {
          if ('errors' in responseJson) {
            responseJson?.errors?.map((error: any)=>toast.error(error))
          } else {
            if(responseJson.data){
              this.handleResponseBulkUploading(responseJson)
              filesStatus[filesStatus.length - 1] = "success";
            }
            else
              toast.error("Upload fail")
            this.onCancelBulkUpload();
          }
          this.setState({ filesStatus });
        }
      );
    }
  };
  async handleResponseBulkUploading(responseJson: { data?: { id: number } }) {
    // Check is desktop application or not

    if (window.api) {
      // Optimizied files and get attachmentId
      for (const file of this.state.filesSelect) {
        await window.api.convertFile(file) // Request file conversion
      }
      setStorageData("attachmentId", responseJson?.data?.id)
      if(!this.state.filesSelect.length)
        toast.success("Upload Successful")
    }
    else {
      toast.success("Upload Successful")
    }
  }
  transformGameType(array:any) {
    const grouped = array.reduce((result:any, item:any) => {
        const category = item?.attributes?.category;
        const name = item?.attributes?.name;
        if (!result[category]) {
            result[category] = [];
        }
        result[category].push({ value: name, label: name });
        return result;
    }, {});

    return Object.keys(grouped).map((category) => ({
        label: category,
        options: grouped[category],
    }));
}
  getGameType = () => {
    const header = {
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };    
    const getGameTypeMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGameTypeId = getGameTypeMsg.messageId;
    getGameTypeMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
      );
    getGameTypeMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGameTypeMethod
    );
    getGameTypeMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGameTypeEndPoint
    );

    runEngine.sendMessage(getGameTypeMsg.id, getGameTypeMsg);
  };
  handleGetGameType = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.getGameTypeId !== null && this.getGameTypeId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:any) => {
          if (!responseJson.errors && responseJson.data) {
            this.setState({ gameTypeOptions: this.transformGameType(responseJson.data) });
          } 
        }
      );
    }
  };
  handleImportDataCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.importDataCallId !== null && this.importDataCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:any) => {
          if ('errors' in responseJson) {
            toast.error("Errors while Importing File.Please Try Again")
          } else {
            toast.success("Imported Successfully")
            this.setState({ importedData: responseJson.data });
          }
        }
      );
    }
  };
  handleGetBulkUploadCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.getBulkUploadCallId !== null && this.getBulkUploadCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:any) => {
          if (!responseJson.errors) {
            this.setState({ uploadedFiles: responseJson });
          } 
        }
      );
    }
  };
  handleAssignModal = (value: boolean) => {
    this.setState(() => ({ showAssignModal:value }));
  };
  onCancelBulkUpload = () => {
   
    this.setState(prev => ({
      activeStep: 0,
      isMultiple: false,
      createGameFolderForm: {
        customName: '',
        myTeamName: prev.defaultTeamName,
        opponentsTeamName: '',
        gameDueDate: '',
        gameType: '',
      },
      formValue: {
        customName: "",
        gameScore1: "",
        gameScore2: "",
        myTeamName: prev.defaultTeamName,
        opponentsTeamName: "",
        uploadDate: "",
        gameType: '',
      },
      errorMessageOn: {
        gameScore:'',
        myTeamName:'',
        opponentsTeamName:'',
        dateOfBirth: '',
        gameType:'',
        pathMsg:'',
        hasFileMsg:'',
      },
      uploadDate:"",
      gamePlace: "Home",
      gameStatus: "Win",
      filesWeb: [],
      files: [],
      uploadedFiles: [],
      filesStatus: [],
      importedData: [],
      importedFile: [],
      editingCell: "",
      showAssignModal:false,
      thumbnailImg: "",
      groups: []
    }))
    
    this.props.onCloseDialog();
  }

  validateField(value: string, errorMessage: string) {
    return value.trim() === "" ? errorMessage : "";
  }
  checkIsInputValueNumber(value: string){
    if (/^\d*$/.test(value)) { 
      return true
    } else {
      return false
    }
  }
  validateGameScore(gameScore1: string, gameScore2: string){
    const isBothANumber = this.checkIsInputValueNumber(gameScore1.trim()) && this.checkIsInputValueNumber(gameScore2.trim())
    const isNotEmpty = gameScore1.trim() !== "" && gameScore2.trim() !== ""
    if(isNotEmpty && isBothANumber){
      return ""
    }
    return "Game Score must be filled and be a number"
  }
  permissionToEdit = ()=>{
    const selectLevels = this.props.pathObj?.selectLevels || [];
    return  selectLevels[selectLevels.length-1].permission || false;
  }
  formValidation() {
    const validPath = this.props.thirdLevelPathId && this.props.secondLevelPathId && this.props.firstLevelPathId;
    const hasFile = this.state.filesWeb.length > 0;
    if (this.state.userType === "eSports" && this.props.uploadType === 'gameFolder') {
        const {createGameFolderForm} = this.state;
      const errorMessageOn = {
        gameScore: '',
        myTeamName: this.validateField(createGameFolderForm.myTeamName, "My Team Name must be filled"),
        opponentsTeamName: this.validateField(createGameFolderForm.opponentsTeamName, "Opponent's Team Name must be filled"),
        gameType: this.validateField(createGameFolderForm.gameType, "Game Type must be filled"),
        pathMsg: validPath ? "" : `A Game Folder cannot be uploaded at this level, please create a folder first.`
      }
      
      this.setState({ errorMessageOn })
      const array = [
      createGameFolderForm.gameType,
      createGameFolderForm.myTeamName,
      createGameFolderForm.opponentsTeamName]
        return (array.every(value => value.trim() !== '') && validPath) 
    }

    const { formValue } = this.state;
    this.setState({
      errorMessageOn: {
        gameScore: this.validateGameScore(formValue.gameScore1, formValue.gameScore2),
        myTeamName: this.validateField(formValue.myTeamName, "My Team Name must be filled"),
        opponentsTeamName:this.validateField(formValue.opponentsTeamName, "Opponent's Team Name must be filled"),
        dateOfBirth: this.validateField(formValue.uploadDate, "Game Date Must be filled"),
        gameType:this.validateField(formValue.gameType, "Game Type must be filled"),
        pathMsg: validPath && this.permissionToEdit() ? "" : "A Video cannot be uploaded at this level, please create a folder first.",
        hasFileMsg: hasFile ? "" : "File must be filled"
      }
    })
    if(this.state.userType === "eSports" && this.props.uploadType === "video"){
      const arrayEsports = [formValue.uploadDate, formValue.gameType,
        formValue.myTeamName, formValue.opponentsTeamName]
        return (arrayEsports.every(value => value.trim() !== '') && validPath && hasFile) 
    }
    const array = [formValue.uploadDate, formValue.gameType,
    formValue.gameScore1, formValue.gameScore2,
    formValue.myTeamName, formValue.opponentsTeamName]
    return (array.every(value => value.trim() !== '') && validPath && hasFile && !this.validateGameScore(formValue.gameScore1, formValue.gameScore2)) 
  }
  handleActiveStepNext = () =>{
    switch (this.steps[this.state.activeStep]) {
      case "General Info":
        if (this.formValidation()) {
          return this.setState(prev => ({activeStep: prev.activeStep + 1,showAssignModal:false}))
        }
        break;
   
      case "Share Video":
        if (this.state.groups.length && this.state.groups.every(item => item.attributes.assigned && item.attributes.competition)) {
          return this.setState(prev => ({activeStep: prev.activeStep + 1,showAssignModal:false}))
        }

        break;
      default:
        return this.setState(prev => ({activeStep: prev.activeStep + 1,showAssignModal:false}))
    }
  }
  keyboardValidate(groups: any) {
    for (const group of groups) {
      if (!group.attributes?.accounts) continue;
      const accounts = group.attributes?.accounts;
      for (const account of accounts) {
        if (!account?.keyboards || account.keyboards?.length === 0) {
          return false;
        }
      }
    }
    return true;
  }

  handleActiveStepBack = () =>
    this.setState(prev => ({ activeStep: prev.activeStep - 1 ,showAssignModal:false,}))
   
  handleOnChangeFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file =event.target.files[0];
      this.setState({
        thumbnailImg:file
      })
    }
  };

  onChooseFile = () => {
    this.fileInputRef.current?.click();
  };
  
  
  changeHandler=(event: ChangeEvent<HTMLInputElement>) =>{
    const { name, value } = event.target;
    const { formValue } = this.state;
    this.setState({
      formValue: {
        ...formValue,
        [name]: value,
      },
      fieldChanging: name
    });
   
  };

  onRandomsClick = () => {
    this.setState(prev => ({ ...prev,errorMessageOn:{...prev.errorMessageOn,opponentsTeamName:""},
    formValue: { ...prev.formValue, opponentsTeamName: "Randoms" },
    createGameFolderForm: { ...prev.createGameFolderForm, opponentsTeamName: "Randoms" }}))
  }

  handleDateInputField = (value: any) => {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    this.setState(prev => ({ ...prev, uploadDate: formattedDate,
      errorMessageOn: {
        ...prev.errorMessageOn,
      dateOfBirth:"",
    },formValue:{...prev.formValue,uploadDate:formattedDate}, createGameFolderForm: { ...prev.createGameFolderForm, gameDueDate: formattedDate } }))
  }

  handleChangeGameType = (selectedOption: { value: string; label: string }) => {
    if(selectedOption){
      this.setState((prev)=>({...prev,errorMessageOn:{
        ...prev.errorMessageOn,gameType:""
      }}))
    }
    this.setState(prev => ({ ...prev, formValue: { ...prev.formValue, gameType:selectedOption.value }, createGameFolderForm: { ...prev.createGameFolderForm, gameType: selectedOption.value }}))
  }
  onSelectFolder =()=>{
    this.props.onSelectClick();
    this.setState(prev=>({...prev,errorMessageOn:{...prev.errorMessageOn,pathMsg:""}}))
  }

  handleMultipleFiles = () => {
    this.setState(prev => ({ isMultiple: !prev.isMultiple }))
  }
  onGamePlaceHome = () => {
    this.setState({ gamePlace: "Home" })
  }
  onGamePlaceAway = () => {
    this.setState({ gamePlace: "Away" })
  }
  onGameStatusWin = () => {
    this.setState({ gameStatus: "Win" })
  }
  onGameStatusLose = () => {
    this.setState({ gameStatus: "Lose" })
  }

  uploadFile = () => {
    const length = this.isPlatformWeb()
      ? this.state.filesWeb.length
      : this.state.files.length;

    if (length > this.state.filesStatus.length) {
      this.uploadFileSingle(this.state.filesStatus.length);
      this.setState({ filesStatus: [...this.state.filesStatus, "uploading"] });
    }

  };

  onBulkUpload = () => {
    const header = {
      token: this.state.token,
    };
    const formData = new FormData();
    if (window.localStorage.getItem("typeSport") === "eSports") {
      if (this.props.uploadType === "gameFolder") {
        formData.append("folder_name", this.state.createGameFolderForm.customName);
        formData.append("my_team_name", this.state.createGameFolderForm.myTeamName);
        formData.append("opponent_team_name", this.state.createGameFolderForm.opponentsTeamName);
        formData.append("game_due_date", this.state.createGameFolderForm.gameDueDate);
        formData.append("game_type", this.state.createGameFolderForm.gameType);
        formData.append("group_ids", JSON.stringify(this.state.groups.map(item => String(item.id))))
        formData.append("auto_inner_generate_folder_id", String(this.props.secondLevelPathId));
        formData.append("parent_folder_id", String(this.props.thirdLevelPathId));
        if (this.state.thumbnailImg !== "") {
          formData.append("thumbnail", this.state.thumbnailImg);
        }
      }
      else {
        this.handleUploadVideoGame(formData)
      }
      
      const createGameFolderApi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.createGameFolderCallId = createGameFolderApi.messageId
      createGameFolderApi.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createGameFolderURL
      )
      createGameFolderApi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      )
      createGameFolderApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )
      createGameFolderApi.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createBulkUploadMethod
      );
      this.props.uploadType === 'gameFolder' && runEngine.sendMessage(createGameFolderApi.messageId,createGameFolderApi)
    }
    else {
      this.handleUploadVideoGame(formData)

      const createBulkUploadMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createBulkUploadCallId = createBulkUploadMsg.messageId;

      createBulkUploadMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.bulkUploadingEndPoint
      );

      createBulkUploadMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      createBulkUploadMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );

      createBulkUploadMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createBulkUploadMethod
      );

      // Comment not using this at this moment
      // runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
    }
  }

  handleCreateGameFolderResponse = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.createGameFolderCallId !== null && this.createGameFolderCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: {data:{}}) => {
          if (responseJson.data) {
            this.props.callBackCreate();
            this.props.onCloseDialog()
            this.onCancelBulkUpload()
          }
        }
      );
    }
  }

  uploadFileSingle = (index: number) => {
    const header = {
      token: this.state.token,
    };

    const formData = new FormData();
    formData.append(
      "files[]",
      this.isPlatformWeb()
        ? this.state.filesWeb[index]
        : this.state.files[index]
    );

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createBulkUploadCallId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBulkUploadEndpoint
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };


  removeFileWeb = async (index: number) => {

    const updatedFilesWeb = Array.from(this.state.filesWeb).filter(
      (file, fileIndex) => fileIndex !== index
    );
    
    this.setState({ filesWeb: updatedFilesWeb });
    
    const mappingTempFile = updatedFilesWeb.map((file) => {
      const { path } = file as { path?: string };
      return path;
    });
    
    if(window.api){
      const listFile = await window.api?.selectFile(mappingTempFile);
      this.setState({filesSelect: listFile})
    }
  };

  removeFile = (index: number) => {
    this.setState({
      files: Array.from(this.state.files).filter(
        (file, fileIndex) => fileIndex !== index
      ),
    });
  };
  clearAllFile = () => {
    // this.setState({ filesWeb: [], files: [], filesStatus: [] });
  };

  onChangeFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const dataStore = await window?.api.getListFileProcess();
    const dataUploading = await window?.api.getListOptimizationFile();
    
    if(dataStore.length || dataUploading.length) {
      return toast.warning("Please wait, last processing still be running")
    }

    removeStorageData('formData');
    
    let tempFile: File[] = [];
    if (event.target.files) {
      for (let iterator = 0; iterator < event.target.files.length; iterator++) {        
        if (event.target.files[iterator].size > this.maxFileSize) {
          this.showAlert(
            "Alert",
            event.target.files[iterator].name + configJSON.fileSizeErrorMsg
          );
        } else {
          tempFile.push(event.target.files[iterator]);
        }
      }
    }
    if(tempFile.length > 0){
      this.setState({
        filesWeb: [...this.state.filesWeb, ...tempFile],
      });
      // Add select file function in here to pass electron
      const mappingTempFile = tempFile.map((file) => {
        const {path} = file as {path?: string}
        return path
      })
      if(window.api){
        const listFile = await window.api?.selectFile(mappingTempFile);
        this.setState({filesSelect: listFile})
      }
    }
  };

  onChangeImportedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempFile: File[] = [];
    if (event.target.files) {
      for (const file of event.target.files) {
        tempFile.push(file);
      }
    }
    tempFile.length > 0 &&
      this.setState({
        importedFile: [...this.state.importedFile, ...tempFile],
      });

    const header = {
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };
    const formData = new FormData();
    tempFile.forEach(file => formData.append("file", file))
    console.log(formData);

    const importDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importDataCallId = importDataMsg.messageId;

    importDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulkDataImportEndPoint
    );

    importDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    importDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    importDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createImportDataMethod
    );

    runEngine.sendMessage(importDataMsg.id, importDataMsg);

  };
  removeImportedFile = (index: number) => {
    this.setState({
      importedFile: Array.from(this.state.importedFile).filter(
        (file, fileIndex) => fileIndex !== index
      ),
      importedData: []
    });
  };
  findGroupIdByAccountId(data: any, accountId: any) {
    for (const group of data) {
      const accounts = group.attributes?.accounts;
      if (accounts) {
        for (const account of accounts) {
          if (account?.id === accountId) {
            return group.id;
          }
        }
      }
    }
    return null;
  }

  updateGroupKeyboard =(groupId:any,keyboardId:any)=>{
    const keyboard = this.state.allKeyboard.find((item)=>item.id === keyboardId)

    const result = {
      account_id: [],
      keyboard_id: keyboard?.id,
      type: keyboard?.type || "",
      assign: "group" 
    }
    this.onChangeKeyboard(result,groupId)
  }

  filterDataKeyboard =(userId:any,keyboardId:any)=>{
    const keyboard =this.state.allKeyboard.find((item)=> item.id === keyboardId);
    const {groups} = this.state
    const groupId = this.findGroupIdByAccountId(groups,userId)
    const result = {
      account_id: [userId],
      keyboard_id: keyboard?.id,
      type: keyboard?.type || "",
      assign: "account" 
    }
    this.onChangeKeyboard(result,groupId)
  }

  onChangeKeyboard = (body:any,groupId:any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };
    const assignKeyboardMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.assignKeyboardGroup = assignKeyboardMsg.messageId;

    assignKeyboardMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_account_groups/groups/${groupId}/assign_keyboard`
    );

    assignKeyboardMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    assignKeyboardMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    assignKeyboardMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createImportDataMethod
    );

    runEngine.sendMessage(assignKeyboardMsg.id, assignKeyboardMsg);

  };

  addKeyboardGroupField(groups: any) {
    return groups.map((group: any) => {
      const keyboards = group.attributes?.accounts?.[0]?.keyboards || [];
      const keyboardType = keyboards?.[0]?.assignable_id ?? "";
      const hasSameKeyboard = group.attributes?.accounts?.every((account: any) => {
        return account.keyboards?.[0]?.assignable_id === keyboardType;
      });
  
      return {
        ...group,
        attributes: {
          ...group.attributes,
          keyboardGroup: hasSameKeyboard ? keyboardType : null,
        },
      };
    });
  }

  updateGroupById(updatedGroupData:any) {
    const groupId = updatedGroupData.id;
    const updatedData = this.state.groups.map((group) => {
      if (group.id === groupId) {
        return updatedGroupData;
      }
      return group;
    });
    return this.addKeyboardGroupField(updatedData) ||[];
  }

  handleUpdateKeyboard = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
   
    if (this.assignKeyboardGroup !== null && this.assignKeyboardGroup === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:any) => {
          if (responseJson) {
            const newGroups = this.updateGroupById(responseJson.assigned_keyboards.data)
            this.setState({groups:newGroups})
          }
        }
      );
    }
  };
 
  selectFiles = async () => {
    try {
      const pickerResult = await DocumentPicker.pickMultiple({
        presentationStyle: "fullScreen",
        copyTo: "cachesDirectory",
      });
      const tempFile: DocumentPickerResponse[] = pickerResult.filter((result) => {
        if (result.size && result.size > this.maxFileSize) {
          this.showAlert("Alert", result.name + configJSON.fileSizeErrorMsg);
          return false;
        } else {
          return true;
        }
      });

      this.setState({
        files: [...this.state.files, ...tempFile],
      });
    } catch (error) {
    }
  };

  getUploadedFiles = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };

    const getBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBulkUploadCallId = getBulkUploadMsg.messageId;

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBulkUploadEndpoint
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(getBulkUploadMsg.id, getBulkUploadMsg);
  };

  deleteFile = (fileGroupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };

    const deleteBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteBulkUploadCallId = deleteBulkUploadMsg.messageId;

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteBulkUploadEndpoint}/${fileGroupId}`
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    deleteBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(deleteBulkUploadMsg.id, deleteBulkUploadMsg);
  };

  fileDownloadHandler = (filePath: string, fileName: string) => {
    if (Platform.OS !== "web") {
      try {
        const RNFS = require("react-native-fs");
        const dirPath = this.isPlatformiOS()
          ? RNFS.DocumentDirectoryPath
          : RNFS.DownloadDirectoryPath;
        const toFile = `${dirPath}/${Date.now()}${fileName}`;
        RNFS.downloadFile({
          fromUrl: baseURL + filePath,
          toFile,
        }).promise.then((response: IDownloadResponse) => {
          if (response.statusCode === 200) {
            this.showAlert("Alert", configJSON.downloadedSuccessMsg + toFile);
          } else {

          }
        });
      } catch (error) {
      }
    }
  };

  onAddNewColumn = () => {
    const newImportedData = this.state.importedData.map(obj => {
      const length = (Object.keys(this.state.importedData[0]).length - 1) / 2
      return {
        ...obj,
        [`In ${length + 1}`]: "00:00:00",
        [`Out ${length + 1}`]: "00:00:00",
      }
    })
    this.setState({ importedData: newImportedData })
  }
  handleEditCell = (key: any) => {
    this.setState({ editingCell: key });
  };

  handleCellChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string, rowIndex: number) => {
    
    const { value } = e.target;
    if(key!=="Player Name"){
      if ((/^\d{0,2}:\d{0,2}:\d{0,2}$/.test(value))) {
        this.setState((prevState) => {
          const updatedData = [...prevState.importedData];
          updatedData[rowIndex][key] = value;
          return { importedData: updatedData };
        })
      }
    }else{
      this.setState((prevState) => {
        const updatedData = [...prevState.importedData];
        updatedData[rowIndex][key] = value;
        return { importedData: updatedData };
    })
    }
  };

  handleCreateGameFolderFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { createGameFolderForm } = this.state;
    this.setState({
      createGameFolderForm: {
        ...createGameFolderForm,
        [name]: value,
      },
    });
    this.setState((prevState: S) => ({
      errorMessageOn: {
        ...prevState.errorMessageOn,
        [name]: "",
      },
    }));
  }
  
  

  handleExpandStep3Group = (id: number) => {
    this.setState(prev => ({
      ...prev,
      groups: prev.groups.map(item => {
        if (Number(item.id) === id) {
          return {
            ...item,
            expanded: !item.expanded
          }
        }

        return item;
      })
    }))
  }

  handleUploadFileApplication = async (formData: FormData) => {
    // Add thumbnail in formData
    if (this.state.thumbnailImg !== "") {
      formData.append("thumbnail", this.state.thumbnailImg);
    }

    // Convert base64
    const fileToBase64 = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    };

    // Start: Handle video file processing
    // Save formdata in local storage
    interface IFileSelected {
      fileName: string;
      fileContent: string;
    }
    interface IFormObject {
      [key: string]: string | IFileSelected 
    }
    const formObject: IFormObject = {};
    for (const [key, value] of formData.entries()) {
      if (typeof value === 'string') {
        formObject[key] = value;
      } else if (value instanceof File) {
        formObject[key] = {
          fileName: value.name,
          fileContent: await fileToBase64(value),
        };
      }
    }

    setStorageData('formData', JSON.stringify(formObject))

    if (window.api) {
      // Optimizied files and get attachmentId
      this.onCancelBulkUpload();

      for (const file of this.state.filesSelect) {
        await window.api.convertFile(file) // Request file conversion
      }
      if (!this.state.filesSelect.length)
        toast.success("Upload Successful")
    }

    // End: Handle video file processing
  }

  onGroupUpdate = (groups: Array<ProductItem & {expanded: boolean}>) => {
    this.setState({groups})
  }

  getAllKeyboard = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
      'ngrok-skip-browser-warning': 'true' 
    };

    const getAllKeyboard = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllKeyboardCallId = getAllKeyboard.messageId;

    getAllKeyboard.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultKeyboardList
    );

    getAllKeyboard.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getAllKeyboard.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGameTypeMethod
    );

    runEngine.sendMessage(getAllKeyboard.id, getAllKeyboard);
  }

  handleGetAllKeyboardResponse = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
    if (this.getAllKeyboardCallId !== null && this.getAllKeyboardCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson:{keyboards:{data:Array<Keyboard>}}) => {
          if (responseJson && responseJson.keyboards.data) {
            this.setState({allKeyboard:responseJson.keyboards.data})
          }
        }
      );
    }
  };

  async handleUploadVideoGame(formData: FormData) {
    const typeSport = window.localStorage.getItem("typeSport")
    const { customName, myTeamName, gameScore1, gameScore2, opponentsTeamName, uploadDate, gameType } = this.state.formValue;
    formData.append("group_ids", JSON.stringify(this.state.groups.map(item => String(item.id))))
    if (typeSport === "eSports") {
      formData.append("custom_name", customName);
      formData.append("game_date", uploadDate);
      formData.append("game_type", gameType);
      formData.append("team_name", myTeamName);
      formData.append("oppo_team_name", opponentsTeamName);
      formData.append("auto_generate_folder_id", String(this.props.firstLevelPathId));
      if (String(this.props.thirdLevelPathId)) {
        formData.append("parent_folder_id", String(this.props.thirdLevelPathId));
      } else {
        formData.append("auto_inner_generate_folder_id", String(this.props.secondLevelPathId));
      }
      !window.api && this.state.filesWeb.forEach(file => formData.append("files[]", file))
      this.handleUploadFileApplication(formData)
    } else {

      formData.append("custom_name", customName);
      formData.append("game_score1", gameScore1);
      formData.append("game_score2", gameScore2);
      formData.append("game_type", gameType);
      if (this.state.thumbnailImg !== "") {
        formData.append("thumbnail", this.state.thumbnailImg);
      }
      formData.append("team_name", myTeamName);
      formData.append("oppo_team_name", opponentsTeamName);
      formData.append("game_date", uploadDate);
      formData.append("game_status", this.state.gameStatus);
      formData.append("game_place", this.state.gamePlace);
      formData.append("auto_generate_folder_id", String(this.props.firstLevelPathId));
      if (String(this.props.thirdLevelPathId)) {
        formData.append("parent_folder_id", String(this.props.thirdLevelPathId));
      } else {
        formData.append("auto_inner_generate_folder_id", String(this.props.secondLevelPathId));
      }
      !window.api && this.state.filesWeb.forEach(file => formData.append("files[]", file))

      this.handleUploadFileApplication(formData)
    }
  }

  isNextBtnDisabled = (): boolean => {
    let disabled = false;
    const {groups} = this.state;
    if (this.steps[this.state.activeStep] === "Share Video") {
      disabled = this.state.groups.length < 1 || !this.state.groups.every(item => item.attributes.assigned && item.attributes.competition)
    }
    if (this.steps[this.state.activeStep] === "Assign Keyboards") {
      disabled = !this.keyboardValidate(groups)
    }
    return disabled
  }

  getStyleStep = (showModal: boolean, step: number) => {
    let result: { right?: string, width?: string } = { right: showModal&& step === 1 ? "730px" : "" };
  
    switch (step) {
      case 0:
        result.width = "800px";
        break;
  
      case 1:
        result.width = "932px";
        break;
  
      default:
        
    }
  
    return result;
  };
  
  // Customizable Area End
}