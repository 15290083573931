import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    password: string;
    confirmPassword: string;
    checkedRememberMe: boolean;
    email: string;
    imgPasswordInVisible: any;
    enablePasswordField: boolean;
    isErrorEmail: boolean;
    imgPasswordVisible: any;
    isErrorPass: {
        show: boolean;
        message: string
    };
    token: string;
    isResetPassword: boolean;
    passwordValidationStatus: {
        title: string;
        isNotValid: boolean;
    }[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class NewPasswordWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    resetPasswordApiCallId: string = ""
    passReg: RegExp;
    emailReg: RegExp;
    labelTitle: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            email: "",
            password: "",
            isErrorEmail: false,
            enablePasswordField: true,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            checkedRememberMe: false,
            imgPasswordInVisible: imgPasswordInVisible,
            isErrorPass: { show: false, message: '' },
            confirmPassword: '',
            token: "",
            isResetPassword: false,
            passwordValidationStatus: []
        };

        this.emailReg = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
        this.passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
            // Customizable Area End

            runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token') || "";
        this.setState({token})
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        message.getData(
            getName(MessageEnum.AuthTokenDataMessage)
        );

        message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            if (apiRequestCallId === this.resetPasswordApiCallId) {
                if(responseJson?.data?.data)
                    this.setState({isResetPassword: true})
            }
        }
        // Customizable Area End
    }

    handleCloseDialog() {
        this.setState({isResetPassword: false})
        this.props.navigation.navigate("LoginBlock")
    }
    resetPassword() {
        if(this.state.passwordValidationStatus.some(item => item.isNotValid === true)) return;
        this.setState({ isErrorPass: { show: false, message: '' } });

        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
            token: this.state.token
        };
        const httpBody = {
            "new_password": this.state.password,
            "confirm_password": this.state.confirmPassword
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resetPassApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.resetPasswordApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    
    }

    handleChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if(name === "reTypePassword"){
            this.setState({ confirmPassword: value })
            this.passwordValidation(this.state.password, value);
        }
        if(name === "password"){
            this.setState({ password: value })
            this.passwordValidation(value, this.state.confirmPassword);
        }
    }

    passwordValidation(passwordValue: string, passwordConrifmValue: string) {
        const minEightChars = /.{8,}/; // At least 8 characters
        const upperCase = /[A-Z]/; // At least one uppercase letter
        const lowerCase = /[a-z]/; // At least one lowercase letter
        const specialChar = /[^a-zA-Z0-9]/; // At least one special character
        const number = /\d/; // At least one digit

        const passwordValidationStatus = [];

        passwordValidationStatus.push({title: "Password must be filled.", isNotValid: !passwordValue});
        passwordValidationStatus.push({title: "Password must contain at least 8 characters.", isNotValid: !minEightChars.test(passwordValue)});
        passwordValidationStatus.push({title: "Password must contain at least one lowercase letter.", isNotValid: !lowerCase.test(passwordValue)});
        passwordValidationStatus.push({title: "Password must contain at least one uppercase letter.", isNotValid: !upperCase.test(passwordValue)});
        passwordValidationStatus.push({title: "Password must contain at least 1 number.", isNotValid: !number.test(passwordValue)});
        passwordValidationStatus.push({title: "Password must contain at least one special character.", isNotValid: !specialChar.test(passwordValue)});
        passwordValidationStatus.push({title: "Password And Confirm Password Must be same.", isNotValid: passwordValue !== passwordConrifmValue || !passwordValue || !passwordConrifmValue});
        
        this.setState({ passwordValidationStatus })
    }

    // Customizable Area End
}
