import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";


interface CountryData {
  name: string;
  code: string;
}
interface SignupData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  username: string;
  reTypePassword: string;
  country: string;
  state: string;
  accountType: string;
  dateOfBirth: string;
  gender: number | string;
  platform: string;

  esportTitleId?: any;
  esportRoleId?: any;
  sportTitleId?: any;
  sportPositionId?: any;
}

export interface TitleData {
  id: number;
  title: string;
}
export interface RoleData {
  id: number;
  name: string;
  esport_id: number;
}
export interface PositionData {
  id: number;
  position_title: string;
  sport_title_id: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

export const configJSON = require("./config");
export interface S {
  // Customizable Area Start
  signupData: SignupData;
  errorMessageOn: SignupData;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  validationMsg: boolean;
  otpAuthToken: string;
  isMaleBtnChecked: boolean;
  isFemaleBtnChecked: boolean;
  countryData: CountryData[];
  roleData: RoleData[],
  statesName: string[];
  TermsChecked: boolean;
  privacyChecked: boolean;
  showModal: boolean;
  showPrivacyModal: boolean;
  isShowInfo: boolean;

  esportTitles: TitleData[];
  esportRoles: RoleData[];

  sportTitles: TitleData[];
  sportPositions: PositionData[];

  isCheckedTermAndPrivacy: boolean;
  userId: string,

  initialDateOpen: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SignupController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  emailErrorMsg: string;
  fieldBlank: string;
  inCorrectFirstname: string;
  inCorrectLastname: string;
  inCorrectPassword: string;
  invalidConfirmPassword: string;
  noError: string;
  createAccountApiCallId: any;
  getCountryCodeApiCallId: any;
  getRolesApiCallId: any;
  getStatesApiCallId: any;

  getEsportTitlesApiCallId: any;
  getEsportRolesApiCallId: any;
  getSportTitlesApiCallId: any;
  getSportPositionsApiCallId: any;
  updateAccountCallId: string="";
  steamSSOApiCallId: string="";
  epicSSOApiCallId: string="";
  getEmailValidationCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.createAccount = this.createAccount.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isShowInfo: false,
      signupData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        reTypePassword: "",
        username: "",
        country: "",
        state: "",
        accountType: "",
        dateOfBirth: "",
        gender: "",
        platform: "email_account",
        esportTitleId: null,
        esportRoleId: null,
        sportTitleId: null,
        sportPositionId: null,
      },
      errorMessageOn: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        reTypePassword: "",
        username: "",
        country: "",
        state: "",
        accountType: "",
        dateOfBirth: "",
        gender: "",
        platform: "",
        esportTitleId: "",
        esportRoleId: "",
        sportTitleId: "",
        sportPositionId: "",
      },
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      enablePasswordField: true,
      enableReTypePasswordField: true,
      validationMsg: false,
      otpAuthToken: "",
      countryData: [],
      roleData: [],
      statesName: [],
      TermsChecked: false,
      privacyChecked: false,
      showModal: false,
      showPrivacyModal: false,
      isCheckedTermAndPrivacy: false,

      esportTitles: [],
      esportRoles: [],
      sportTitles: [],
      sportPositions: [],
      userId:"",
      initialDateOpen: new Date(new Date().getFullYear() - 13, new Date().getMonth(), new Date().getDate()).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
      // Customizable Area End
    };

    // Customizable Area Start
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.emailErrorMsg = configJSON.emailErrorMsg;
    this.fieldBlank = configJSON.blankField;
    this.inCorrectFirstname = configJSON.inCorrectFirstname;
    this.inCorrectLastname = configJSON.inCorrectLastname;
    this.inCorrectPassword = configJSON.incorrectPass;
    this.invalidConfirmPassword = configJSON.invalidConfirmPassword;
    this.noError = configJSON.noError;
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    const { signupData, errorMessageOn } = this.state;
    const searchParams = new URLSearchParams(this.props.navigation.history.location.search)     
       
    const email = searchParams.get('email')??"";
    const userName = searchParams.get('user_name')??"";
    const userId = searchParams.get('user_id')??"";

    this.setState({
      signupData: {
        ...signupData,
        accountType: this.getAccountType(),
        email,
        username:userName
      },
      errorMessageOn: {
        ...errorMessageOn,
      },
      userId,
    });
    this.getCountryCode();
    if(this.getAccountType() === "Sports") {
      this.getSportTitles();
      this.getSportPositions();
    } else {
      this.getEsportTitles();
      this.getEsportRoles();
    }
  }
  
  getAccountType = () => {
    let userSelectedAccountType = window.localStorage.getItem("typeSport");
    return userSelectedAccountType === "Sports" ? "Sports" : "eSports";
  };  

  handleGender(value: string) {
    const { signupData, isMaleBtnChecked, isFemaleBtnChecked } = this.state;

    // If gender is already selected and neither male nor female button is checked, return
    if (signupData.gender && !isMaleBtnChecked && !isFemaleBtnChecked) {
      return;
    }

    let updatedGender;
    if (value === "Male" || value === "Female") {
      updatedGender = value;
    } else {
      updatedGender = 2; // Assuming 2 represents another gender option
    }

    this.setState({
      isMaleBtnChecked: updatedGender === "Male",
      isFemaleBtnChecked: updatedGender === "Female",
      signupData: {
        ...signupData,
        gender: updatedGender,
      },
      errorMessageOn: {
        ...this.state.errorMessageOn,
        gender: "",
      },
    });
  }

  getKeyByValue = (value: string) => {
    const country = this.state.countryData.find(
      (country) => country.name === value
    );
    return country ? country.code : null;
  };

  handleChangeCountry = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        country: selectedOption.value,
        state:""
      },
      statesName:[],
      errorMessageOn: {
        ...prevState.errorMessageOn,
        country: "",
      },
    }));
    let code: any = this.getKeyByValue(selectedOption.value);
    this.getStatesNameBasedOnCountryCode(code);
  };

  handleChangeEsportTitle = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        esportTitleId: selectedOption.value,
        esportRoleId: 0,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        esportTitleId: "",
      },
    }));
  };

  handleChangeEsportRole = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        esportRoleId: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        esportRoleId: "",
      },
    }));
  };

  handleChangeSportTitle = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        sportTitleId: selectedOption.value,
        sportPositionId: 0,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        sportTitleId: "",
      },
    }));
  };

  handleChangeSportPosition = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        sportPositionId: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        sportPositionId: "",
      },
    }));
  };

  handleChangeState = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        state: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        state: "",
      },
    }));
  };

  handleDateInputField = (value: any) => {
    const date = new Date(value.toDate());
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    this.setState((prevState) => ({
      signupData: {
        ...prevState.signupData,
        dateOfBirth: formattedDate,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        dateOfBirth: "",
      },
      initialDateOpen: formattedDate
    }));
  };

  changeHandler(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event?.target;
    const { signupData } = this.state;
    this.setState({
      signupData: {
        ...signupData,
        [name]: value.trimStart(),
      },
    });
    this.setState((prevState) => ({
      errorMessageOn: {
        ...prevState.errorMessageOn,
        [name]: "",
      },
    }));

    const elements = [
      { name: "firstName", validate: this.firstNameValidation(value) },
      { name: "lastName", validate: this.lastNameValidation(value) },
      { name: "email", validate: this.emailValidation(value) },
      { name: "password", validate: this.passwordValidation(value) },
      {
        name: "reTypePassword",
        validate: this.confirmPassValidation(
          value,
          this.state.signupData?.password
        ),
      },
    ];
    elements.forEach((item) => {
      if (name === item?.name) {
        return this.setState({
          errorMessageOn: {
            ...this.state.errorMessageOn,
            [item.name]: item.validate,
          },
        });
      }
    });
  }

  firstNameValidation(value: string) {
    if (!value) {
      return "First name must be filled";
    } else if (value.length <= 1) {
      return this.inCorrectFirstname;
    } else {
      return "";
    }
  }
  lastNameValidation(value: string) {
    if (!value) {
      return "Last name must be filled";
    } else if(value.trim().length < 2){
      return "Last name is not valid";
    } else {
      return "";
    }
  }
  emailValidation(value: string) {
    const emailRegExp =
      /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
    if (!value) {
      return "Email must be filled";
    } else if (!value.match(emailRegExp)) {
      return this.emailErrorMsg;
    } else {
      this.getEmailValidation(value)
    }
  }

  passwordValidation(value: string) {
    const minEightChars = /.{8,}/; // At least 8 characters
    const upperCase = /[A-Z]/; // At least one uppercase letter
    const lowerCase = /[a-z]/; // At least one lowercase letter
    const specialChar = /[^a-zA-Z0-9]/; // At least one special character
    const number = /\d/; // At least one digit

    if (!value) {
      return "Password must be filled"; // Error message for blank field
    }
    if (!minEightChars.test(value)) {
      return "Password must contain at least 8 characters."; // Error message for minimum length
    }
    if (!upperCase.test(value)) {
      return "Password must contain at least one uppercase letter."; // Error message for uppercase letter
    }
    if (!lowerCase.test(value)) {
      return "Password must contain at least one lowercase letter."; // Error message for lowercase letter
    }
    if (!number.test(value)) {
      return "Password must contain at least 1 number."; // Error message for minimum number
    }
    if (!specialChar.test(value)) {
      return "Password must contain at least one special character."; // Error message for special character
    }
    return "";
  }

  passwordInstruction() {
    const minEightChars = /.{8,}/;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const specialChar = /[^a-zA-Z0-9]/;
    const number = /\d/;

    const passwordValue = this.state.signupData.password;
    const passwordConrifmValue = this.state.signupData.reTypePassword;

    const passwordInstrucStatus = [];

    passwordInstrucStatus.push({title: "Password must be filled.", isNotValid: !passwordValue});
    passwordInstrucStatus.push({title: "Password must contain at least 8 characters.", isNotValid: !minEightChars.test(passwordValue)});
    passwordInstrucStatus.push({title: "Password must contain at least one lowercase letter.", isNotValid: !lowerCase.test(passwordValue)});
    passwordInstrucStatus.push({title: "Password must contain at least one uppercase letter.", isNotValid: !upperCase.test(passwordValue)});
    passwordInstrucStatus.push({title: "Password must contain at least 1 number.", isNotValid: !number.test(passwordValue)});
    passwordInstrucStatus.push({title: "Password must contain at least one special character.", isNotValid: !specialChar.test(passwordValue)});
    passwordInstrucStatus.push({title: "Password And Confirm Password Must be same.", isNotValid: passwordValue !== passwordConrifmValue});
    
    return passwordInstrucStatus;
}

  confirmPassValidation(value: string, passValue: string) {
    if (!value) {
      return "Confirm Password must be filled";
    } else if (passValue && value && passValue !== value) {
      return this.invalidConfirmPassword;
    } else if (!passValue) {
      return this.passwordValidation(value)
    }
    else {
      return "";
    }
  }

  formValidation = (signupData: SignupData) => {
    type Item = {
      name: string;
      validate: string|undefined;
    };

    type Element = {
      [key: string]: string|undefined;
    };

    type MappingElement = {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      reTypePassword: string;
    };

    const elements: Item[] = [
      {
        name: "firstName",
        validate: this.firstNameValidation(signupData.firstName.trim()),
      },
      {
        name: "lastName",
        validate: this.lastNameValidation(signupData.lastName.trim()),
      },
      { name: "email", validate: this.emailValidation(signupData.email.trim()) },
      { name: "password", validate: this.passwordValidation(signupData.password.trim()) },
      {
        name: "reTypePassword",
        validate: this.confirmPassValidation(
          signupData.reTypePassword.trim(),
          this.state.signupData?.password.trim()
        ),
      },
    ];

    const mappingElements = elements.reduce(
      (accumulator: Element, current: Item) => {
        accumulator[current.name] = current.validate;
        return accumulator;
      },
      {}
    );

    const errorMessageOn = {
      ...(mappingElements as MappingElement),
      username:
        signupData.username.trim() === "" ? "Username must be filled" : "",
      country:
        signupData.country.trim() === "" ? "Country must be filled" : "",
      state: signupData.state.trim() === "" ? "State must be filled" : "",
      accountType:
        signupData.accountType.trim() === ""
          ? "Account type must be filled"
          : "",
      dateOfBirth:
        signupData.dateOfBirth.trim() === ""
          ? "Date of birth must be filled"
          : "",
      gender: signupData.gender === "" ? "Gender must be selected" : "",
      platform: "",
      esportTitleId: signupData.accountType === "eSports" && !signupData.esportTitleId ? "Please select Esports title" : "",
      esportRoleId: signupData.accountType === "eSports" && !signupData.esportRoleId ? "Please select in-game role" : "",
      sportTitleId: signupData.accountType === "Sports" && !signupData.sportTitleId ? "Please select game title" : "",
      sportPositionId: signupData.accountType === "Sports" && !signupData.sportPositionId ? "Please select position" : "",
    }

    this.setState({ errorMessageOn });

    const isValid = Object.values(errorMessageOn).every(
      (value) => (value || "").trim() === ""
    );
    return isValid;
  };

  handleClickInfo() {
    this.setState({
      isShowInfo: !this.state.isShowInfo,
    });
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowRetypePassword = () => {
    this.setState({
      enableReTypePasswordField: !this.state.enableReTypePasswordField,
    });
  };
  handleTerms = (value: any) => {
    this.setState({ TermsChecked: value });
  };
  handlePrivacy = (value: any) => {
    this.setState({ privacyChecked: value });
  };
  handleModalClose = (value: any) => {
    this.setState({ showModal: value });
  };

  createAccount() {
    const { signupData } = this.state;

    if (this.formValidation(signupData)) {
      if (!this.state.isCheckedTermAndPrivacy) {
        this.setState({ showModal: true });
        return;
      }
      this.setState({ showModal: false });
      let selectGender: number | string = 2;
      if (signupData.gender === "Male") {
        selectGender = 0;
      } else if (signupData.gender === "Female") {
        selectGender = 1;
      }
      const header = {
       "Accept":"*/*"
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
      if(this.state.userId){
        let formData=new FormData();
        formData.append("first_name",signupData.firstName)
        formData.append("last_name",signupData.lastName)
        formData.append("user_name",signupData.username)
        formData.append("email",signupData.email)
        formData.append("esport_title_id",String(signupData.esportTitleId))
        formData.append("country",signupData.country)
        formData.append("state",signupData.state)
        formData.append("date_of_birth",signupData.dateOfBirth)
        formData.append("gender",String(signupData.gender).toLowerCase())
        formData.append("password",signupData.password)
        formData.append("in_game_role_id",String(signupData.esportRoleId))
        this.updateAccountCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateAccountApiEndPoint}${this.state.userId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.apiMethodPatch
              );

      }else{ 
        const header = {
          "Content-Type": configJSON.contentTypeApiAddDetail,
        };
        const attrs = {
          first_name: signupData.firstName,
          last_name: signupData.lastName,
          user_name: signupData.username,
          gender: selectGender,
          country: signupData.country,
          account_type: signupData.accountType,
          state: signupData.state,
          date_of_birth: signupData.dateOfBirth,
        email: signupData.email,
        password: signupData.password,
        platform: "email_account",
        
        esport_title_id: signupData.esportTitleId,
        in_game_role_id: signupData.esportRoleId,
        sport_title_id: signupData.sportTitleId,
        sport_position_id: signupData.sportPositionId,
      };
      const dataBody = {
        type: "email_account",
        attributes: attrs,
      };
      const httpBody = {
        data: dataBody,
      };
      this.createAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.account_blockApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
            );
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.apiMethodTypeAddDetail
              );
            }
              runEngine.sendMessage(requestMessage.id, requestMessage);
              return true;
          }
  }

  getCountryCode = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCodeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getEsportTitles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEsportTitlesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEsportTitlesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getEsportRoles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEsportRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEsportRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSportTitles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSportTitlesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSportTitlesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSportPositions = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSportPositionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSportPositionsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStatesNameBasedOnCountryCode = async (code: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStatesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStatesApiEndPoint}?country=${code}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const apiRequestCallIds = {
        [this.createAccountApiCallId]: this.handleSignUpApiResponse,
        [this.getCountryCodeApiCallId]: this.handleCountryCodeApiResponse,
        [this.getStatesApiCallId]: this.handleStatesApiResponse,

        [this.getSportTitlesApiCallId]: this.handleSportTitlesApiResponse,
        [this.getSportPositionsApiCallId]: this.handleSportPositionsApiResponse,
        [this.getEsportTitlesApiCallId]: this.handleEsportTitlesApiResponse,
        [this.getEsportRolesApiCallId]: this.handleEsportRolesApiResponse,
        [this.updateAccountCallId]:this.handleUpdateAccountApiResponse,
        [this.steamSSOApiCallId]: this.handleSteamSSOApiResponse,
        [this.epicSSOApiCallId]: this.handleEpicSSOApiResponse,
        [this.getEmailValidationCallId]: this.handleEmailValidationApiResponse,

      };

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
      }
    }

    // Customizable Area End
  }

  handleEmailValidationApiResponse=(responseJson: any)=>{
    if(responseJson){
      if(responseJson.errors){
        this.setState((prevState) => ({
          errorMessageOn: {
            ...prevState.errorMessageOn,
            email:responseJson.errors[0].email
          },
        }));
      }
    }
  }

  handleSteamSSOApiResponse=(responseJson: any)=>{    
    if(responseJson.login_url){
      window.location.href=responseJson.login_url
    }
  }

  handleEpicSSOApiResponse=(responseJson: any)=>{    
    if(responseJson.login_url){
      window.location.href=responseJson.login_url
    }
  }

  handleUpdateAccountApiResponse=(responseJson: any, errorResponse?: string)=>{
    if(responseJson.message){
      const navigationMessage = new Message(getName(MessageEnum.NavigationEmailLogInMessage))
      navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage),this.props)
      this.send(navigationMessage);
    }
  }
  handleSignUpApiResponse = (responseJson: any, errorResponse?: string) => {
    if (responseJson?.errors?.length) {
      responseJson.errors.map((error: any) => {
        if (error?.user_name) {
          this.setState((prevState) => ({
            errorMessageOn: {
              ...prevState.errorMessageOn,
              username: `Username ${error.user_name} !`,
            },
          }));
        }
        if (error?.email) {
          this.setState((prevState) => ({
            errorMessageOn: {
              ...prevState.errorMessageOn,
              email: `Email ${error.email} !`,
            },
          }));
        }
      });
    }
    if (!errorResponse && responseJson) {
      if (!responseJson.errors) {
        this.setState({
          signupData: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            reTypePassword: "",
            username: "",
            country: "",
            state: "",
            accountType: this.getAccountType(),
            dateOfBirth: "",
            gender: "",
            platform: "email_account",
          },
        });
        toast.info("Please check your email and activate your account before logging in.")
        this.goToLoginPage();
      }
    }
  };
  handleCountryCodeApiResponse = (
    responseJson: any | { error: string },
    errorResponse?: string
  ) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        this.setState({ countryData: responseJson.countryName });
      }
    }
  };
  handleStatesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (!errorResponse && responseJson) {
      if (!responseJson.error) {
        this.setState({ statesName: responseJson });
      }
    }
  };

  handleEsportTitlesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ esportTitles: responseJson.data });
    }
  };

  handleEsportRolesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson)) {
      this.setState({ esportRoles: responseJson });
    }
  };

  handleSportTitlesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ sportTitles: responseJson.data });
    }
  };

  handleSportPositionsApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ sportPositions: responseJson.data });
    }
  };

  goToLoginPage = () => {
    this.props.navigation.navigate("LoginBlock");
  };
  handleChangeGender = (event: { target: { value: string } }) => {
    this.setState((prevState) => ({
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      signupData: {
        ...prevState.signupData,
        gender: event.target.value,
      },
    }));
  };
  getDateRange = () => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 120, 0, 1);
    const maxDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

    return {
      minDate,
      maxDate,
    };
  };
  capitalizeFirstLetter(string: any) {
    if (string.length === 0) {
      return string;
    }
    let result = string.charAt(0).toUpperCase();
    result += string.slice(1).toLowerCase();
    return result;
  }

  showTermAndPrivacy=()=>{
    this.setState({ showModal: true });
  }

  hideTermAndPrivacy=()=>{
    this.setState({ showModal: false });
  }

  acceptTermAndPrivacy=(value: boolean)=>{
    this.setState({ isCheckedTermAndPrivacy: value, showModal: false });
  }

  steamLogin = () => {
    const dataBody = {
      data: {
        type: "steam_account",
      },
    };
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const getSteamSSOid = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.steamSSOApiCallId = getSteamSSOid.messageId;

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSteamSSO
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataBody)
    );

    getSteamSSOid.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    
    runEngine.sendMessage(getSteamSSOid.id, getSteamSSOid);
  };

  epicLogin = () => {
    const dataBody = {
      data: {
        type: "epic_games",
      },
    };
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const getEpicSSOid = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.epicSSOApiCallId = getEpicSSOid.messageId;

    getEpicSSOid.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSteamSSO
    );

    getEpicSSOid.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getEpicSSOid.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataBody)
    );

    getEpicSSOid.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    
    runEngine.sendMessage(getEpicSSOid.id, getEpicSSOid);
  };

  getEmailValidation = async (email:string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailValidationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.getEmailValidationEndPoint}${email}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
