import React from "react";
import { Box, Typography, styled } from "@material-ui/core";
import { bgGunImg, fileIcon, logoApp } from "../../blocks/dashboard/src/assets";
import { GameFolder } from "../../blocks/dashboard/src/DashboardController.web";

interface IPropBrowseRecentGame {
    data: GameFolder[]
}

export default function BrowseRecentGame({ data }: IPropBrowseRecentGame) {
    return <BrowseRecentGameStyled className="browseRecentGame_box">
        {!data?.length && (<Box style={{ display: 'flex', justifyContent: 'center' }}><Typography className="description_text">No browse recent games</Typography></Box>)}
        <div
            className="listRecentGame"
            data-test-id='recentGameId'
        >
            {data?.map((item, index) => (
                <div
                    key={index}
                    className="recentGameItem"
                    style={{
                        backgroundImage: `url(${item.attachment.thumbnail || bgGunImg})`,
                    }}
                >
                    <div className="backgroundBlurGameItem" />
                    <div className="content_text">
                        <Box className="top_left_info">
                            <Box style={{position: "relative"}}>
                                <Typography
                                    style={{
                                        color: '#FF881A',
                                        fontWeight: '500',
                                        padding: '5px',
                                        position: "absolute",
                                        left: "10px"
                                    }}
                                >
                                    {item.attachment.files_uploads?.length}
                                </Typography>
                                <img src={fileIcon} alt="File Icon"/>
                            </Box>
                        </Box>
                        <Box className="text_center">
                            <Typography className="description_text">{item.attachment?.team_name}</Typography>
                            <Typography className="description_text"> Vs</Typography>
                            <Typography className="description_text">{item.attachment?.oppo_team_name}</Typography>
                        </Box>
                    </div>
                    <div
                        style={{
                            marginTop: '24px',
                            marginBottom: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            zIndex: 2
                        }}
                    >
                        <img src={logoApp} width={'100px'} height={'50px'} alt="Logo" />
                    </div>
                </div>
            ))}
        </div>
    </BrowseRecentGameStyled>
}


const BrowseRecentGameStyled = styled(Box)(({ theme }) => ({
    paddingBottom: '24px',
    background: "#252527F2",
    position: "relative",
    borderRadius: "10px",
    "& .description_text": {
        color: "#CFD1D4",
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400"
    },
    "& .content_text": {
        padding: '8px', 
        position: 'relative', 
        zIndex: 2, 
        display: "flex",
        justifyContent: "center",
        "& .top_left_info": {
            position: "absolute",
            left: 10,
            top: 10
        },
        "& .text_center": {
            display: "flex",
            flexDirection: "column"
        },
        "& .description_text": {
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center"
        }, 
    }, 
    "& .listRecentGame": {
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '0px 24px',
        "& .recentGameItem": {
            minWidth: '300px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            marginRight: '16px',
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& .backgroundBlurGameItem": {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1,
            }
        }
    }
}))