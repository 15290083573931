import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { 
  AddUserData, 
  TitleData, 
  RoleData, 
  PositionData 
} from "./types";
import { 
  getStorageData 
} from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
}
type Role = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

type Account = {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    country_code: string | null;
    email: string;
    first_name: string;
    full_phone_number: string;
    last_name: string;
    phone_number: string | null;
    type: string;
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string | null;
    position: string | null;
    staff: string;
    sport_title_id: string | null;
    sport_position_id: string | null;
    sport_position: any;
    guardian_email: string;
    guardian_first_name: string;
    guardian_last_name: string;
    guardian_gender: string;
    last_visit_at: string | null;
    photo: string | null;
    role: Role;
    missed_op_score: string | null;
    date_of_birth: string;
    in_game_role:{name: string}
  };
};
export interface ObjData {
  [key: string]: string | null;
}
// Customizable Area End
interface MarkedEvents {
  missed_op: number;
  missed: number;
  made: number;
}

interface TeamResponse {
   id: number;
  team_name: string;
  team_image: string | null;
  user_size: number;
  marked_events: MarkedEvents;
  role_count: RoleCount[];
  most_common: string;
}

interface RoleCount {
  name: string;
  count: number;
}

export interface S {
  id: string;
  // Customizable Area Start
  addUserData: AddUserData;
  errorMessageOn: AddUserData;
  accountsList: any; 
  showing: string;
  searchName: string;
  esportTitles: TitleData[];
  esportRoles: RoleData[];
  isMaleBtnChecked: boolean;
  isFemaleBtnChecked: boolean;
  isGuardianMaleBtnChecked: boolean;
  isGuardianFemaleBtnChecked: boolean;
  sportTitles: TitleData[];
  sportPositions: PositionData[];
  openCreateModal: boolean;
  currentPage: number;
  rowsPerPage: number;
  totalPages: number; 
  currentTeamId: number | null;
  teamData:TeamResponse;
  isEdit: boolean;
  deleteAccountModal:boolean;
  deleteData:ObjData | undefined
  initialDateOpen: string;
  initialLoading: boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSportTitlesApiCallId = "";
  getSportPositionsApiCallId = "";
  getEsportTitlesApiCallId = "";
  getEsportRolesApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  createUserApiCallId = "";
  getUserDataApiCallId = "";
  inviteUserApiCallId="";
  getUserTeamApiCallId="";
  editUserApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      isEdit: false,
      deleteAccountModal:false,
      accountsList: [],
      teamData:{} as TeamResponse,
      addUserData: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        accountType: "",
        dateOfBirth: "",
        gender: "",
        guardianGender: "",
        guardianFirstName: "",
        guardianLastName: "",
        guardianEmail: "",
        invitationEmail:"",
        esportTitleId: null,
        sportPositionId: null,
        sportTitleId: null,
        esportRoleId: null,
      },
      errorMessageOn: {
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        accountType: "",
        username: "",
        esportRoleId: "",
        sportTitleId: "",
        dateOfBirth: "",
        esportTitleId: "",
        guardianFirstName: "",
        guardianLastName: "",
        guardianGender: "",
        sportPositionId: "",
        guardianEmail: "",
        invitationEmail:"",
      },
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      isGuardianMaleBtnChecked: false,
      isGuardianFemaleBtnChecked: false,
      searchName:"",
      showing: "Staff",
      esportTitles: [],
      esportRoles: [],
      sportTitles: [],
      sportPositions: [],
      openCreateModal: false,
      currentPage: 1,        // Current page
      rowsPerPage: 10, // Items per page
      totalPages: 1,  // Total pages from backend
      currentTeamId: null,
      deleteData:undefined,
      initialDateOpen: new Date(new Date().getFullYear() - 13, new Date().getMonth(), new Date().getDate()).toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
      initialLoading: true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  intitalFormState = { addUserData: {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    dateOfBirth: "",
    gender: "",
    esportTitleId: null,
    esportRoleId: null,
    sportTitleId: null,
    sportPositionId: null,
    guardianGender: "",
    guardianFirstName: "",
    guardianLastName: "",
    guardianEmail: "",
    invitationEmail:"",
  },
  errorMessageOn: {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    accountType: "",
    dateOfBirth: "",
    gender: "",
    esportTitleId: "",
    esportRoleId: "",
    sportTitleId: "",
    sportPositionId: "",
    guardianGender: "",
    guardianFirstName: "",
    guardianLastName: "",
    guardianEmail: "",
    invitationEmail:"",
  },
  isMaleBtnChecked: false,
  isFemaleBtnChecked: false,
  isGuardianMaleBtnChecked: false,
  isGuardianFemaleBtnChecked: false}
  playerColumns  = [
    "Photo",
    "Name",
    "Date of Birth",
    "Position",
    "Last Active",
    "Added On",
    "Guardian Name",
    "Guardian Sex",
    "Guardian Email",
    "Verified",
    "Action"
  ];
  staffColumns = [
    "Photo",
    "Name",
    "Date of Birth",
    "Role",
    "Last Active",
    "Added On",
    "Verified",
    "Action"
  ]
  async componentDidMount() {
    super.componentDidMount();
    const accountType = await this.getAccountType()
    this.esportPlayerColumns(accountType)
    await this.getAPIData(accountType)
  }
 esportPlayerColumns (type:string) {
  if(type ==='eSports'){
    this.playerColumns = this.playerColumns.map(item => item ==='Position' ? 'In-game Role' : item)
  }
 }
  async getAPIData(accountType: string) {
    await this.getUserDataFromAPI()
    if (accountType === "Sports") {
      await this.getSportTitles();
      await this.getSportPositions();
    } else {
      this.getEsportTitles();
      this.getEsportRoles();
    }
  }
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    // Condition for fetching group list.
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const apiRequestCallIds = {
        [this.getSportTitlesApiCallId]: this.handleSportTitlesApiResponse,
        [this.getSportPositionsApiCallId]: this.handleSportPositionsApiResponse,
        [this.getEsportTitlesApiCallId]: this.handleEsportTitlesApiResponse,
        [this.getEsportRolesApiCallId]: this.handleEsportRolesApiResponse,
        [this.getUserDataApiCallId]: this.handleUserDataApiResponse,
        [this.createUserApiCallId]: this.handleCreateUserApiResponse,
        [this.inviteUserApiCallId]: this.handleInvitedUserApiResponse,
        [this.getUserTeamApiCallId]: this.handleGetUserTeamApiResponse,
        [this.editUserApiCallId]: this.handleEditUserApiResponse,
        [this.deleteGroupApiCallId]: this.handleDeleteUserApiResponse,




      };

      if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
        apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
      }
    }
  };

  getAccountType = async () => {
    let userSelectedAccountType = await getStorageData("typeSport");
    this.setState({
      addUserData: {
        ...this.state.addUserData,
        accountType: userSelectedAccountType
      }
    })
    return userSelectedAccountType === "Sports" ? "Sports" : "eSports";
  };

  handleGender = (value: string) => {
    const { addUserData } = this.state;
    this.setState({
      isMaleBtnChecked: value === "Male",
      isFemaleBtnChecked: value === "Female",
      addUserData: {
        ...addUserData,
        gender: value,
      },
      errorMessageOn: {
        ...this.state.errorMessageOn,
        gender: "",
      },
    });
  }
  handleSearchName = (value: string) =>{
    this.setState({searchName: value})
  }
  handleGuardianGender=(value: string)=> {
    const { addUserData } = this.state;
    this.setState({
      isGuardianMaleBtnChecked: value === "Male",
      isGuardianFemaleBtnChecked: value === "Female",
      addUserData: {
        ...addUserData,
        guardianGender: value,
      },
      errorMessageOn: {
        ...this.state.errorMessageOn,
        guardianGender: "",
      },
    });
  }
  handleDeleteUserApiResponse = (responseJson: any, errorResponse?: string) => {
    if (!responseJson.error && !responseJson.errors) {
      toast.success("User deleted successfully")
      this.setState({
        ...this.state,
        deleteAccountModal: false,
        deleteData: undefined
      })
      this.getUserDataFromAPI()
    }else{
      toast.error("Something Went Wrong")
    }
  }
  handleEditUserApiResponse = (responseJson: any, errorResponse? : string) => {
    if (!responseJson.error && !responseJson.errors) {
      toast.success("User updated successfully")
      this.setState({
        ...this.state,
      addUserData:{...this.state.addUserData, ...this.intitalFormState.addUserData},
      errorMessageOn:{...this.state.errorMessageOn, ...this.intitalFormState.errorMessageOn},

        openCreateModal:false,
        
      })
      this.getUserDataFromAPI()
    }else{
      toast.error("Something Went Wrong")
    }
  }
  handleCreateUserApiResponse = (responseJson: any, errorResponse?: string) =>{
    if(!responseJson.errors&&responseJson.meta.token){
      toast.success("User Created")
      this.setState({
        ...this.state,
      addUserData:{...this.state.addUserData,...this.intitalFormState.addUserData},
        openCreateModal:false,
      })
      this.getUserDataFromAPI()
    }else{
      toast.error("Something Went Wrong")
    }
  }
  handleInvitedUserApiResponse = (responseJson: any, errorResponse?: string) =>{
    if(!responseJson.errors){
      toast.success("Invite Sent")
      this.setState({
        errorMessageOn: {
          firstName: "",
          lastName: "",
          username: "",
          email: "",
          accountType: "",
          dateOfBirth: "",
          gender: "",
          esportRoleId: "",
          sportTitleId: "",
          esportTitleId: "",
          guardianLastName: "",
          sportPositionId: "",
          guardianGender: "",
          guardianFirstName: "",
          guardianEmail: "",
          invitationEmail:"",
        },
        addUserData: {
          lastName: "",
          accountType: "",
          firstName: "",
          username: "",
          dateOfBirth: "",
          email: "",
          gender: "",
          sportTitleId: null,
          esportTitleId: null,
          esportRoleId: null,
          sportPositionId: null,
          guardianGender: "",
          invitationEmail:"",
          guardianLastName: "",
          guardianFirstName: "",
          guardianEmail: "",
        },
        isMaleBtnChecked: false,
        isFemaleBtnChecked: false,
        isGuardianMaleBtnChecked: false,
        isGuardianFemaleBtnChecked: false,
        openCreateModal:false,
      })
      this.getUserDataFromAPI()
    }else{
      toast.error("Something Went Wrong")
    }
  }
  handleGetUserTeamApiResponse = (response:any,errorResponse?:string) =>{  
    if(!errorResponse){
      this.setState({teamData:response || {}})

    }
  }
  handleUserDataApiResponse = (responseJson: any, errorResponse?: string) =>{
    this.setState({initialLoading:false})
    if(!errorResponse){
      this.setState({accountsList:responseJson?.data || [],totalPages:responseJson?.meta.pages|| 1,currentTeamId:responseJson?.meta.team_id||null})
      this.getUserTeamFromAPI()
    }
  }
  handleEsportTitlesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ esportTitles: responseJson.data });
    }
  };

  handleEsportRolesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson)) {
      this.setState({ esportRoles: responseJson });
    }
  };

  handleSportTitlesApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ sportTitles: responseJson.data });
    }
  };

  handleSportPositionsApiResponse = (responseJson: any, errorResponse?: string) => {
    if (Array.isArray(responseJson?.data)) {
      this.setState({ sportPositions: responseJson.data });
    }
  };

  handleChangeGender = (event: { target: { value: string } }) => {
    this.setState((prevState) => ({
      isMaleBtnChecked: false,
      isFemaleBtnChecked: false,
      addUserData: {
        ...prevState.addUserData,
        gender: event.target.value,
      },
    }));
  };

  handleChangeGuardianGender = (event: { target: { value: string } }) => {
    this.setState((prevState) => ({
      isGuardianMaleBtnChecked: false,
      isGuardianFemaleBtnChecked: false,
      addUserData: {
        ...prevState.addUserData,
        guardianGender: event.target.value,
      },
    }));
  };

  handleChangeEsportTitle = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        esportTitleId: selectedOption.value,
        esportRoleId: 0,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        esportTitleId: "",
      },
    }));
  };

  handleChangeEsportRole = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        esportRoleId: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        esportRoleId: "",
      },
    }));
  };

  handleChangeSportTitle = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        sportTitleId: selectedOption.value,
        sportPositionId: 0,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        sportTitleId: "",
      },
    }));
  };

  handleChangeSportPosition = (selectedOption: { value: string; label: string }) => {
    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        sportPositionId: selectedOption.value,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        sportPositionId: "",
      },
    }));
  };

  getEsportTitles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEsportTitlesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEsportTitlesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getEsportRoles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEsportRolesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEsportRolesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getQueryParams = () =>{
    const obj:{[key: string]:string | number} = {
      per_page:this.state.rowsPerPage,
      page: this.state.currentPage,
      name:this.state.searchName,
      role:this.state.showing ==='Staff' ? 'staff':'player'
    }
    let paramStr = '';
    Object.keys(obj).forEach((key: string) =>{
      paramStr=paramStr+`&${key}=${obj[key]}`
    })
    return paramStr
  }
  getUserDataFromAPI = async () => {
    const token = await getStorageData("authToken")
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint+ '?' + this.getQueryParams()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserTeamFromAPI = async () => {
    const token = await getStorageData("authToken")
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserTeamApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userTeamEndPoint+ '?' + `team_id=${this.state.currentTeamId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  getSportTitles = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSportTitlesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSportTitlesApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSportPositions = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSportPositionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSportPositionsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleDateInputField = (value: any) => {
    const date = new Date(value.toDate());
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    this.setState((prevState) => ({
      addUserData: {
        ...prevState.addUserData,
        dateOfBirth: formattedDate,
      },
      errorMessageOn: {
        ...prevState.errorMessageOn,
        dateOfBirth: "",
      },
      initialDateOpen: formattedDate
    }));
  };

  changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    const { addUserData } = this.state;
    this.setState({
      addUserData: {
        ...addUserData,
        [name]: value.trimStart(),
      },
    });
    this.setState((prevState) => ({
      errorMessageOn: {
        ...prevState.errorMessageOn,
        [name]: "",
      },
    }));

    const elements = [
      { name: "firstName", validate: this.firstNameValidation(value) },
      { name: "lastName", validate: this.lastNameValidation(value) },
      { name: "email", validate: this.emailValidation(value) },
      { name: "guardianFirstName", validate: this.firstNameValidation(value) },
      { name: "guardianLastName", validate: this.lastNameValidation(value) },
      { name: "guardianEmail", validate: this.emailValidation(value) },
      { name: "invitationEmail", validate: this.emailValidation(value) },
    ];
    elements.forEach((item) => {
      if (name === item.name) {
        return this.setState({
          errorMessageOn: {
            ...this.state.errorMessageOn,
            [item.name]: item.validate,
          },
        });
      }
    });
  }

  firstNameValidation = (value: string) => {
    if (!value) {
      return "First name must be filled";
    } else if (value.length <= 1) {
      return "Please Enter a Valid First name";
    } else {
      return "";
    }
  }
  lastNameValidation = (value: string) => {
    if (!value) {
      return "Last name must be filled";
    } else if (value.length < 2) {
      return "Last name is not valid";
    } else {
      return "";
    }
  }
  emailValidation = (value: string) => {
    const emailRegExp =
      /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/;
    if (!value) {
      return "Email must be filled";
    } else if (!value.match(emailRegExp)) {
      return "Please Enter a Valid Email";
    } else {
      return ""
    }
  }

  getDateRange = () => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 120, 0, 1);
    const maxDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

    return {
      minDate,
      maxDate,
    };
  };

  onShowingChange = (event: React.ChangeEvent<{}>, tab: "Player" | "Staff") => {
    this.setState({
      showing: tab
    },() =>{this.handleOnSearch()})

  }

  handleOpenCreateModal = () => {
    this.setState({...this.state,addUserData:{...this.state.addUserData,...this.intitalFormState.addUserData},errorMessageOn:{...this.intitalFormState.errorMessageOn}, isEdit: false, openCreateModal: true })
  }
  handleOpenEditModal = () => {
    this.setState({ openCreateModal: true, isEdit :true})
  }
  closeCreateModal = () => {
    this.setState({ openCreateModal: false })
  }
  getUserData(type: string) {
    const data = this.state.accountsList
    const mapperFun = type === "Staff" ? this.staffMapper : this.playerMapper
    const staffData = data.map((item:Account) => {
      return mapperFun(item)
    })
    return staffData
  }
  formatDate(dateString: string | null): string {
    if (!dateString) return ''
    const date = new Date(dateString);

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${hours}:${minutes} | ${day}/${month}/${year}`;
  }


  playerMapper = (data: Account): ObjData => {
    const { attributes } = data
    const staffData: ObjData = {
      "id":data.id,
      "Photo": attributes.photo,
      "Name": `${attributes.first_name} ${attributes.last_name}`,
      "Date of Birth": attributes.date_of_birth,
      "Position": attributes.sport_position?.position_title || attributes.sport_position || '',
      "In-game Role":attributes.in_game_role?.name || '',
      "Last Active": this.formatDate(attributes.last_visit_at),
      "Added On": this.formatDate(attributes.created_at),
      "Guardian Name": `${attributes.guardian_first_name || ''} ${attributes.guardian_last_name || ''}`,
      "Guardian Sex": `${attributes.guardian_gender || ''}`,
      "Guardian Email": `${attributes.guardian_email || ''}`,
      "Verified": `${attributes.activated ? 'yes' : 'no'}`,
      "Action": ''
    }
    return staffData;
  }
  staffMapper = (data: Account): ObjData => {
    const { attributes } = data
    const playerData: ObjData = {
      "id":data.id,
      "Photo": attributes.photo,
      "Name": `${attributes.first_name} ${attributes.last_name}`,
      "Date of Birth": attributes.date_of_birth,
      "Role": attributes.role?.name,
      "Last Active": this.formatDate(attributes.last_visit_at),
      "Added On": this.formatDate(attributes.created_at),
      "Verified": `${attributes.activated ? 'yes' : 'no'}`,
      "Action": ''
    }
    return playerData
  }
  handleChangePage = ( event: React.ChangeEvent<unknown>,newPage:number) => {
    this.setState({ currentPage: newPage }, () => {this.handleOnSearch()});
  };

   calculateAge = (dateOfBirth: string): number => {
    const dob = new Date(dateOfBirth);
    const ageDifMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  
  // Function to validate basic info fields (name, email, etc.)
   validateBasicInfo = (addUserData: AddUserData) => {
    return [
      {
        name: "firstName",
        validate: this.firstNameValidation(addUserData.firstName.trim()),
      },
      {
        name: "lastName",
        validate: this.lastNameValidation(addUserData.lastName.trim()),
      },
      { name: "email", validate: this.emailValidation(addUserData.email.trim()) },
    ];
  };
  
  // Function to validate guardian details if necessary
   validateGuardianDetails = (addUserData: AddUserData, age: number) => {
    if (age >= 13 && age <= 18) {
      return [
        {
          name: "guardianFirstName",
          validate: this.firstNameValidation(addUserData.guardianFirstName?.trim()),
        },
        {
          name: "guardianLastName",
          validate: this.lastNameValidation(addUserData.guardianLastName?.trim()),
        },
        {
          name: "guardianEmail",
          validate: this.emailValidation(addUserData.guardianEmail?.trim()),
        },
      ];
    }
    return [];
  };
  
  // Function to validate other fields (account type, gender, etc.)
   validateOtherFields = (addUserData: AddUserData, age: number) => {
    addUserData.dateOfBirth = addUserData.dateOfBirth?.toString()
    return {
      username: addUserData.username?.trim() === "" ? "Username must be filled" : "",
      dateOfBirth: addUserData.dateOfBirth?.toString()?.trim() === "" ? "Date of birth must be filled" : "",
      gender: addUserData.gender === "" ? "Gender must be selected" : "",
      esportTitleId: addUserData.accountType === "eSports" && !addUserData.esportTitleId ? "Please select game title" : "",
      esportRoleId: addUserData.accountType === "eSports" && !addUserData.esportRoleId ? "Please select in-game role" : "",
      sportTitleId: addUserData.accountType === "Sports" && !addUserData.sportTitleId ? "Please select game title" : "",
      sportPositionId: addUserData.accountType === "Sports" && !addUserData.sportPositionId ? "Please select position" : "",
      accountType: addUserData.accountType.trim() === "" ? "Account type must be filled" : "",
      guardianGender: age >= 13 && age <= 18 && addUserData.guardianGender === "" ? "Guardian Gender must be selected" : "",
      invitationEmail: "",
    };
  };
  
  formValidation = (addUserData: AddUserData) => {
    const dateOfBirth  =addUserData.dateOfBirth?.toString() || ''
    const age = this.calculateAge(dateOfBirth.trim());
  
    // Validate different sections of the form
    const basicInfoValidations = this.validateBasicInfo(addUserData);
    const guardianValidations = this.validateGuardianDetails(addUserData, age);
    const otherFieldValidations = this.validateOtherFields(addUserData, age);
  
    // Combine all validations
    const elements = [...basicInfoValidations, ...guardianValidations];
    const mappingElements = elements.reduce(
      (accumulator: { [key: string]: string | undefined }, current: { name: string; validate: string | undefined }) => {
        accumulator[current.name] = current.validate;
        return accumulator;
      },
      {}
    );
  
    // Merge all validation errors
    const errorMessageOn: AddUserData = {
      firstName: mappingElements.firstName || "",
      lastName: mappingElements.lastName || "",
      email: mappingElements.email || "",
      guardianFirstName: mappingElements.guardianFirstName || "",
      guardianLastName: mappingElements.guardianLastName || "",
      guardianEmail: mappingElements.guardianEmail || "",
      username: otherFieldValidations.username,
      dateOfBirth: otherFieldValidations.dateOfBirth,
      gender: otherFieldValidations.gender,
      esportTitleId: otherFieldValidations.esportTitleId,
      esportRoleId: otherFieldValidations.esportRoleId,
      sportTitleId: otherFieldValidations.sportTitleId,
      sportPositionId: otherFieldValidations.sportPositionId,
      accountType: otherFieldValidations.accountType,
      guardianGender: otherFieldValidations.guardianGender,
      invitationEmail: otherFieldValidations.invitationEmail
    };
  
    this.setState({ errorMessageOn });
  
    const isValid = Object.values(errorMessageOn).every((value) => (value || "").trim() === "");
  
    return isValid;
  };

  createAccount=()=> {
    const { addUserData } = this.state;

   

   
      if(this.formValidation(addUserData)){
        this.callCreateUserAPI(addUserData)
      
     }
   }

  editAccount = () =>{
    const { addUserData } = this.state;
    if(this.formValidation(addUserData)){
      this.callEditUserAPI(addUserData)
    }
  }
   handleInviteAccount=()=>{
    const { addUserData } = this.state;

    if(this.state.errorMessageOn.invitationEmail===""&&addUserData.invitationEmail.length>0&&this.state.currentTeamId){
        this.callInviteUserAPI(addUserData)
    }

   }
          
  callInviteUserAPI=(addUserData:AddUserData)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );

    const header = {
      "Content-Type": configJSON.apiContentType,
    };
  


  this.inviteUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.inviteUserEndPoint}?email=${addUserData.invitationEmail}&team_id=${this.state.currentTeamId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
      );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethod
          );
        
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
  }
  getAPIMappedData = (addUserData: AddUserData) =>{
    const data = {
      first_name: addUserData.firstName,
      last_name: addUserData.lastName,
      user_name: addUserData.username,
      gender: `${(addUserData.gender)?.toString().toLowerCase()}`, 
      account_type: addUserData.accountType,
      date_of_birth: addUserData.dateOfBirth,
      email: addUserData.email,
      platform: "email_account",
      guardian_first_name: addUserData.guardianFirstName,
      guardian_last_name: addUserData.guardianLastName,
      guardian_email: addUserData.guardianEmail,
      guardian_gender: addUserData.guardianGender?.toString().toLowerCase() || null,
      esport_title_id: addUserData.esportTitleId || null,
      in_game_role_id: addUserData.esportRoleId || null,
      sport_title_id: addUserData.sportTitleId || null,
      sport_position_id: addUserData.sportPositionId || null,
      team_id: this.state.currentTeamId,
    };
    return data;
  }
  deleteUserAPI = () =>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    this.deleteGroupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountsApiEndPoint + `?account_id=${this.state.deleteData?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  callEditUserAPI=(addUserData:AddUserData)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const attrs = this.getAPIMappedData(addUserData)

  const httpBody = {
    data: {attributes: attrs,"type": "email_account"},
  };
    this.editUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createUserInTeamEndPoint + '/' + addUserData.userId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  callCreateUserAPI=(addUserData:AddUserData)=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
    const header = {
      "Content-Type": configJSON.apiContentType,
    };
    const attrs = this.getAPIMappedData(addUserData)
  const dataBody = {
    type: "email_account",
    attributes: {...attrs,
      password: `${addUserData.username}@FantasticWe1`,
    },
  };
  const httpBody = {
    data: dataBody,
  };
  this.createUserApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.createUserInTeamEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethod
          );
        
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
  }
  
  handleOnSearch = () =>{
    this.getUserDataFromAPI()
  }
  getMalGender = (gender?:string) =>{
    return gender?.toLowerCase() === 'male'
  }
  getFemaleGender = (gender?:string) =>{
    return gender?.toLowerCase() === 'female'

  }
  handleEditUser = async(data: ObjData) => {
    const editableData = this.state.accountsList.find((item:ObjData) => item.id == data.id);
    const { attributes } = editableData

    const addUserData = {
      firstName: attributes.first_name,
      lastName: attributes.last_name,
      email: attributes.email,
      username: attributes.user_name,
      accountType: await this.getAccountType(),
      dateOfBirth: new Date(attributes.date_of_birth),
      gender: attributes.gender,
      guardianGender: attributes.guardian_gender,
      guardianFirstName: attributes.guardian_first_name,
      guardianLastName: attributes.guardian_last_name,
      guardianEmail: attributes.guardian_email,
      sportTitleId:attributes.sport_title_id?.toString(),
      sportPositionId: attributes.sport_position_id?.toString(),
      esportRoleId:attributes.in_game_role?.id?.toString(),
      esportTitleId:attributes.in_game_role?.esport_id?.toString(),      
      userId: editableData.id
    } as AddUserData;
    this.setState({
      ...this.state,
      addUserData:addUserData,
      isMaleBtnChecked:this.getMalGender(attributes.gender),
      isFemaleBtnChecked: this.getFemaleGender(attributes.gender),
      isGuardianMaleBtnChecked: this.getMalGender(attributes.guardian_gender),
      isGuardianFemaleBtnChecked: this.getFemaleGender(attributes.guardian_gender),
    },() =>{
      this.handleOpenEditModal()
    })
  }
  handleClickDelete = () =>{
    this.deleteUserAPI()
  }
  handleDeleteUser = (data:ObjData) => {
    this.setState({ deleteAccountModal: true,deleteData:data })

  }
  closeDeleteAccount = () => {
    this.setState({ deleteAccountModal: false,deleteData:undefined })
  }
  getSportTitle = (): string =>{
    return this.state.sportTitles.find((item) => item.id.toString() === this.state.addUserData.sportTitleId)?.title || ''
  }
  getSportPositionTitle = ():string =>{
    return this.state.sportPositions.find((item) => item.id.toString() === this.state.addUserData.sportPositionId)?.position_title || ''
  }
  getEsportTitle = ():string => {
    return this.state.esportTitles.find((item) => item.id.toString() === this.state.addUserData.esportTitleId)?.title || ''
  }
  getEsportRole = ():string => {
    return this.state.esportRoles.find((item) => item.id?.toString() === this.state.addUserData?.esportRoleId)?.name || ''
  }
  // Handle
  

  // Customizable Area End
}
