Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelTitleText = "Bulk Upload";
exports.assignKeyboardsText = "Assign Keyboards";
exports.uploadBtnLabel = "Upload";
exports.clearFileBtnLabel = "Clear files";
exports.submitBtnLabel = "Submit";
exports.getUploadedFileLabel = "Get All Uploaded File";
exports.idLabel = "ID:";
exports.statusLabel = "Status: ";
exports.deleteBtnLabel = "Delete";
exports.removeBtnLabel = "Remove";
exports.downloadBtnLabel = "Download";
exports.apiContentType = "application/json"
exports.createContentType = "multipart/form-data"
exports.createBulkUploadEndpoint = "bulk_uploading/attachments";
exports.createBulkUploadMethod = "POST";
exports.getGameTypeMethod = "GET";
exports.uploadingSuccessMsg = "File uploaded Successfully!"
exports.getBulkUploadEndpoint = "bulk_uploading/attachments";
exports.getBulkUploadMethod = "GET";
exports.deleteBulkUploadSingleEndpoint = "bulk_uploading/attachments/delete_attachment";
exports.deleteBulkUploadEndpoint = "bulk_uploading/attachments";
exports.deleteBulkUploadMethod = "DELETE";
exports.deletedSuccessMsg = "Attachment file deleted successfully!";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 5MB!";
exports.downloadedSuccessMsg = "file has been downloaded! File Path: ";
exports.downloadingFailedMsg = "Something went wrong!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.loginAlertMessage = "Please login first.";
exports.bulkUploadingEndPoint="bx_block_bulk_uploading/attachments"
exports.getGameTypeEndPoint="/bx_block_categories/game_type_cat"
exports.bulkDataImportEndPoint="bx_block_bulk_uploading/attachments/data_import"
exports.createImportDataMethod="POST"
exports.labelTextContent="You can also select the share settings from the previous game settings"
exports.labelTextContentCheck="Use previous share settings"
exports.playerText="Select the players you's wish to share the video with, assign the competition type per group"
exports.contentH1="(Add Group - Select Keyboard - Finish)"
exports.contentH2="Add groups (G) by selecting players / positions per group, after adding a group select the following keyboard you’d like them to mark on for this game."
exports.label3 = "Assign the Keyboard type per group or per person."
exports.content3=" (Either Groups or Person must be assigned to continue)*"
exports.getAllKeyboardURL = "bx_block_settings/keyboards"
exports.createGameFolderURL = 'bx_block_content_management/folder_creations/create_game_folder'
exports.assignKeyboardGroup='/bx_block_account_groups/groups/841/assign_keyboard'
exports.getDefaultKeyboardList = '/bx_block_settings/keyboards/combined_keyboards'
// Customizable Area End