import React from "react";

// Customizable Area Start
const _ = require('lodash');
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  Checkbox,
} from "@material-ui/core";
import {
  alertLogo,
  bgImageEsports,
  bgImageSports,
  calendarIcon,
  epic,
  eyeOff,
  eyeOn,
  logo,
  riot,
  steam,
} from "./assets";
import InputField from "../../../components/src/InputField.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import DropDownSp from "../../../components/src/DropDownSp.web";
import {Check, Close} from "@material-ui/icons";
import { check,checkedBox } from "../../../../packages/blocks/videos/src/assets";


const backgroundImage:Record<string,string>={
  eSports:bgImageEsports,
  Sports:bgImageSports,
}

// Customizable Area End

import SignupController, { Props } from "./SingupController.web";
import DatePicker, { DateObject } from "react-multi-date-picker"
import ModalInfoSignup from "./ModalInfo.web";



export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  CustomInput = ({ openCalendar, value }: any) => {
    const formatDate = (date: any) => {
      if (!date) return '';
      return date;
    };
  
    return (
      <div className="input-date" style={{ display: 'flex', alignItems: 'center' }}>
        <input
          readOnly
          value={formatDate(value)}
          onClick={openCalendar}
        />
        <IconButton onClick={openCalendar} >
          <img src={calendarIcon} />
        </IconButton>
      </div>
    );
  };

  renderPasswordInstruction() {

    const isShowInstruction = this.state.signupData.password || this.state.signupData.reTypePassword;

    return (
      <Box style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 50
      }}>
        {isShowInstruction && this.passwordInstruction().map((element: { title: string, isNotValid: boolean }) => {
          const icon = element.isNotValid ? <Close htmlColor="red" /> : <Check htmlColor="green" />;
          const textStyle = {
            color: element.isNotValid ? "red" : "green",
            marginLeft: 15
          };
          return (
            <Box style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: 10
            }}>
              {icon}
              <Typography style={textStyle}>{element.title}</Typography>
            </Box>
          )
        })}
      </Box>
    )
  }

  renderTermAndPrivacy() {
    return (
      <TermPrivacy>
        <Button onClick={this.showTermAndPrivacy.bind(this)}>
          <Checkbox
            data-test-id='BpCheckbox'
            disableRipple
            checked={this.state.isCheckedTermAndPrivacy}
            checkedIcon={<BpIcon />}
            icon={
              <BpCheckedIcon
                style={{
                  border: '3px solid #676767',
                  backgroundImage: `url(${check})`,
                }}
              />
            }
            inputProps={{ 'aria-label': 'Checkbox demo' }}
          />
          <Typography className="label">{'Accept'}</Typography>
          <Typography className="label-yellow">{'Terms and Conditions'}</Typography>
          <Typography className="label">{"and"}</Typography>
          <Typography className="label-yellow">{"Privacy Policy"}</Typography>
          
        </Button>
      </TermPrivacy>
    )
  }

  render() {

    const { signupData, errorMessageOn, esportTitles, sportTitles, esportRoles, sportPositions, initialDateOpen } = this.state;

    const selectedEsportTitle = _.find(esportTitles, (item: any) => item.id + "" === signupData.esportTitleId);
    const selectedEsportTitleLabel = _.get(selectedEsportTitle, 'title', '');
    
    const selectedSportTitle = _.find(sportTitles, (item: any) => item.id + "" === signupData.sportTitleId);
    const selectedSportTitleLabel = _.get(selectedSportTitle, 'title', '');

    const selectedEsportRole = _.find(esportRoles, (item: any) => item.id + "" === signupData.esportRoleId);
    const selectedEsportRoleLabel = _.get(selectedEsportRole, 'name', '');

    const selectedSportPosition = _.find(sportPositions, (item: any) => item.id + "" === signupData.sportPositionId);
    const selectedSportPositionLabel = _.get(selectedSportPosition, 'position_title', '');

    return (
      <>
      <TestFieldStyle>
        <Box className="signup__page__container">
        <img src={backgroundImage[this.state.signupData.accountType]} alt="image"/>
        <Grid container style={{minHeight: "100vh"}}>
            <Grid item sm={12} md={6} className="signup_form_container">
              <Box className="icon_and_form_container">
                <Box className="icon_image_container">
                  <img className="logoImage" src={logo} />
                </Box>
                <Box className="form_container">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          className="MuiFilledInput-underline inputField "
                          testID="txtInputFirstName"
                          name="firstName"
                          label="First Name"
                          type="text"
                          value={signupData.firstName}
                          onChange={this.changeHandler}
                          validationMsg={errorMessageOn.firstName}

                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          className="MuiFilledInput-underline inputField "
                          testID="txtInputLastName"
                          label="Last Name"
                          name="lastName"
                          type="text"
                          value={signupData.lastName}
                          onChange={this.changeHandler}
                          validationMsg={errorMessageOn.lastName}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          className="MuiFilledInput-underline inputField "
                          name="username"
                          label="Username"
                          type="text"
                          value={signupData.username}
                          disabled={!!this.state.userId}
                          onChange={this.changeHandler}
                          validationMsg={errorMessageOn.username}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          className="MuiFilledInput-underline inputField "
                          testID="txtInputEmail"
                          data-testId="txtInputEmail"
                          name="email"
                          label="Email"
                          type="email"
                          disabled={!!this.state.userId}
                          value={signupData.email}
                          validationMsg={errorMessageOn.email}
                          onChange={this.changeHandler}
                        />
                      </Box>
                    </Grid>
  
                      {this.state.signupData.accountType === "Sports" ? (
                        <>
                          <Grid item xs={12} md={6} style={{paddingBottom:'24px'}} className="inputFieldCol" >
                            <Box className="input_field_container">
                              <DropDownSp
                                value={selectedSportTitleLabel}
                                label="Sports Title"
                                option={this.state.sportTitles.map((roleItem) => ({
                                  value: roleItem.id + "",
                                  label: roleItem.title,
                                }))}
                                name="sportTitleId"
                                handleChangeFunction={this.handleChangeSportTitle}
                                validationMsg={errorMessageOn.sportTitleId + ''} />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} style={{paddingBottom:'24px'}} className="inputFieldCol" >
                            <Box className="input_field_container">
                              <DropDownSp
                                value={selectedSportPositionLabel}
                                label="Position"
                                option={this.state.sportPositions.filter(element => element.sport_title_id + "" === this.state.signupData.sportTitleId + "").map((roleItem) => ({
                                  value: roleItem.id + "",
                                  label: roleItem.position_title,
                                }))}
                                name="sportPositionId"
                                handleChangeFunction={this.handleChangeSportPosition}
                                validationMsg={errorMessageOn.sportPositionId + ''}
                                isDisabled={!this.state.signupData.sportTitleId}
                                 />
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={6} style={{paddingBottom:'24px'}} className="inputFieldCol" >
                            <Box className="input_field_container">
                              <DropDownSp
                                value={selectedEsportTitleLabel}
                                label="Esports Title"
                                option={this.state.esportTitles.map((roleItem) => ({
                                  value: roleItem.id + "",
                                  label: roleItem.title,
                                }))}
                                name="esportTitleId"
                                handleChangeFunction={this.handleChangeEsportTitle}
                                validationMsg={errorMessageOn.esportTitleId + ''} />
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} style={{paddingBottom:'24px'}} className="inputFieldCol" >
                            <Box className="input_field_container">
                              <DropDownSp
                                value={selectedEsportRoleLabel}
                                label="In-game Role"
                                option={this.state.esportRoles.filter(element => element.esport_id + "" === this.state.signupData.esportTitleId + "").map((roleItem) => ({
                                  value: roleItem.id + "",
                                  label: roleItem.name,
                                }))}
                                name="esportRoleId"
                                handleChangeFunction={this.handleChangeEsportRole}
                                isDisabled={!this.state.signupData.esportTitleId}
                                validationMsg={errorMessageOn.esportRoleId + ''} />
                            </Box>
                          </Grid>
                        </>
                      )}
                    <Grid item xs={12} md={6} style={{paddingBottom:'24px'}}className="inputFieldCol" >
                      <Box className="input_field_container">
                        <DropDownSp
                            value={this.state.signupData.country}
                            label="Select Country"
                            option={this.state.countryData.map((country) => ({
                              value: country.name,
                              label: country.name,
                            }))}
                            name="country"
                            handleChangeFunction={this.handleChangeCountry}
                            validationMsg={errorMessageOn.country}/>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} style={{paddingBottom:'24px'}} className="inputFieldCol" >
                      <Box className="input_field_container">
                        
                        <DropDownSp
                          value={this.state.signupData.state}
                          label="Select State"
                          option={this.state?.statesName.map((state) => ({
                            value: state,
                            label: state,
                          }))}
                          name="state"
                          handleChangeFunction={this.handleChangeState}
                          validationMsg={errorMessageOn.state}
                          isDisabled={!this.state.signupData.country}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <Typography className="lableStyle">Date of Birth</Typography>
                        <DatePicker
                          data-test-id="datepickerDOB"
                          minDate={this.getDateRange().minDate}
                          maxDate={this.getDateRange().maxDate}
                          format="MM/DD/YYYY"
                          value={signupData.dateOfBirth}
                          onChange={this.handleDateInputField}
                          placeholder="MM/DD/YYYY"
                          render={(value,openCalendar) => this.CustomInput({openCalendar,value})}
                          inputClass="custom-input"
                          arrow={false}
                          currentDate={new DateObject(new Date(initialDateOpen))}
                        />

                        <Box>
                          {<span className="validationMsg" style={{ visibility: this.state.errorMessageOn.dateOfBirth ? "visible" : "hidden" }}>{this.state.errorMessageOn.dateOfBirth ? this.state.errorMessageOn.dateOfBirth : "error"}</span>}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <Typography className="lableContainer" children="Gender" />
                        <Box className="genderContainer">
                          <Button
                            variant="contained"
                            data-test-id='gender-btn-btn'
                            className={`gender-btn ${this.state.isMaleBtnChecked
                              ? "gender-btn-checked"
                              : "gender-btn-unchecked"
                              }`}
                            onClick={() => this.handleGender("Male")}
                          >
                            M
                          </Button>
                          <Button
                            data-test-id='gender-btn-btn'
                            variant="contained"
                            className={`gender-btn ${this.state.isFemaleBtnChecked
                              ? "gender-btn-checked"
                              : "gender-btn-unchecked"
                              }`}
                            onClick={() => this.handleGender("Female")}
                          >
                            F
                          </Button>
                          <InputField
                           data-testId="inputChangeGender"
                            isHideMsgError={true}
                            className="MuiFilledInput-underline gender-input-box"
                            onChange={(event: {target: {value: string}}) => this.handleChangeGender(event)}
                            placeholder="Other please specify"
                            type="text"
                            value={(!this.state.isMaleBtnChecked && !this.state.isFemaleBtnChecked)?signupData.gender:''}
                          />

                        </Box>
                        
                        <Box>
                          {<span className="validationMsg" style={{ visibility: this.state.errorMessageOn.gender ? "visible" : "hidden" }}>{this.state.errorMessageOn.gender ? this.state.errorMessageOn.gender : "error"}</span>}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          testID="txtInputPassword"
                          label="Password"
                          name="password"
                          type={this.state.enablePasswordField ? "password" : "text"}
                          className="MuiFilledInput-underline inputField "
                          value={signupData.password}
                          onChange={this.changeHandler}
                          // validationMsg={errorMessageOn.password}
                          iconChangeState={this.state.enablePasswordField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-testid="togglePassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  edge="end"
                                >
                                  {this.state.enablePasswordField ? ( <img src={eyeOff} width={32}/>
                                  ) : (
                                    <img src={eyeOn} width={32}/>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className="inputFieldCol" >
                      <Box className="input_field_container">
                        <InputField
                          testID="txtInputConfirmPassword"
                          label="Confirm Password"
                          name="reTypePassword"
                          type={
                            this.state.enableReTypePasswordField ? "password" : "text"
                          }
                          value={signupData.reTypePassword}
                          className="MuiFilledInput-underline inputField"
                          onChange={this.changeHandler}
                          // validationMsg={errorMessageOn.reTypePassword}
                          iconChangeState={this.state.enableReTypePasswordField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id=""
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowRetypePassword}
                                  edge="end"
                                >
                                  {this.state.enableReTypePasswordField ? (
                                     <img src={eyeOff} width={32}/>
                                     ) : (
                                     <img src={eyeOn} width={32}/>)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>

                    {this.renderPasswordInstruction()}

                  </Grid>
                </Box>
              </Box>

                {this.renderTermAndPrivacy()}

              <Box className="signup_page_footer_container">
              <Box style={{ gap:'16px',display: "flex", width: "100%", justifyContent: "center", marginTop: 30 }} className="buttonContainer" >
                <Button variant="contained"
                                        className="back-btn"
                                        data-test-id="forgot-password"
                                        onClick={this.goToLoginPage}
                                    >Back</Button>
                  <Button data-test-id="signupApiBtn"
                    variant="contained"
                    className="signupBtn"
                    onClick={this.createAccount}>Sign-Up</Button>
                </Box>

                <Box className="login_container">
                  {
                    this.state.signupData.accountType === 'eSports' && <>
                      <Box className="btn-below-line" />
                      <Box className="games-company-container">
                      <Button onClick={this.steamLogin} data-test-id="steamBtn">
                        <img
                          src={steam}
                          alt="SSO Steam"
                          className="img-style"
                        />
                      </Button>
                      <Button variant="text" onClick={this.epicLogin}  data-test-id="epicBtn">
                          <img
                            src={epic}
                            alt="Epic Games"
                            className="img-style"
                          />
                        </Button>
                        <img src={riot} alt="Riot Games" style={{ margin: "1%" }} />
                      </Box>
                    </>
                  }
                  <Typography className="bottom-text">Already have an account? <span onClick={this.goToLoginPage} className="bottom-link-text">Log-In here!</span></Typography>
                </Box>
              </Box>

            </Grid>

            <Grid item sm={12} md={6} className="signup_image_container">
            </Grid>
          </Grid>
          <Box className="alertContainer" data-testId="btnInfo" style={{ cursor: "pointer" }} onClick={() => this.handleClickInfo()}>
            <img src={alertLogo} />
          </Box>
        </Box>
        <ModalInfoSignup data-testId="modalInfo" isOpen={this.state.isShowInfo} onCloseDialog={() => this.handleClickInfo()}/>
      </TestFieldStyle>
        <TermsConditions
          navigation="" id=""
          showModal={this.state.showModal}
          showAcceptButton={true}
          onClosePress={this.hideTermAndPrivacy.bind(this)}
          onAcknowledgePress={this.hideTermAndPrivacy.bind(this)}
          onAcceptPress={this.acceptTermAndPrivacy.bind(this)}
        /> 
      </>
    );
  }
}

const BpIcon = styled("span")({
  borderRadius: 5,
  width: 16,
  height: 16,
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center",
  border:"3px solid #676767",
  backgroundColor: "transparent", 
  backgroundImage:`url(${checkedBox})`,
  
  padding:1,
  "&::before": {
    color: "black",
    content: '""',
    display: "block",
    width: 16,
    height: 16,
  },
});

export const BpCheckedIcon = styled(BpIcon)({
  borderRadius: 5,
  backgroundColor: "transparent", 
  backgroundRepeat:"no-repeat",
  backgroundPosition: "center",
  width: 16,
  height: 16,
  padding:1,
  "&::before": {
    color: "black",
    content: '""',
    display: "block",
    width: 16,
    height: 16,
  },
});

export const TermPrivacy = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  alignSelf: "center",

  "& .label": {
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: "none",
    color: "#CFD1D4",
    marginLeft: 7
  },

  "& .label-yellow": {
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: "none",
    color: "#FF881A",
    marginLeft: 7,
    textDecoration: "underline"
  },  
});

const TestFieldStyle = styled(Box)({
  "& .signup__page__container": {
    position: "relative",
    width: '100%',
    "& > img":{
      position:"absolute",
      zIndex:-1,
      inset:0,
      width:"100vw",
      height:"100%"
    }
  },
  "& .signup_form_container": {
    padding: '16px 34px',
    background: "#252527",
    boxShadow: "-11px 0px 13px 0px #00000040 inset",
    flex: "0 0 48%",
    maxWidth: "920px",
    "@media screen and (max-width: 1000px)": {
      maxWidth: "unset",
      flex: 1
    }
  },
  "& .icon_and_form_container": {
    display: 'flex',
    flexDirection: 'column',
    gap: '34px'

  },
  "& .icon_image_container": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },

  "& .logoImage": {
    width: '280px'
  },

  "& .alertContainer": {
    position: "absolute",
    bottom: 0,
    padding: "8px",
    background: "#1D1D1F",
    borderRadius: "0 10px 0 0"
  },

  "& .input_field_container .select-container .css-1okebmr-indicatorSeparator": {
    display: "none"
  },
  "& .signup_image_container": {
    width: '100%',
  },
  "& .genderContainer": {
    display: "flex",
    alignItems: "center",
    gap: '16px'
  },

  "& .gender-btn": {
    borderRadius: "10px",
    boxShadow: " 4px 4px 4px 0px #00000040 inset",
    fontSize: "16px",
    height: "34px",
    width: "34px",
    minWidth: "auto",
    cursor: "pointer",
    borderBottom: "1px solid #FF881A",
  },
  "& .gender-btn-checked": {
    background: "#FF881A",
  },
  "& .gender-btn-unchecked": {
    background: "#1D1D1F",
    color: "#CFD1D4",
  },

  "& .genderContainer .MuiBox-root": {
    width: "100%",
    "& .MuiInputBase-root input::placeholder": {
      opacity: 1,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      color: "#CFD1D4"
    }
  },
  "& .validationMsg": {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fontSize: '14px',
    margin: '6px 0'
  },
  "& .inputFieldCol": {
    padding: "0 12px"
  },
  // date picker css start
  "& .rmdp-top-class": {
    width: '300px'
  },
  "& .rmdp-calendar": {
    border: '1px solid #FF881A',
    backgroundColor: '#1D1D1F',
    boxShadow: 'none',
    width: '100%'
  },
  "& .MuiIconButton-root": {
    padding:"10px"
  },
  "& .rmdp-day-picker > div:first-child": {
    width: '100%',
  },
  "& .rmdp-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-week-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-header-values": {
    color: '#FF881A',
    fontWeight: 700
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: '#FBB26E',
  },
  "& .rmdp-month-picker, .rmdp-year-picker": {
    color: '#FF881A',
    fontWeight: 700,
    backgroundColor: '#1D1D1F'
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: '#FF881A',
    boxShadow: 'none',
    fontWeight: 500
  },
  "& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
    backgroundColor: '#FF881A'
  },
  "& .rmdp-arrow": {
    border: 'solid #FF881A',
    borderWidth: '0 2px 2px 0'
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "transparent",
    boxShadow: 'none',
    border: 'none',
    borderWidth: 'none'
  },
  "& .MuiTextField-root , .select-container": {
    height:"53px"
  },
  "& .rmdp-container ": {
    width: "100%",
    color: '#CFD1D4',
    height: '53px',
    "& .input-date": {
      background: "#1D1D1F",
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderRadius: "10px",
      borderBottom: "1px solid #FF881A"
    },
    "& .input-date input": {
      width: "100%",
      height: "39px",
      background: "transparent",
      border: "unset",
      padding: "7 7px",
      color: "#CFD1D4",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400
    },
    "& .input-date input:focus-visible": {
      outline: "unset"
    }
  },
  "& .icon-tabler-calendar-event ": {
    right: '16px !important'
  },
  "& .rmdp-input": {
    height: '39px',
    width: "100%",
    color: '#CFD1D4',
    background: '#1D1D1F',
    border: 'none',
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    margin: '1px 0',
    padding: '7px 5px',
    fontSize: '16px',
    fontWeight: 400
  },

  // datepicker css end
  "&  .signup_page_footer_container": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  "& .login_container": {
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: '16px'

  },


  "& .backgroundImg": {
    width: "100%",
    height: "100%",
    objectFit: 'cover'
  },

  "& .lableContainer": {
    color: "#676767",
    fontWeight: 400,
    fontSize: "20px",
  },
  "& .bottom-link-text": {
    color: "#FF881A",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
    cursor: "pointer"
  },

  "& .bottom-text": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    color: "#CFD1D4",
    paddingRight: "1%",
  },
  "& .games-company-container": {
    display: "flex",
    justifyContent: "center",
    gap: '32px'
  },

  '& .back-btn': {
    color: "#FF881A",
    backgroundColor: "#252527",
    marginTop:"32",
    border:'1px solid #FF881A',
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    maxWidth: "150px",
    width: "100%",
    textTransform: "none"
},
  "& .signupBtn": {
    backgroundColor: "#FF881A",
    color: "#1D1D1F",
    marginTop:"32",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    maxWidth: "150px",
    width: "100%",
    textTransform: "none"
  },

  "& .btn-below-line": {
    border: "1px solid #676767",
    width: "348px",
    top: "968px",
    margin: "auto",
    marginTop: "2%",
  },

  '& .lableStyle': {
    color: "#676767",
    fontWeight: 400,
    fontSize: '1.3rem'
  },

  "& .rmdp-day.rmdp-disabled": {
    cursor: 'not-allowed',
    color: "#7F7F7F !important"
  },
});
